import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const updateCampaign = async (campaignId, formData) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };
    const response = await axios.patch(
      `${API_URL}/nonprofits/campaigns/${campaignId}/update/`,
      formData,
      config
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("Profile Update Error: ", error.response);
      throw error;
    }
  }
};
