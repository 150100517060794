import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import QRCodeModal from "../QRCodeModal/QRCodeModal";
import { urlifyName } from "../../shared/Utils/PageUrl";
import CampaignModal from "../CampaignModal/CampaignModal";
import { deleteCampaign } from "../../../api/Campaigns";
import EmailTemplateModal from "../EmailTemplateModal/EmailTemplateModal";
import { faPlus, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import classes from "./DashboardContent.module.css";
import EditCampaignModal from "../EditCampaignModal/EditCampaignModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PrimaryButton from "../../shared/Buttons/PrimaryButton";
import SecondaryButton from "../../shared/Buttons/SecondaryButton";
import { MenuItem } from "@mui/material";
import Select from "@mui/material/Select";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

// This component renders the 'Your Campaigns' section in the Dashboard Content for NPs
// Includes CRUD

const campaignOptions = [
  { label: "Animal", value: "Animal" },
  { label: "Product", value: "Product" },
  { label: "Capital", value: "Capital" },
];

const filterOptions = [
  { label: "Animal", value: "animal" },
  { label: "Product", value: "product" },
  { label: "Capital", value: "capital" },
];

const CustomIcon = (props) => (
  <FontAwesomeIcon icon={faChevronDown} {...props} style={{ color: "white" }} />
);

const CampaignsComponent = ({
  npData,
  localUserData,
  campaigns,
  setCampaigns,
  fetchCampaigns,
  ifCampaignExists,
}) => {
  const [campaignModal, setCampaignModal] = useState(false);
  const [campaignType, setCampaignType] = useState("");
  const [campaignTypeError, setCampaignTypeError] = useState("");
  const [updatingCampaignId, setUpdatingCampaignId] = useState(null);
  const [emailModal, setEmailModal] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [QRModal, setQRModal] = useState(false);
  const [campaignUrl, setCampaignUrl] = useState("");
  const navigate = useNavigate();
  const [qrModalOpen, setQrModalOpen] = useState(false);
  const [campaignSearchInput, setCampaignSearchInput] = useState("");
  const [confirmDelete, setConfirmDelete] = useState({});
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState("all");

  // Stops the background from scrolling if a modal is open
  useEffect(() => {
    if (campaignModal || emailModal || updatingCampaignId) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [campaignModal, emailModal, updatingCampaignId]);

  const handleCampaignInputChange = (e) => {
    setCampaignType(e.target.value);
    // Restarts formErrors when input changes
    setCampaignTypeError("");
  };

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue) {
      handleOpenModal(selectedValue);
    }
    setCampaignType(selectedValue);
  };

  // Handle open/close modal for Create Campaign
  const handleOpenModal = (selectedType) => {
    setCampaignType(selectedType);
    setCampaignModal(true);
  };

  const handleCloseModal = () => {
    setCampaignModal(false);
    setCampaignType(null);
  };

  // Handle open/close modal for New Email
  const handleOpenEmailModal = (campaignId) => {
    const campaign = campaigns.find((c) => c.id === campaignId);
    setSelectedCampaign(campaign);
    setEmailModal(true);
  };

  const handleCloseEmailModal = () => {
    setEmailModal(false);
    setSelectedCampaign(null);
  };

  // Handle open/close modal for Edit/Update Campaign
  const handleOpenModify = (campaignId) => {
    setUpdatingCampaignId(campaignId);
  };

  const handleCloseModify = () => {
    setUpdatingCampaignId(null);
  };

  // Navigates to the campaign page
  const redirectToCampaignPage = (campaign) => {
    const campaignPath =
      campaign.campaign_type === "animal"
        ? `/campaigns/animal/${urlifyName(
            campaign.nonprofit_name
          )}/${urlifyName(campaign.animal_name)}/${campaign.id}`
        : `/campaigns/${campaign.campaign_type}/${urlifyName(
            campaign.nonprofit_name
          )}/${urlifyName(campaign.campaign_title)}/${campaign.id}`;

    navigate(campaignPath);
  };

  // Searching Campaigns
  const handleCampaignSearchChange = (e) => {
    setCampaignSearchInput(e.target.value);
    setPage(1);
    fetchCampaigns(1, e.target.value, localUserData.nonprofit, filter);
  };

  // Switches between 'Confirm' and delete
  const handleDeleteConfirm = (id, campaignType) => {
    if (confirmDelete[id]) {
      handleDeleteCampaign(id, campaignType);
    } else {
      setConfirmDelete((prevState) => ({
        ...prevState,
        [id]: true,
      }));
    }
  };

  // Handles delete functionality
  const handleDeleteCampaign = async (campaignId, campaignType) => {
    try {
      campaignId = parseInt(campaignId);
      await deleteCampaign(campaignId, campaignType);
      setCampaigns(campaigns.filter((campaign) => campaign.id !== campaignId));
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  // Creates a QR Code and opens the QR Modal for the 'Share' button
  const handleGenerateQRCode = (campaign) => {
    const { protocol, host } = window.location;

    const campaignPath =
      campaign.campaign_type === "animal"
        ? `animal/${urlifyName(campaign.nonprofit_name)}/${urlifyName(
            campaign.animal_name
          )}`
        : `${campaign.campaign_type}/${urlifyName(
            campaign.nonprofit_name
          )}/${urlifyName(campaign.campaign_title)}`;

    const qrUrl = `${protocol}//${host}/campaigns/${campaignPath}/${campaign.id}`;

    setCampaignUrl(qrUrl);
    setQRModal(true);
  };

  // Closes the QR code modal
  const handleCloseQRCodeModal = () => {
    setQRModal(false);
    setCampaignUrl("");
  };

  // Handles button to load more campaigns
  const handleLoadMoreCampaigns = () => {
    fetchCampaigns(
      page + 1,
      campaignSearchInput,
      localUserData.nonprofit,
      filter
    );
    setPage(page + 1);
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
    fetchCampaigns(
      1,
      campaignSearchInput,
      localUserData.nonprofit,
      e.target.value
    );
    setPage(1);
  };

  return (
    <div className="sm:mb-2 !overflow-x-auto rounded-xl bg-orange-100 items-center align-center flex flex-col p-5 gap-1">
      <h1 className={`!mt-0 text-orange !text-3xl mb-4`}>Your Campaigns</h1>

      {/* if localUserData exists and NP is not approved => show waiting for approval text */}
      {/* if localUserData exists and NP is approved => check if user is admin or member. include complete onboarding text if stripe onboarding is incomplete */}
      {localUserData && !npData.is_approved ? (
        <>
          <p>
            Your application is under review. In the meantime, check out the
            rest of your dashboard!
          </p>
        </>
      ) : (
        <>
          {!npData?.stripe_account?.charges_enabled && (
            <>
              <p>
                Complete the Stripe Onboarding in the 'Nonprofit Profile' tab in
                order to receive donations directly to your bank account. once
                you receive an email of your Nonprofit being approved, to
              </p>
              <br />
              <p>Watch this guide on how to set up your Stripe Account!</p>
              <div
                className={`${classes.video} !min-w-[500px] sm:mb-2 flex gap-1 mb-2`}
              >
                <iframe
                  width="150%"
                  height="400"
                  src="https://www.youtube.com/embed/WNJb-iSFC3M?list=PLELFenzodbTGgZvPRlONBqvmTKLmpNaHY"
                  title="How to Connect Your Stripe Account"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                ></iframe>
              </div>
            </>
          )}

          {localUserData &&
          npData.is_approved &&
          (localUserData.np_access_level === "admin" ||
            localUserData.np_access_level === "editor") ? (
            <div className={`flex flex-col justify-center w-full`}>
              <div className="flex flex-col sm:flex-row justify-center items-center sm:items-start sm:justify-between gap-2 sm:gap-0">
                <Select
                  className="!w-48 !h-8 !bg-orange !text-white"
                  value={campaignType || " "}
                  name="campaignType"
                  onChange={handleChange}
                  displayEmpty
                  IconComponent={CustomIcon} // Custom white arrow icon
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        backgroundColor: "#f3f3f3", // Dropdown background grey
                      },
                    },
                  }}
                  sx={{
                    "& .MuiSelect-icon": {
                      color: "white", // Force the dropdown icon color to white
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FE994F", // Default border color
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#fb8c00", // Focused border color (orange outline)
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#fb8c00", // Hover border color
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#fb8c00", // Ensure focus border color is orange
                      },
                    },
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      boxShadow: "0 0 0 2px #fb8c00", // Add orange focus shadow
                    },
                  }}
                >
                  <MenuItem
                    value=" "
                    disabled
                    sx={{
                      color: campaignType === " " ? "#ffffff" : "#808080", // White when selected, grey in dropdown
                      fontWeight: "bold",
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="font-semibold mr-4"
                    />{" "}
                    New Campaign
                  </MenuItem>

                  {/* Campaign Options */}
                  {campaignOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                <Select
                  className="!w-48 !h-8 !bg-blue !text-white"
                  value={filter}
                  displayEmpty
                  onChange={handleFilterChange}
                  IconComponent={CustomIcon} // Custom white arrow icon
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        backgroundColor: "#f3f3f3", // Dropdown background grey
                      },
                    },
                  }}
                  sx={{
                    "& .MuiSelect-icon": {
                      color: "white", // Force the dropdown icon color to white
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#46A4DD", // Default border color
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#46A4DD", // Focused border color (blue outline)
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#46A4DD", // Hover border color
                    },
                    "& .MuiOutlinedInput-root": {
                      "&.Mui-focused fieldset": {
                        borderColor: "#46A4DD", // Ensure focus border color is blue
                      },
                    },
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      boxShadow: "0 0 0 2px #46A4DD", // Add blue focus shadow
                    },
                    "& .MuiMenu-paper": {
                      maxHeight: "200px", // Limit dropdown height for long lists
                    },
                  }}
                >
                  {/* Default Option */}
                  <MenuItem key="all" value="all">
                    Filter Campaigns
                  </MenuItem>

                  {/* Dynamically Render Campaign Options */}
                  {filterOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
          ) : (
            <p>Only Editors and Admins can create campaigns.</p>
          )}
        </>
      )}

      {/* QR Modal opens after creating a campaign successfully */}
      <QRCodeModal
        isOpen={qrModalOpen}
        onClose={() => {
          setQrModalOpen(false);
          setCampaignModal(false);
          window.location.reload();
        }}
        campaignUrl={campaignUrl}
      />

      {campaignModal && (
        <CampaignModal
          nonprofitName={npData.org_name}
          nonprofitId={npData.id}
          isOpen={campaignModal}
          onClose={handleCloseModal}
          setCampaigns={setCampaigns}
          setQrModalOpen={(boolean) => setQrModalOpen(boolean)}
          campaignUrl={campaignUrl}
          setCampaignUrl={setCampaignUrl}
          campaignType={campaignType}
          handleGenerateQRCode={handleGenerateQRCode}
        />
      )}

      {/* Campaign Search Bar */}
      <div className={`flex w-full mt-4 items-center relative`}>
        <FontAwesomeIcon
          icon={faMagnifyingGlass}
          size="xs"
          className="absolute left-3"
          color="#666666"
        />
        <input
          type="text"
          placeholder="Search campaigns by title, animal name, product type, or project status"
          value={campaignSearchInput}
          onChange={handleCampaignSearchChange}
          className={`p-2 pl-8 border text-black border-gray-300 rounded-lg w-full h-7 `}
        />
      </div>

      {campaigns && localUserData && npData && (
        <div className={`flex flex-col w-full mt-4 gap-4`}>
          <div className={`flex items-center gap-8 p-4 pb-6 overflow-x-auto`}>
            {campaigns.map((item) => {
              if (item.nonprofit !== localUserData.nonprofit) return null;

              return (
                <div
                  className={`pb-4 overflow-y-auto shadow-lg hover:shadow-xl min-w-52 max-w-52 min-h-[340px] !hover:shadow-lg bg-white w-full !pb-8 h-fit flex flex-col overflow-hidden gap-1 rounded-lg`}
                  key={item.campaign_title + item.id}
                >
                  <div
                    onClick={() => redirectToCampaignPage(item)}
                    className={`${classes.gridContainer} grid relative z-10 before:content=[""] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:z-10 before:cursor-pointer`}
                  >
                    {item.campaign_images && item.campaign_images[0] ? (
                      <img
                        onClick={() => redirectToCampaignPage(item)}
                        className={`${classes.campaign_image} cursor-pointer w-52 max-h-32 object-cover z-0`}
                        src={item.campaign_images[0].image_url}
                        alt={item.campaign_title}
                      />
                    ) : (
                      <div
                        onClick={() => redirectToCampaignPage(item)}
                        className={`${classes.campaign_image} cursor-pointer min-w-52 min-h-32 object-cover z-0`}
                      ></div>
                    )}
                    {/* Darker overlay div */}
                    <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 z-20 pointer-events-none"></div>
                    <div
                      className={`${classes.text_overlay} absolute top-3 left-6 text-white text-left flex flex-col justify-center items-center z-50`}
                    >
                      <h3
                        className={`text-orange font-semibold my-2 mx-0 p-0 cursor-pointer pointer-events-auto break-words`}
                        onClick={() => redirectToCampaignPage(item)}
                      >
                        {item.animal_name || item.campaign_title}
                      </h3>
                    </div>
                  </div>

                  {localUserData.np_access_level !== "viewer" ? (
                    <div
                      className={`buttons-portion max-h-44 rounded-lg rounded-t-lg px-4 `}
                    >
                      <progress
                        className={`${classes.progress_bar} w-full h-2.5 my-1`}
                        value={Math.min(
                          item.donation_amount,
                          item.donation_goal
                        )}
                        max={item.donation_goal}
                      />
                      <p
                        className={`!text-sm text-center font-semibold !,t-0 !text-zinc-700 text-left`}
                      >
                        ${item.donation_amount} of ${item.donation_goal} raised!
                      </p>

                      <SecondaryButton
                        className="!w-full !text-xs my-2"
                        onClick={() => handleOpenModify(item.id)}
                      >
                        Modify Campaign
                      </SecondaryButton>

                      <EditCampaignModal
                        campaignId={item.id}
                        campaignData={item}
                        isOpen={updatingCampaignId === item.id}
                        onClose={handleCloseModify}
                      />

                      <SecondaryButton
                        className="!bg-error hover:!bg-red-800 !w-full !text-xs mb-2"
                        onClick={() =>
                          handleDeleteConfirm(item.id, item.campaign_type)
                        }
                      >
                        {confirmDelete[item.id] ? "Confirm Delete?" : "Delete"}
                      </SecondaryButton>

                      <div
                        className={`${classes.side_by_side_buttons} flex justify-center gap-2`}
                      >
                        <SecondaryButton
                          onClick={() => handleGenerateQRCode(item)}
                          className="!text-xs !p-2 !w-full"
                        >
                          Share
                        </SecondaryButton>
                        <SecondaryButton
                          onClick={() => handleOpenEmailModal(item.id)}
                          className="!text-xs !p-2 !w-full"
                        >
                          <FontAwesomeIcon icon={faPlus} className="text-xs" />{" "}
                          New Email
                        </SecondaryButton>
                      </div>
                    </div>
                  ) : (
                    <>
                      <progress
                        className={`${classes.progress_bar}`}
                        value={item.donation_amount}
                        max={item.donation_goal}
                      />
                      <SecondaryButton
                        onClick={() => handleGenerateQRCode(item)}
                      >
                        Share
                      </SecondaryButton>
                    </>
                  )}
                </div>
              );
            })}
            {campaigns.length === 0 && !ifCampaignExists && (
              <div className="mx-auto">No campaigns found!</div>
            )}

            {ifCampaignExists && (
              <SecondaryButton
                onClick={handleLoadMoreCampaigns}
                className="!mt-16 max-h-12 max-w-fit mx-auto"
              >
                Load More
              </SecondaryButton>
            )}
          </div>
        </div>
      )}

      <EmailTemplateModal
        isOpen={emailModal}
        onClose={handleCloseEmailModal}
        campaignId={selectedCampaign?.id}
        nonprofitName={npData?.org_name}
        animalName={selectedCampaign?.animal_name}
        nonprofitId={npData?.id}
        customEmailPermission={npData?.custom_emails}
      />

      {/* Opens after a user clicks on 'Share' button */}
      {QRModal && (
        <QRCodeModal
          isOpen={QRModal}
          onClose={handleCloseQRCodeModal}
          campaignUrl={campaignUrl}
        />
      )}
    </div>
  );
};

export default CampaignsComponent;
