import React, { useState, useEffect, useRef } from "react";
import PrimaryButton from "../../shared/Buttons/PrimaryButton";
import Spinner from "../../shared/LoadingSpinner/Spinner";
import TextInput from "../../shared/Inputs/TextInput";
import DropDown from "../../shared/Inputs/Dropdown";
import classes from "./CampaignModal.module.css";
import SecondaryButton from "../../shared/Buttons/SecondaryButton";
import SharedTooltip from "../../shared/Tooltip/SharedTooltip";
import Editor from "./Editor/Editor";
import CampaignFeature from "../../Campaigns/CampaignFeature/CampaignFeature";
import QRCodeModal from "../QRCodeModal/QRCodeModal";

const productOptions = [
  { value: "food", label: "Food" },
  { value: "toys", label: "Toys" },
  { value: "supplies", label: "Supplies" },
  { value: "beds", label: "Beds" },
  { value: "medical", label: "Medical Equipment" },
  { value: "other", label: "Other" },
];

const ProductCampaignForm = ({
  nonprofitId,
  onSuccess,
  nonprofitName,
  qrModalOpen,
  campaignUrl,
  setQrModalOpen,
  productFormValues,
  setProductFormValues,
  productFormErrors,
  setProductFormErrors,
  SortableList,
  handleCampaignSubmit,
  handleImageChange,
  imagePreviews,
  onSortEnd,
  campaignType,
  isLoading,
}) => {
  const fileInputRef = useRef();
  const [previewModalOpen, setPreviewModalOpen] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    const finalValue =
      name === "product_type"
        ? productOptions.find((option) => option.value.toString() === value)
            ?.value || value
        : value;

      // Prevent negative donaation & quanity goals
     if (
       (name === "product_quantity_needed" || name === "donation_goal") &&
       parseFloat(value) < 0
     ) {
       return;
     }

    setProductFormValues((prev) => ({
      ...prev,
      [name]: finalValue,
    }));

    // Restarts formErrors when input changes
    setProductFormErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  const handleEditorChange = (content) => {
    setProductFormValues((prevState) => ({
      ...prevState,
      description: content,
    }));

    setProductFormErrors((prevState) => ({
      ...prevState,
      description: "",
    }));
  };

  const handleOpenPreview = () => {
    setPreviewModalOpen(true);

    const createCampaignContent = document.querySelector(
      `.${classes.campaign_modal_overlay}`
    );
    if (createCampaignContent) {
      createCampaignContent.style.overflow = "hidden";
    }
  };

  const handleClosePreview = () => {
    setPreviewModalOpen(false);

    const createCampaignContent = document.querySelector(
      `.${classes.campaign_modal_overlay}`
    );
    if (createCampaignContent) {
      createCampaignContent.style.overflow = "auto";
    }
  };

  return (
    <div>
      <h2 className="font-bold text-orange text-2xl text-center">
        Create New Product Campaign
      </h2>
      <form onSubmit={handleCampaignSubmit}>
        <TextInput
          label="Campaign Title"
          subLabel="(Required)"
          type="text"
          name="campaign_title"
          placeholder="Campaign Title"
          value={productFormValues.campaign_title}
          onChange={handleInputChange}
          errorMessage={productFormErrors.campaign_title}
        />
        <TextInput
          label="Donation Goal"
          subLabel="(Required)"
          type="number"
          name="donation_goal"
          placeholder="$"
          value={productFormValues.donation_goal}
          onChange={handleInputChange}
          errorMessage={productFormErrors.donation_goal}
        />
        <TextInput
          label="Donation Box Title "
          maxLength="30"
          subLabel={
            <>
              (Max. 30 Chars){"  "}
              <SharedTooltip
                id="my-tooltip"
                toolClass="flex justify-center items-center"
                content="The heading right above the donation box"
              />
            </>
          }
          sublabelClassName="inline-flex items-center"
          type="text"
          name="donation_box_title"
          placeholder="Donate"
          value={productFormValues.donation_box_title}
          onChange={handleInputChange}
          errorMessage={productFormErrors.donation_box_title}
        />
        <DropDown
          label="Product type"
          subLabel="(Required)"
          placeholder="Select an option"
          options={productOptions}
          name="product_type"
          value={productFormValues.product_type}
          onChange={handleInputChange}
          errorMessage={productFormErrors.product_type}
        />
        <TextInput
          label="Quantity Needed"
          subLabel="(Required)"
          type="number"
          name="product_quantity_needed"
          placeholder="0"
          value={productFormValues.product_quantity_needed}
          onChange={handleInputChange}
          errorMessage={productFormErrors.product_quantity_needed}
        />

        <div>
          <label className="flex flex-row justify-start items-center gap-1.5">
            <h2 className="text-black font-roboto font-semibold text-lg block">
              Description
            </h2>
            <span className="text-grey-dark1 !text-sm font-roboto font-semibold">
              (Required)
            </span>
          </label>
          <Editor
            defaultValue={productFormValues.description}
            onTextChange={handleEditorChange}
          />
          <div className="mt-2 text-left w-full ">
            {productFormErrors.description !== "" && (
              <p className="text-error !text-sm">
                {productFormErrors.description}
              </p>
            )}
          </div>
        </div>

        <TextInput
          label="Video URLs"
          subLabel="(Separated by commas)"
          type="text"
          name="video_urls"
          placeholder="URL, URL"
          value={productFormValues.video_urls}
          onChange={handleInputChange}
          errorMessage={productFormErrors.video_urls}
        />

        {/* Image upload */}
        <div>
          <h2 className="text-lg mb-4 !text-black font-semibold">
            Upload Images
          </h2>
          <SortableList
            items={imagePreviews}
            onSortEnd={onSortEnd}
            axis="xy"
            helperClass={classes.sortable_helper}
          />

          <PrimaryButton
            className="!w-1/2 !justify-center !items-center !flex !m-auto !mt-4"
            onClick={(e) => {
              e.preventDefault();
              fileInputRef.current.click();
            }}
          >
            Add Image
          </PrimaryButton>
          <input
            onChange={(e) =>
              handleImageChange(e, setProductFormErrors, setProductFormValues)
            }
            multiple
            ref={fileInputRef}
            name="images"
            type="file"
            hidden
          />
          <div className="mt-2 text-center w-full ">
            {productFormErrors.images !== "" && (
              <p className="text-error !text-sm">{productFormErrors.images}</p>
            )}
          </div>
        </div>

        <div className="my-4 flex gap-2 justify-center items-center">
          <SecondaryButton type="submit" disabled={isLoading}>
            {isLoading ? "Saving..." : "Save Campaign"}
          </SecondaryButton>
          <PrimaryButton
            type="preview"
            onClick={(e) => {
              e.preventDefault();
              handleOpenPreview();
            }}
          >
            Preview
          </PrimaryButton>
        </div>
      </form>

      {previewModalOpen && (
        <CampaignFeature
          isOpen={previewModalOpen}
          onClose={() => handleClosePreview()}
          campaignData={productFormValues}
          imagePreviews={imagePreviews}
          graphicImagePreviews={[]}
          campaignType={campaignType}
        />
      )}

      <QRCodeModal
        isOpen={qrModalOpen && campaignUrl !== ""}
        onClose={() => setQrModalOpen(false)}
        campaignUrl={campaignUrl}
        key={campaignUrl}
      />
    </div>
  );
};

export default ProductCampaignForm;
