import React, { useEffect, useState } from "react";
import { LinearProgress } from "@mui/material";
import { getAllPaginatedCampaigns } from "../../../api/Campaigns";
import SharedTooltip from "../Tooltip/SharedTooltip";

const ProgressBar = ({ npData, campaigns = null }) => {
  const [progress, setProgress] = useState(25);

  useEffect(() => {
    if (npData?.stripe_account) {
      setProgress(50);
      if (npData?.stripe_account.charges_enabled) {
        setProgress(75);
      }
    }
  }, [npData]);

  useEffect(() => {
    const fetchCampaignData = async () => {
      try {
        let fetchedCampaigns = null;
        if (campaigns === null) {
          const response = await getAllPaginatedCampaigns(
            1,
            "",
            npData.id,
            "all"
          );
          fetchedCampaigns = response.results;
        } else {
          fetchedCampaigns = campaigns;
        }

        if (fetchedCampaigns && fetchedCampaigns.length > 0) {
          setProgress(100);
        }
      } catch (error) {
        console.error("Error fetching campaign data:", error);
      }
    };

    if (progress >= 75) {
      fetchCampaignData();
    }
  }, [progress, npData.id]);

  const tooltipContentMap = {
    50: "Complete your Stripe's onboarding!",
    75: "Create a campaign to finish your account onboarding!",
  };

  return (
    <>
      <div>
        <span className="flex justify-center gap-1 mb-1">
          {progress}%{" "}
          {tooltipContentMap[progress] && (
            <SharedTooltip
              id="1"
              content={tooltipContentMap[progress]}
              toolClass="flex justify-center items-center"
            />
          )}
        </span>
        <LinearProgress variant="determinate" value={progress} />
      </div>
    </>
  );
};

export default ProgressBar;
