import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const DropDown = ({
  className, // styles for the dropdown itself
  labelClassName, // styles for the labels (for darker backgrounds)
  disabled, // disabled the dropdown
  errorMessage, // shows up under the dropdown
  label,
  name,
  onChange,
  optionKey, // additional option key for dropdown
  optionLabel, // additional option label for dropdown
  options, // maps thru the options
  placeholder, // always disabled
  subLabel, // usually meant for (required)
  value,
}) => {
  // Example usage
  //   <DropDown
  //     label="Do you rent or own your home?"
  //     subLabel="(Required)"
  //     placeholder="Select an option"
  //     options={["Rent", "Own"]}
  //     name="rent_or_own"
  //     value={formValues.rent_or_own}
  //     onChange={handleInputChange}
  //     errorMessage={formErrors.rent_or_own}
  //   />;

  //   const handleInputChange = (e) => {
  //     const { name, value } = e.target;

  //     setFormValues((prev) => ({
  //       ...prev,
  //       [name]: value,
  //     }));

  //     // Restarts formErrors when input changes
  //     setFormErrors((prev) => ({
  //       ...prev,
  //       [name]: "",
  //     }));
  //   };
  return (
    <div className="flex flex-col gap-1 my-2">
      {label && (
        <label
          className={`font-roboto font-semibold text-lg text-left !w-full block ${labelClassName}`}
        >
          {label}{" "}
          {subLabel && (
            <span className="text-grey-dark1 text-sm font-roboto font-semibold">
              {subLabel}
            </span>
          )}
        </label>
      )}

      <div className={`relative ${className}`}>
        <select
          name={name}
          value={value}
          onChange={onChange}
          disabled={disabled}
          className={`border
              shadow-inputs rounded-3xl px-4 w-full h-12 text-sm font-roboto 
              focus:border-blue active:border-blue focus:outline-none focus:ring-0 placeholder:grey-superLight
              appearance-none 
              ${!value ? "text-grey-inputs" : "text-black"} 
              ${
                disabled
                  ? "bg-gray-200 !cursor-not-allowed"
                  : "bg-white !cursor-pointer"
              }
              ${errorMessage ? "border-error" : "border-grey-inputs"} `}
        >
          <option value="" disabled className="!text-grey-inputs">
            {placeholder}
          </option>
          {/* Render Options */}
          {options.map((option, index) => {
            if (optionKey && optionLabel) {
              // Handle object-based options with custom keys
              return (
                <option
                  key={option[optionKey] || index}
                  value={option[optionKey]}
                  className="!text-black !cursor-pointer"
                >
                  {option[optionLabel]}
                </option>
              );
            } else if (option.value && option.label) {
              // Handle object-based options with default keys (value/label)
              return (
                <option
                  key={index}
                  value={option.value}
                  className="!text-black !cursor-pointer"
                >
                  {option.label}
                </option>
              );
            }
            // Handle string-based options
            return (
              <option
                key={index}
                value={option}
                className="!text-black !cursor-pointer"
              >
                {option}
              </option>
            );
          })}
        </select>
        <FontAwesomeIcon
          icon={faChevronDown}
          className="absolute right-4 top-1/2 transform -translate-y-1/2 pointer-events-none text-grey-superLight"
        />
      </div>
      <p className="text-error !text-sm ml-4 min-h-[20px] font-roboto text-left">
        {errorMessage || "\u00A0"}
      </p>
    </div>
  );
};

export default DropDown;
