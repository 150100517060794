import React, { useState, useEffect } from "react";
import PrimaryButton from "../../shared/Buttons/PrimaryButton";
import SuccessModal from "../../Campaigns/CampaignFeature/SuccessModal/SuccessModal";
import { useDispatch } from "react-redux";
import { cartActions } from "../../../store/index";
import { useNavigate } from "react-router-dom";
import { createOrderPayment } from "../../../api/ShopOrders";
import Spinner from "../../shared/LoadingSpinner/Spinner";

const PaymentForm = ({
  clientSecret,
  productItems,
  donationItems,
  productTotal,
  donationTotal,
  formValues,
  total,
}) => {
  const [stripe, setStripe] = useState(null);
  const [elements, setElements] = useState(null);
  const [paymentResponse, setPaymentResponse] = useState(null);
  const [isStripeLoading, setIsStripeLoading] = useState(true);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Use effect to attach Stripe's payment element to the DOM
  useEffect(() => {
    if (clientSecret) {
      const stripeInstance = window.Stripe(process.env.REACT_APP_STRIPE_KEY);
      const appearance = {
        /* appearance */
      };
      const options = {
        layout: {
          type: "tabs",
          defaultCollapsed: false,
        },
      };
      const elementsInstance = stripeInstance.elements({
        clientSecret,
        appearance,
      });
      const cardElement = elementsInstance.create("payment", options);
      cardElement.mount("#payment-element");

      cardElement.on("ready", () => {
        setIsStripeLoading(false);
      });

      setStripe(stripeInstance);
      setElements(elementsInstance);
    }
  }, [clientSecret]);

  // Handles creating and submitting the order
  const handleSubmitPayment = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    if (!stripe || !elements) return;

    const paymentElement = elements.getElement("payment");

    if (!paymentElement) {
      setPaymentResponse("Payment Element is not loaded.");
      return;
    }

    setIsStripeLoading(true);

    try {
      const { error, setupIntent } = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: window.location.href,
        },
        redirect: "if_required",
      });

      if (error) {
        setPaymentResponse(error.message);
      } else if (setupIntent && setupIntent.status === "succeeded") {
        // We now have setupIntent with an attached payment_method
        const paymentMethodId = setupIntent.payment_method;

        // Send paymentMethodId to server to handle multiple paymentIntents
        const data = {
          paymentMethod: paymentMethodId,
          productItems: productItems,
          productTotal: productTotal,
          donationItems: donationItems,
          donationTotal: donationTotal,
          total: total,
          address: formValues,
        };

        const response = await createOrderPayment(data);

        if (response && response.results) {
          // Check if all objects in response.results have succeeded
          const allSucceeded = response.results.every(
            (result) => result.status === "succeeded"
          );

          if (allSucceeded) {
            setPaymentResponse({ status: "success" });
            setShowSuccessModal(true);
          } else {
            setPaymentResponse({ status: "failed" });
          }
        }
      }
    } catch (error) {
      setPaymentResponse("An error occurred during payment.");
      console.error(error);
    } finally {
      setIsStripeLoading(false);
    }
  };

  // Closes the success modal and clears the cart from global state
  const closeModal = () => {
    setShowSuccessModal(false);

    // Clear cart
    dispatch(cartActions.clearCart());
    navigate("/");
  };

  return (
    <div>
      <h2 className="block text-left text-2xl !text-white pb-4 !font-fingerpaint">
        Payment Information
      </h2>

      <form id="payment-form" className="" onSubmit={handleSubmitPayment}>
        <div id="payment-element"></div>
        <PrimaryButton
          type="submit"
          className={`!mt-4 !mx-auto ${isLoading && "!cursor-wait"}`}
        >
          {isLoading ? <Spinner size={28} /> : "Checkout"}
        </PrimaryButton>
      </form>

      {showSuccessModal && (
        <SuccessModal
          message="Payment succeeded! You will receive a confirmation email shortly."
          onClose={closeModal}
          showSuccessModal={showSuccessModal}
        />
      )}
    </div>
  );
};

export default PaymentForm;
