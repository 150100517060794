import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faAngleDown,
  faAngleUp,
} from "@fortawesome/free-solid-svg-icons";
import classes from "./Header.module.css";

// THIS IS THE MOBILE HEADER. CONTAINS ALL NAV ITEMS

const MobileHeader = ({
  HEADER_DROPDOWNS,
  toggleDropdown,
  visibleDropdown,
  hideDropdown,
  localUserData,
  logout,
  location,
  back,
  cartTotals,
  toggleDropdownMobile,
}) => {
  return (
    <div className="flex items-center justify-start grow">
      {/* Mobile nav */}
      <div
        className={`flex flex-1 justify-end items-center gap-8 pr-0 lg:pr-12`}
      >
        <FontAwesomeIcon
          icon={faBars}
          className="cursor-pointer !text-lg ml-4"
          onClick={() => {
            toggleDropdown(HEADER_DROPDOWNS.hamburger);
          }}
        />

        {/* Dropdown content here */}
        {visibleDropdown !== null && (
          <div
            className={`${classes.header_nav_dropdown} ${
              classes.header_nav_dropdown_mobile
            } !overflow-scroll md:!overflow-hidden ${
              visibleDropdown && "!pb-16"
            } !bg-orange`}
          >
            {localUserData ? (
              <>
                <Link
                  to="#"
                  onClick={logout}
                  className="text-white py-3 px-4 text-sm block text-left border-b border-zinc-300 font-roboto"
                >
                  Logout
                </Link>
                <Link
                  to="/profile"
                  className="text-white py-3 px-4 text-sm block text-left border-b border-zinc-300 font-roboto"
                  onClick={hideDropdown}
                >
                  Profile
                </Link>
              </>
            ) : location ? (
              <>
                <Link
                  to="/signup"
                  className="text-white py-3 px-4 text-sm block text-left border-b border-zinc-300 font-roboto"
                  onClick={hideDropdown}
                  state={{ back: back }}
                >
                  Signup
                </Link>
                <Link
                  to="/login"
                  className="text-white py-3 px-4 text-sm block text-left border-b border-zinc-300 font-roboto"
                  onClick={hideDropdown}
                  state={{ back: back }}
                >
                  Login
                </Link>
              </>
            ) : (
              ""
            )}

            {/* Dropdown for Campaigns */}
            <div
              className="cursor-pointer text-white py-3 px-4 no-underline text-sm flex text-left border-b border-zinc-300"
              onClick={() => {
                toggleDropdownMobile(HEADER_DROPDOWNS.donate);
              }}
            >
              <p className="!text-sm" onClick={hideDropdown}>
                Donate
              </p>
              <FontAwesomeIcon
                className="mt-1.5 ml-auto"
                icon={
                  visibleDropdown === HEADER_DROPDOWNS.donate
                    ? faAngleUp
                    : faAngleDown
                }
              />
            </div>
            {visibleDropdown === HEADER_DROPDOWNS.donate && (
              <div className="pl-[32px]">
                <Link
                  to="/campaigns/animal"
                  className="text-white py-3 px-4 text-sm block text-left border-b border-zinc-300 font-roboto"
                  onClick={hideDropdown}
                >
                  Animal Campaigns
                </Link>
                <Link
                  to="/campaigns/product"
                  className="text-white py-3 px-4 text-sm block text-left border-b border-zinc-300 font-roboto"
                  onClick={hideDropdown}
                >
                  Product Campaigns
                </Link>
                <Link
                  to="/campaigns/capital"
                  className="text-white py-3 px-4 text-sm block text-left border-b border-zinc-300 font-roboto"
                  onClick={hideDropdown}
                >
                  Capital Campaigns
                </Link>
              </div>
            )}

            <Link
              to="/petstar"
              className="text-white py-3 px-4 text-sm block text-left border-b border-zinc-300 font-roboto"
              onClick={hideDropdown}
            >
              PetStar
            </Link>
            <Link
              to="/non-profit"
              className="text-white py-3 px-4 text-sm block text-left border-b border-zinc-300 font-roboto"
              onClick={hideDropdown}
            >
              Nonprofits
            </Link>

            {/* Dropdown for Community */}
            <div
              className="cursor-pointer text-white py-3 px-4 no-underline text-sm flex text-left border-b border-zinc-300"
              onClick={() => {
                toggleDropdownMobile(HEADER_DROPDOWNS.community);
              }}
            >
              <p className="!text-sm" onClick={hideDropdown}>
                Community
              </p>
              <FontAwesomeIcon
                className="mt-1.5 ml-auto"
                icon={
                  visibleDropdown === HEADER_DROPDOWNS.community
                    ? faAngleUp
                    : faAngleDown
                }
              />
            </div>
            {visibleDropdown === HEADER_DROPDOWNS.community && (
              <div className="pl-[32px]">
                <Link
                  to="/foster-interest"
                  className="text-white py-3 px-4 text-sm block text-left border-b border-zinc-300 font-roboto"
                  onClick={hideDropdown}
                >
                  Foster Interest
                </Link>
                <Link
                  to="/partners"
                  className="text-white py-3 px-4 text-sm block text-left border-b border-zinc-300 font-roboto"
                  onClick={hideDropdown}
                >
                  Corporate Partners
                </Link>
                <Link
                  to="/events"
                  className="text-white py-3 px-4 text-sm block text-left border-b border-zinc-300 font-roboto"
                  onClick={hideDropdown}
                >
                  Events
                </Link>
              </div>
            )}

            {/* Dropdown for Services */}
            {/* <div
                    className="cursor-pointer text-white py-3 px-4 no-underline text-sm flex text-left border-b border-zinc-300"
                    onClick={() => {
                      toggleDropdownMobile(HEADER_DROPDOWNS.services);
                    }}
                  >
                    <p className="!text-sm" onClick={hideDropdown}>
                      Services
                    </p>
                    <FontAwesomeIcon
                      className="mt-1.5 ml-auto"
                      icon={
                        visibleDropdown === HEADER_DROPDOWNS.services
                          ? faAngleUp
                          : faAngleDown
                      }
                    />
                  </div>
                  {visibleDropdown === HEADER_DROPDOWNS.services && (
                    <div className="pl-[32px]">
                      <Link
                        to="/resources"
                        className="text-white py-3 px-4 text-sm block text-left border-b border-zinc-300 font-roboto"
                        onClick={hideDropdown}
                      >
                        Resources
                      </Link>
                      <Link
                        to="/consulting-services"
                        className="text-white py-3 px-4 text-sm block text-left border-b border-zinc-300 font-roboto"
                        onClick={hideDropdown}
                      >
                        Consulting
                      </Link>
                    </div>
                  )} */}

            {/* Dropdown for Company */}
            <div
              className="cursor-pointer text-white py-3 px-4 no-underline text-sm flex text-left border-b border-zinc-300"
              onClick={() => {
                toggleDropdownMobile(HEADER_DROPDOWNS.company);
              }}
            >
              <p className="!text-sm" onClick={hideDropdown}>
                Company
              </p>
              <FontAwesomeIcon
                className="mt-1.5 ml-auto"
                icon={
                  visibleDropdown === HEADER_DROPDOWNS.company
                    ? faAngleUp
                    : faAngleDown
                }
              />
            </div>
            {visibleDropdown === HEADER_DROPDOWNS.company && (
              <div className="pl-[32px]">
                <Link
                  to="/about-us"
                  className="text-white py-3 px-4 text-sm block text-left border-b border-zinc-300 font-roboto"
                  onClick={hideDropdown}
                >
                  About Us
                </Link>
                <Link
                  to="/faq"
                  className="text-white py-3 px-4 text-sm block text-left border-b border-zinc-300 font-roboto"
                  onClick={hideDropdown}
                >
                  FAQ
                </Link>
                <Link
                  to="/our-team"
                  className="text-white py-3 px-4 text-sm block text-left border-b border-zinc-300 font-roboto"
                  onClick={hideDropdown}
                >
                  Our Team
                </Link>
                <Link
                  to="/blogs"
                  className="text-white py-3 px-4 text-sm block text-left border-b border-zinc-300 font-roboto"
                  onClick={hideDropdown}
                >
                  Blogs
                </Link>
                <Link
                  to="/shop"
                  className="text-white py-3 px-4 text-sm block text-left border-b border-zinc-300 font-roboto"
                  onClick={hideDropdown}
                >
                  Shop
                </Link>
              </div>
            )}

            <Link
              to="/cart"
              className="text-white py-3 px-4 text-sm block text-left border-b border-zinc-300 font-roboto"
              onClick={hideDropdown}
            >
              Cart ({cartTotals})
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default MobileHeader;
