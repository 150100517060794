import React, { useState, useEffect } from "react";
import { dashboard } from "../../../api/Dashboard";
import PrimaryButton from "../../shared/Buttons/PrimaryButton";
import CheckoutBox from "./CheckoutBox";
import LoginSignupModal from "../../shared/LoginSignupModal/LoginSignupModal";
import ProductItems from "./ProductItems";
import DonationItems from "./DonationItems";
import OrderSummary from "./OrderSummary";
import { useSelector, useDispatch } from "react-redux";
import { cartActions } from "../../../store/index";
import Spinner from "../../shared/LoadingSpinner/Spinner";
import EditDonationModal from "./EditDonationModal";
import EditProductModal from "./EditProductModal";

function Cart() {
  const [localUserData, setLocalUserData] = useState(null);
  const [shippingFee, setShippingFee] = useState(2.0);
  const [tax, setTax] = useState(0);
  const [total, setTotal] = useState(0);
  const [isLoginSignupModalOpen, setLoginSignupModalOpenModalOpen] =
    useState(false);
  const [loginOrSignup, setLoginOrSignup] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  // States for donations
  const [donationItems, setDonationItems] = useState([]);
  const [donationTotal, setDonationTotal] = useState(0);
  const [editDonationsModal, setEditDonationsModal] = useState(false);
  const [editedDonation, setEditedDonation] = useState(null);
  const [updatedDonationAmount, setUpdatedDonationAmount] = useState(0);
  const [updatedDonationAmountError, setUpdatedDonationAmountError] =
    useState("");

  // States for products
  const [productItems, setProductItems] = useState(null);
  const [productTotal, setProductTotal] = useState(0);
  const [editProductModal, setEditProductModal] = useState(false);
  const [editedProduct, setEditedProduct] = useState(null);
  const [updatedProduct, setUpdatedProduct] = useState(null);
  const [updatedProductError, setUpdatedProductError] = useState("");

  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  // Removes the overflow for the background when edit modals are open
  useEffect(() => {
    if (editDonationsModal || editProductModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [editDonationsModal, editProductModal]);

  // Fetches donations and products from global state
  useEffect(() => {
    setDonationItems(cart.donations);
    setProductItems(cart.products);
  }, []);

  // Opens the login/signup modal depending on the type
  const openLoginSignupModal = (type) => {
    setLoginOrSignup(type);
    setLoginSignupModalOpenModalOpen(true);
    document.body.style.overflow = "hidden";
  };

  // Closes the login/signup modal
  const closeLoginSignupModal = () => {
    setLoginSignupModalOpenModalOpen(false);
    document.body.style.overflow = "";
  };

  // Refreshes the page after a user successfully signs up/logins
  const handleFormSuccess = (success) => {
    if (success) {
      window.location.reload();
    }
  };

  // Gets localUserData and sets isloading to false
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userDataString = localStorage.getItem("userData");
        if (userDataString) {
          const userData = JSON.parse(userDataString);
          const response = await dashboard(userData.id);
          setLocalUserData(response);

          localStorage.setItem("userData", JSON.stringify(response));
        }
        setIsLoading(false);
      } catch (error) {
        console.error(
          "Failed to parse user data or fetch user profile: ",
          error
        );
        localStorage.removeItem("userData");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("accessToken");
      }
    };

    fetchUserData();
  }, []);

  // Use effect to get the total amount of products
  useEffect(() => {
    if (productItems?.length > 0) {
      const newProductTotal = productItems.reduce(
        (acc, item) => acc + (item.variant_price / 100) * item.quantity,
        0
      );
      setProductTotal(newProductTotal);
    } else {
      setProductTotal(0);
    }
  }, [productItems]);

  // Use effect to get the total amounts of donations
  useEffect(() => {
    if (donationItems?.length > 0) {
      const newDonationTotal = donationItems.reduce(
        (acc, item) => acc + item.amount,
        0
      );
      setDonationTotal(newDonationTotal);
    } else {
      setDonationTotal(0);
    }
  }, [donationItems]);

  // Use effect to get the total
  // Product totals include tax and shipping
  useEffect(() => {
    if (productItems?.length > 0) {
      setTotal(productTotal + donationTotal + shippingFee + tax);
    } else {
      setTotal(donationTotal);
    }
  }, [productTotal, donationTotal, shippingFee, tax]);

  // Handles donation amount change
  const handleDonationChange = (donation, amount) => {
    setEditedDonation(donation);
    setUpdatedDonationAmountError("");
    setUpdatedDonationAmount(amount);
    setEditDonationsModal(true);
  };

  // Handles updating the cart with the new donation amount
  const handleUpdateDonation = () => {
    if (updatedDonationAmount <= 0 || isNaN(updatedDonationAmount)) {
      setUpdatedDonationAmountError("Please set a valid amount");
      return;
    }

    if (editedDonation && updatedDonationAmount > 0) {
      setUpdatedDonationAmountError("");

      // Update the donation amount in the cart
      dispatch(
        cartActions.updateDonationAmount({
          donation_id: editedDonation.donation_id,
          amount: updatedDonationAmount,
        })
      );
      setEditDonationsModal(false); // Close the modal after update
      window.location.reload(); // reload page to reflect the updated cart
    }
  };

  // Handles product quantity change
  const handleProductChange = (currentItem, quantity) => {
    setEditedProduct(currentItem);
    setUpdatedProduct({
      ...currentItem,
      quantity: quantity,
    });
    setUpdatedProductError("");
    setEditProductModal(true);
  };

  // Handles updating the cart with the new product quantity
  const handleUpdateProduct = () => {
    if (!updatedProduct.quantity || updatedProduct.quantity <= 0) {
      setUpdatedProductError("Please set a valid quantity.");
      return;
    }

    try {
      dispatch(
        cartActions.updateProductQuantity({
          product_id: editedProduct.product_id,
          quantity: updatedProduct.quantity,
          product_size: updatedProduct?.product_size,
          product_color: updatedProduct?.product_color,
        })
      );
      setEditProductModal(false);
      window.location.reload();
    } catch (error) {
      console.error("Can't update product.");
    }
  };

  // Deletes the item depending on if it's a product or donation
  const handleDelete = (product, donation_id) => {
    try {
      if (product) {
        // Update the global cart state for products
        dispatch(
          cartActions.removeProductFromCart({
            product_id: product.product_id,
            product_size: product?.product_size,
            product_color: product?.product_color,
          })
        );
      } else if (donation_id) {
        // Update the global cart state for donations
        dispatch(
          cartActions.removeDonationFromCart({ donation_id: donation_id })
        );
      }

      window.location.reload();
    } catch (error) {
      console.error("Error removing item from cart:", error);
    }
  };

  return (
    <div className="mb-16 sm:grow pt-40 px-4 sm:px-16">
      {isLoading ? (
        <div className="flex flex-row mx-auto mt-16 w-full pb-24 justify-center">
          <Spinner />
        </div>
      ) : (
        <>
          <h1 className="!text-4xl text-left text-orange font-light">
            Checkout
          </h1>

          <div className="flex flex-col sm:flex-row mt-8 w-full gap-20">
            {/* Left side with Products and Donations */}
            <div className={`flex flex-col w-full sm:w-[60%] gap-9 `}>
              {/* Products and donations are both empty */}
              {productItems?.length === 0 && donationItems?.length === 0 && (
                <>
                  {/* Product box */}
                  <ProductItems
                    productItems={productItems}
                    handleProductChange={handleProductChange}
                    handleDelete={handleDelete}
                    productTotal={productTotal}
                  />

                  {/* Donations box */}
                  <DonationItems
                    donationItems={donationItems}
                    handleDonationChange={handleDonationChange}
                    handleDelete={handleDelete}
                    donationTotal={donationTotal}
                  />
                </>
              )}

              {/* Products exist, donations are empty */}
              {productItems?.length > 0 && donationItems?.length === 0 && (
                <>
                  {/* Product box */}
                  <ProductItems
                    productItems={productItems}
                    handleProductChange={handleProductChange}
                    handleDelete={handleDelete}
                    productTotal={productTotal}
                  />
                </>
              )}

              {/* Products are empty, donations exist */}
              {productItems?.length === 0 && donationItems?.length > 0 && (
                <>
                  {/* Donations box */}
                  <DonationItems
                    donationItems={donationItems}
                    handleDonationChange={handleDonationChange}
                    handleDelete={handleDelete}
                    donationTotal={donationTotal}
                  />
                </>
              )}

              {/* Both are not empty */}
              {productItems?.length > 0 && donationItems?.length > 0 && (
                <>
                  {/* Product box */}
                  <ProductItems
                    productItems={productItems}
                    handleProductChange={handleProductChange}
                    handleDelete={handleDelete}
                    productTotal={productTotal}
                  />

                  {/* Donations box */}
                  <DonationItems
                    donationItems={donationItems}
                    handleDonationChange={handleDonationChange}
                    handleDelete={handleDelete}
                    donationTotal={donationTotal}
                  />
                </>
              )}
            </div>

            {/* Right side with Login/Signup, OrderSummary, and CheckoutBox */}
            <div className="w-full sm:w-[40%] flex flex-col gap-9">
              {!localUserData && (
                <section className="bg-blue rounded-xl35 p-8 shadow-cartBoxes">
                  <h1 className="font-fingerpaint !text-white text-left !text-2xl !font-light mb-4">
                    Login To Start Purchasing
                  </h1>
                  <div className="flex gap-4">
                    {isLoginSignupModalOpen && (
                      <LoginSignupModal
                        loginOrSignup={loginOrSignup}
                        isOpen={openLoginSignupModal}
                        onClose={closeLoginSignupModal}
                        onFormSuccess={handleFormSuccess}
                      />
                    )}
                    <PrimaryButton
                      onClick={() => openLoginSignupModal("login")}
                    >
                      Login
                    </PrimaryButton>
                    <PrimaryButton
                      onClick={() => openLoginSignupModal("signup")}
                    >
                      Sign up
                    </PrimaryButton>
                  </div>
                </section>
              )}

              <OrderSummary
                productItems={productItems}
                donationItems={donationItems}
                donationTotal={donationTotal}
                productTotal={productTotal}
                shippingFee={shippingFee}
                total={total}
              />

              {localUserData && (
                <CheckoutBox
                  total={total}
                  productTotal={productTotal}
                  donationTotal={donationTotal}
                  localUserData={localUserData}
                  productItems={productItems}
                  donationItems={donationItems}
                />
              )}
            </div>
          </div>
        </>
      )}

      {/* Edit Donation Modal */}
      {editDonationsModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 px-4">
          <EditDonationModal
            editedDonation={editedDonation}
            updatedDonationAmount={updatedDonationAmount}
            updatedDonationAmountError={updatedDonationAmountError}
            setEditDonationsModal={setEditDonationsModal}
            handleUpdateDonation={handleUpdateDonation}
            handleDonationChange={handleDonationChange}
          />
        </div>
      )}

      {/* Edit Product Modal */}
      {editProductModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 px-4">
          <EditProductModal
            editedProduct={editedProduct}
            updatedProduct={updatedProduct}
            updatedProductError={updatedProductError}
            handleUpdateProduct={handleUpdateProduct}
            handleProductChange={handleProductChange}
            setEditProductModal={setEditProductModal}
          />
        </div>
      )}
    </div>
  );
}

export default Cart;
