import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FosterTable } from "./FosterTable/FosterTable";
import { fosterList } from "../../../api/FosterSubmit";
import { useSelector } from "react-redux";
import {
  IsWithinLastMonth,
  isWithinLastWeek,
} from "../../shared/Utils/DateFilters";
import Select from "@mui/material/Select";
import { MenuItem } from "@mui/material";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// TODO:
// Fix the filter by drop down for time range

// Arrow for drop down buttons
const CustomIcon = (props) => (
  <FontAwesomeIcon
    icon={faChevronDown}
    {...props}
    style={{ color: "#AAAAAA" }}
  />
);

export default function FosterList() {
  const [localUserData, setLocalUserData] = useState(null);
  const [fosterData, setFosterData] = useState(null);
  const [allFosters, setAllFosters] = useState(null);

  const userData = useSelector((state) => state.userData.userData);
  const [filter, setFilter] = useState("Filter by");

  const getFosterList = async () => {
    try {
      const response = await fosterList();

      if (response) {
        setFosterData(response);
        setAllFosters(response);
      }
    } catch (error) {
      console.error("Error getting Fosters: ", error);
    }
  };

  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      setLocalUserData(JSON.parse(userData));
    }
  }, [userData]);

  useEffect(() => {
    if (localUserData) {
      getFosterList();
    }
  }, [localUserData]);

  const handleFilterChange = (e) => {
    const filter = e.target.value;
    setFilter(filter);

    let filterData = [];
    if (filter === "This Week") {
      filterData = fosterData.filter(() => {
        return isWithinLastWeek(new Date(fosterData.created_at));
      });
      setFosterData(filterData);
    } else if (filter === "This Month") {
      filterData = fosterData.filter(() => {
        return IsWithinLastMonth(new Date(fosterData.created_at));
      });
      setFosterData(filterData);
    } else {
      setFosterData(allFosters);
    }
  };

  return (
    <>
      <div className="mx-auto pt-0 lg:pt-12 w-full lg:w-4/6">
        <div className={`flex justify-center flex-col items-center gap-2`}>
          <h2 className="text-3xl text-left font-bold my-2 text-center !text-orange">
            Fosters
          </h2>
        </div>
        {/* <div className="my-2">
          <Select
            className="!w-36 !h-8 !text-black !font-normal !rounded-lg"
            value={filter}
            IconComponent={CustomIcon}
            MenuProps={{
              PaperProps: {
                sx: {
                  backgroundColor: "#f3f3f3", // Dropdown background grey
                },
              },
            }}
            onChange={handleFilterChange}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#d1d5db", // Default border color
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "black", // Focused border color
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#9ca3af", // Hover border color
              },
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "#d1d5db", // Ensure consistency
                },
              },
            }}
          >
            <MenuItem value="Filter by">Filter by</MenuItem>
            <MenuItem value="month">This Month</MenuItem>
            <MenuItem value="week">This Week</MenuItem>
          </Select>
        </div> */}
        {fosterData ? <FosterTable data={fosterData} /> : ""}
      </div>
    </>
  );
}
