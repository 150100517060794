import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getNpInfo } from "../../../api/NonprofitInfo";
import {
  getAllPaginatedCampaigns,
  getTotalCampaigns,
} from "../../../api/Campaigns";
import classes from "./DashboardContent.module.css";
import dashboardClasses from "../Dashboard.module.css";
import Spinner from "../../shared/LoadingSpinner/Spinner";
import DashboardBanner from "./DashboardBanner";
import UpdatesDropdown from "./UpdatesDropdown";
import CampaignCompletePopup from "./CampaignCompletePopup";
import { campaignPopupShown } from "../../../api/CampaignPopupShown";
import { retrieveStripeAccount } from "../../../api/RefreshStripeLink";
import CampaignsComponent from "./CampaignsComponent";
import DonationsComponent from "./DonationsComponent";
import { fetchUpdatesByNP } from "../../../api/Updates";
import { getNonprofitSubscription } from "../../../api/NonprofitSubscribe";
import { deleteNPUpdateEmail } from "../../../api/SendEmail";
import { fetchNPDonations } from "../../../api/Donations";

function isWithinLastWeek(date) {
  let currentDate = new Date();
  let differenceMilliseconds = currentDate - date;
  let millisecondsInDay = 1000 * 60 * 60 * 24;
  let daysDifference = Math.floor(differenceMilliseconds / millisecondsInDay);
  return daysDifference <= 7;
}

function isSameMonthAsToday(date) {
  let currentDate = new Date();
  return (
    date.getFullYear() === currentDate.getFullYear() &&
    date.getMonth() === currentDate.getMonth()
  );
}

function DashboardContent({ setTab }) {
  const [localUserData, setLocalUserData] = useState(null);
  const [npData, setNpData] = useState(null);
  const [campaignModal, setCampaignModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // States for Dashboard Banner
  const [totalCampaigns, setTotalCampaigns] = useState(0);

  // States for Task/Updates Bar
  const [updates, setUpdates] = useState([]);
  const [subscriptionExists, setSubscriptionExists] = useState(false);
  const [subscriptionActive, setSubscriptionActive] = useState(false);
  const [taskModalContent, setTaskModalContent] = useState("");
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
  const [showSubscription, setShowSubscription] = useState(true);
  const [isTaskBarLoading, setIsTaskBarLoading] = useState(true); // state to load task bar component
  const [shouldExpandAccordion, setShouldExpandAccordion] = useState(true);
  const [fetchedUpdates, setFetchedUpdates] = useState([]);

  // States for Campaigns Component
  const [isCampaignsLoading, setIsCampaignsLoading] = useState(true); // state to load campaigns component
  const [campaigns, setCampaigns] = useState([]);
  const [ifCampaignExists, setIfCampaignExists] = useState(true);

  // States for Donations Component
  const [donations, setDonations] = useState([]);
  const [completedDonations, setCompletedDonations] = useState("");
  const [weeklyDonations, setWeeklyDonations] = useState([]);
  const [monthlyDonations, setMonthlyDonations] = useState([]);

  const navigate = useNavigate();

  // Stops the background from scrolling if a modal is open
  useEffect(() => {
    if (campaignModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [campaignModal]);

  // Fetches user data from local storage
  useEffect(() => {
    const userDataString = localStorage.getItem("userData");

    if (userDataString) {
      try {
        const userData = JSON.parse(userDataString);
        setLocalUserData(userData);
      } catch (error) {
        console.error("Failed to parse user data: ", error);
        localStorage.removeItem("userData");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("accessToken");
        navigate("/");
      }
    }
  }, [navigate]);

  // Popup for campaigns with donation goals reached
  useEffect(() => {
    if (localUserData != null && localUserData.nonprofit) {
      const campaignPopupContent = async () => {
        try {
          const response = await campaignPopupShown(localUserData.nonprofit);
          setCompletedDonations(response);

          return response;
        } catch (error) {
          console.error("Error: ", error);
        }
      };
      campaignPopupContent();
    }
  }, [localUserData]);

  // Gets NP data
  const fetchNpInfo = async () => {
    try {
      const response = await getNpInfo(localUserData.nonprofit);

      // Handles redirect from Stripe's onboarding
      // If successful, removes the task from pending task
      if (
        response &&
        response.is_approved &&
        response.stripe_connect_link !== ""
      ) {
        // Else get stripe account info (meaning NPs already set up their account with us)
        // Connects the stripe account to the NP in the backend
        await retrieveStripeAccount(localUserData.nonprofit);
      }
      return response;
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  // Gets paginated campaigns
  const fetchCampaigns = async (page, search, nonprofitId, filter_param) => {
    try {
      const response = await getAllPaginatedCampaigns(
        page,
        search,
        nonprofitId,
        filter_param
      );

      if (page === 1) {
        setCampaigns(response.results);
      } else {
        setCampaigns([...campaigns, ...response.results]);
        setCampaigns([...campaigns, ...response.results]);
      }

      if (!response.next) {
        setIfCampaignExists(false);
      } else {
        setIfCampaignExists(true);
      }
      return response;
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  // Gets donations from campaigns and sets weekly/monthly
  const getDonations = async (npId) => {
    if (!npId) {
      console.error("No nonprofit ID provided");
      return;
    }

    try {
      const response = await fetchNPDonations(npId);

      let donationList = [];
      let weeklyDonations = [];
      let monthlyDonations = [];

      // Process each donation
      for (const donation of response) {
        // Add to the overall donation list
        donationList.push(donation);

        // Check if the donation was made within the last week
        if (isWithinLastWeek(new Date(donation.created_at))) {
          weeklyDonations.push(donation);
        }

        // Check if the donation was made in the same month as today
        if (isSameMonthAsToday(new Date(donation.created_at))) {
          monthlyDonations.push(donation);
        }
      }

      // Sort donationList by created_at (most recent first)
      donationList.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );

      // Update state with sorted donations
      setDonations(donationList);
      setWeeklyDonations(weeklyDonations);
      setMonthlyDonations(monthlyDonations);
    } catch (error) {
      console.error("Error fetching donations:", error);
    }
  };

  // Fetches updates by NP and adds an onClick to each update
  // Task Modal content depends on the task
  const fetchUpdates = async (id) => {
    try {
      const response = await fetchUpdatesByNP(id);
      if (response) {
        const enhancedUpdates = response.map((update) => {
          // Customize the `onClick` fields based on update properties
          let onClick = () => {};

          // Change the modal content based on what the task is
          if (update.description === "Complete Stripe Onboarding") {
            onClick = () => {
              setTaskModalContent(
                <div className="text-left">
                  <p>
                    To complete your Stripe onboarding, please follow the
                    provided steps. This step will allow you to receive
                    donations directly to your bank account.
                  </p>
                  <br></br>
                  <p>1. Go to 'Nonprofit Profile' tab.</p>
                  <p>
                    2. Click on 'Complete Onboarding' button under 'Account
                    Onboarding'.
                  </p>
                  <p>3. Follow Stripe's Onboarding until completion.</p>
                  <p>
                    4. You will be redirected back to your dashboard once
                    complete!
                  </p>
                </div>
              );
              setIsTaskModalOpen(true);
            };
          } else if (update.description === "Upload Donor List") {
            onClick = () => {
              setTaskModalContent(
                <div className="text-left">
                  <p>
                    To upload your donor list, please follow the provided steps.
                    This step enables you to organize all your donors from a
                    single location.
                  </p>
                  <br></br>
                  <p>1. Go to 'Donor List' tab.</p>
                  <p>
                    2. If you need a Donor List Template, click on 'Download
                    Donor List CSV Template'. Otherwise, please click on 'Upload
                    Donor List'.
                  </p>
                  <p>
                    3. Once uploaded, refresh the page and you will see your
                    donors!
                  </p>
                </div>
              );
              setIsTaskModalOpen(true);
            };
          } else if (update.description === "Create an Animal Campaign") {
            onClick = () => {
              setTaskModalContent(
                <div className="text-left">
                  <p>
                    To create an animal campaign, please follow the provided
                    steps. This step requires you to complete Strip's onboarding
                    first.
                  </p>
                  <br></br>
                  <p>
                    1. On the 'Dasboard' tab, scroll down to 'Your Campaigns'
                    section.
                  </p>
                  <p>
                    2. Click on 'New Animal Campaign' and fill out all the
                    information about your animal.
                  </p>
                  <p>
                    3. After clicking 'Save Campaign,' your first campaign will
                    be successfully created!
                  </p>
                </div>
              );
              setIsTaskModalOpen(true);
            };
          } else if (update.description === "Email Donor List") {
            onClick = () => {
              setTaskModalContent(
                <div className="text-left">
                  <p>
                    To email your donor list, please make sure you have
                    completed Stripe's onboarding, created an animal campaign,
                    and uploaded your donors list. Once all 3 are completed,
                    please follow the provided steps:
                  </p>
                  <br></br>
                  <p>
                    1. On the 'Dasboard' tab, scroll down to 'Your Campaigns'
                    section.
                  </p>
                  <p>
                    2. Click on the animal campaign you want to send to your
                    donors.
                  </p>
                  <p>
                    3. Click on 'New Email' and select an email template to use.
                  </p>
                  <p>
                    4. Complete the information using your chosen template and
                    click "Send". Your email will then be sent to all your
                    donors!
                  </p>
                </div>
              );
              setIsTaskModalOpen(true);
            };
          }

          return {
            ...update,
            onClick,
          };
        });

        setUpdates(enhancedUpdates);
        return enhancedUpdates;
      }
      return []; // Return an empty array if no updates are found
    } catch (error) {
      console.error("Error in fetchUpdates:", error);
      return []; // Return an empty array on error
    }
  };

  // Fetches subscription status and updates state
  const fetchSubscriptionStatus = async (id) => {
    try {
      const response = await getNonprofitSubscription(id);
      const subscription = response[0] || null;

      setSubscriptionExists(!!subscription);
      setSubscriptionActive(subscription?.activated || false);
      setShowSubscription(!subscription?.activated);
    } catch (error) {
      console.error("Error fetching subscription status:", error);
    }
  };

  // Checks if the NP has sent emails before and delete the NP Update for "Email Donors List"
  const deleteNPUpdateEmails = async (id) => {
    try {
      const response = await deleteNPUpdateEmail(id);
      return response;
    } catch (error) {
      console.error("Error deleting NP Update for 'Email Donors List':", error);
    }
  };

  // Filters out "Complete Stripe Onboarding", "Upload Donor List" and "Create an Animal Campaign"
  // for current users
  // Once the user goes to NPProfle, Donor List, or creates a new campaign, these updates will be deleted in the database
  const checkingTasks = (updates, npData) => {
    // Check for required tasks
    const hasCompleteOnboardingUpdate = updates.some(
      (update) => update.description === "Complete Stripe Onboarding"
    );
    const hasDonorsListUpdate = updates.some(
      (update) => update.description === "Upload Donor List"
    );

    // Check if donors exist with "upload" as the source
    const hasUploadSource = npData?.donors?.some(
      (donor) => donor.source === "upload"
    );

    const hasCampaignsUpdate = updates.some(
      (update) => update.description === "Create a Campaign"
    );

    if (campaigns && campaigns.length > 0) {
      if (hasCampaignsUpdate) {
        setUpdates((prev) =>
          prev.filter((update) => update.description !== "Create a Campaign")
        );
      }
    }

    // Handle Stripe onboarding update
    if (npData.stripe_account?.charges_enabled && hasCompleteOnboardingUpdate) {
      setUpdates((prev) =>
        prev.filter(
          (update) => update.description !== "Complete Stripe Onboarding"
        )
      );
    }

    // Handle Donors List update
    if (hasUploadSource && hasDonorsListUpdate) {
      setUpdates((prev) =>
        prev.filter((update) => update.description !== "Upload Donor List")
      );
    }
  };

  // Fetches everything from the NP data to task bar upates
  // If everything is loaded, the isLoading is switched to false
  useEffect(() => {
    if (localUserData && localUserData.nonprofit) {
      const fetchAllData = async () => {
        try {
          const [tempNpData, tempCampaigns, tempTotalCampaigns] =
            await Promise.all([
              fetchNpInfo(),
              fetchCampaigns(1, "", localUserData.nonprofit),
              getTotalCampaigns(localUserData.nonprofit),
            ]);
          if (!tempNpData || !tempNpData.id) {
            throw new Error("Nonprofit data is not available");
          }

          const fetchUpdatesPromise = fetchUpdates(tempNpData.id);
          const fetchSubscriptionStatusPromise = fetchSubscriptionStatus(
            tempNpData.id
          );
          const deleteNPUpdateEmailsPromise = deleteNPUpdateEmails(
            tempNpData.id
          );

          const updates = await Promise.all([
            fetchUpdatesPromise,
            fetchSubscriptionStatusPromise,
            deleteNPUpdateEmailsPromise,
          ]);

          setNpData(tempNpData);
          setCampaigns(tempCampaigns.results);
          getDonations(tempNpData.id);
          checkingTasks(updates[0], tempNpData);

          setTotalCampaigns(tempTotalCampaigns.total_campaigns);
          setFetchedUpdates(updates[0]); // Store updates in state for task bar logic
        } catch (error) {
          console.error("Error in fetchAllData:", error);
        } finally {
          setIsLoading(false); // Stop showing the spinner
          setIsCampaignsLoading(false); // Allow CampaignsComponent rendering
        }
      };
      fetchAllData();
    }
  }, [localUserData]);

  // Useeffect to handle if the accordion should be expanded
  useEffect(() => {
    if (
      (!fetchedUpdates || fetchedUpdates.length === 0) &&
      !showSubscription &&
      subscriptionExists
    ) {
      setShouldExpandAccordion(false);
    } else {
      setShouldExpandAccordion(true);
    }
    setIsTaskBarLoading(false); // Allow Task Bar Component rendering
  }, [fetchedUpdates, showSubscription, subscriptionExists]);

  return (
    <>
      {isLoading ? (
        <div className="flex flex-row mt-[25vh] w-full h-full justify-center">
          <Spinner />
        </div>
      ) : (
        <>
          {localUserData &&
            localUserData.nonprofit &&
            completedDonations != "" && (
              <CampaignCompletePopup completedlist={completedDonations} />
            )}

          <div
            className={`flex flex-col mx-auto sm:gap-3.5 pt-0 lg:pt-12 w-full lg:w-4/6`}
          >
            <div className="sm:mb-2 flex flex-col mb-16">
              <h1 className={`${classes.header} !mt-0 text-orange !text-3xl`}>
                Dashboard
              </h1>
            </div>

            {!isTaskBarLoading && (
              <UpdatesDropdown
                npData={npData}
                campaigns={campaigns}
                updates={updates}
                subscriptionActive={subscriptionActive}
                setSubscriptionActive={setSubscriptionActive}
                subscriptionExists={subscriptionExists}
                isTaskModalOpen={isTaskModalOpen}
                setIsTaskModalOpen={setIsTaskModalOpen}
                setShowSubscription={setShowSubscription}
                showSubscription={showSubscription}
                taskModalContent={taskModalContent}
                shouldExpandAccordion={shouldExpandAccordion}
              />
            )}

            <DashboardBanner
              donations={donations}
              totalCampaigns={totalCampaigns}
              npId={npData.id}
            />

            {!isCampaignsLoading && (
              <CampaignsComponent
                npData={npData}
                localUserData={localUserData}
                campaigns={campaigns}
                setCampaigns={setCampaigns}
                fetchCampaigns={fetchCampaigns}
                ifCampaignExists={ifCampaignExists}
              />
            )}

            <DonationsComponent
              donations={donations}
              setTab={setTab}
              weeklyDonations={weeklyDonations}
              monthlyDonations={monthlyDonations}
            />
          </div>
        </>
      )}
    </>
  );
}

export default DashboardContent;
