import React, { useState, useEffect } from "react";
import PrimaryButton from "../../shared/Buttons/PrimaryButton";
import TextInput from "../../shared/Inputs/TextInput";
import DropDown from "../../shared/Inputs/Dropdown";
import { State, City } from "country-state-city";

const AddressForm = ({
  handleAddressSubmit,
  localUserData,
  formValues,
  formErrors,
  setFormValues,
  setFormErrors,
}) => {
  const [errorMsg, setErrorMsg] = useState("");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const country = ["US"];

  // Gets states depending on the country
  useEffect(() => {
    const countryCode = "US";
    const countryStates = State.getStatesOfCountry(countryCode);
    setStates(countryStates);
  }, []);

  // Validates inputs for the form
  const validateForm = () => {
    const errors = {};

    if (!formValues.address1.trim()) {
      errors.address1 = "Address is required";
    }
    if (!formValues.city.trim()) {
      errors.city = "City is required";
    }
    if (!formValues.state.trim()) {
      errors.state = "State is required";
    }
    if (!formValues.zip.trim()) {
      errors.zip = "Zip code is required";
    }
    if (!formValues.phone.trim()) {
      errors.phone = "Phone  number is required";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0; // Returns true if no errors
  };

  // Submission logic for submitting address
  // Goes back to CheckoutBox component and goes on to the next step
  const handleSubmit = async (e) => {
    e.preventDefault();

    setErrorMsg("");

    if (validateForm()) {
      if (localUserData) {
        try {
          handleAddressSubmit(e);
          setErrorMsg("");
        } catch (error) {
          if (error.response && error.response.status === 500) {
            setErrorMsg("Internal server error. Please try again later.");
          } else {
            setErrorMsg(
              "An error occurred while submitting the form. Please try again."
            );
          }
        }
      } else {
        setErrorMsg("Please sign up or login before proceeding!");
      }
    }
  };

  // Handles input changes and resets formErrors
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Restarts formErrors when input changes
    setFormErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  // Attaches state to the formValue
  const handleStateChange = (e) => {
    const selectedState = e.target.value;
    setFormValues({ ...formValues, state: selectedState, city: "" });
    setFormErrors({ ...formErrors, state: "" });

    // Fetch cities based on the selected state
    const stateCities = City.getCitiesOfState("US", selectedState);
    setCities(stateCities);
  };

  return (
    <div>
      <h2 className="text-left !text-2xl !text-white mb-4 !font-fingerpaint !font-light">
        Delivery Address
      </h2>
      <form>
        <TextInput
          label="Street Address"
          subLabel="(Required)"
          labelClassName="!text-white"
          type="text"
          value={formValues.address1}
          name="address1"
          placeholder="Address"
          onChange={handleInputChange}
          errorMessage={formErrors.address1}
        />

        <TextInput
          label="Unit/Apartment"
          labelClassName="!text-white"
          type="text"
          value={formValues.address2}
          name="address2"
          placeholder="Unit/Apartment"
          onChange={handleInputChange}
          errorMessage={formErrors.address2}
        />

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 ">
          <DropDown
            name="state"
            label="State"
            subLabel="(Required)"
            labelClassName="!text-white"
            placeholder="Select a State"
            onChange={handleStateChange}
            value={formValues.state}
            options={states}
            optionKey="isoCode"
            optionLabel="name"
            errorMessage={formErrors.state}
          />

          <DropDown
            name="city"
            label="City"
            subLabel="(Required)"
            labelClassName="!text-white"
            placeholder="Select a City"
            onChange={handleInputChange}
            disabled={!formValues.state}
            value={formValues.city}
            options={cities}
            optionKey="name"
            optionLabel="name"
            errorMessage={formErrors.city}
          />
        </div>

        {/* Country and Zip Code */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <DropDown
            name="Country"
            label="Country"
            subLabel="(Required)"
            labelClassName="!text-white"
            placeholder="Select Country"
            onChange={handleInputChange}
            disabled={false}
            options={country}
            optionKey=""
            className={"w-full"}
            value={formValues.country}
            optionLabel={0}
            selectClassName={"!h-8"}
          />
          <TextInput
            label="Zip"
            subLabel="(Required)"
            labelClassName="!text-white"
            type="text"
            value={formValues.zip}
            name="zip"
            placeholder="Zip Code"
            onChange={handleInputChange}
            errorMessage={formErrors.zip}
          />
        </div>

        <TextInput
          label="Phone Number"
          subLabel="(E.g. 123-456-7890)"
          labelClassName="!text-white"
          type="text"
          value={formValues.phone}
          name="phone"
          placeholder="Phone Number"
          onChange={handleInputChange}
          errorMessage={formErrors.phone}
        />

        {/* Proceed Button */}
        <PrimaryButton
          type="button"
          className="!w-full"
          onClick={(e) => {
            handleSubmit(e);
          }}
        >
          Proceed to Payment
        </PrimaryButton>
      </form>
      <div className="mx-auto mt-2">
        {errorMsg && <p className="text-error">{errorMsg}</p>}
      </div>
    </div>
  );
};

export default AddressForm;
