import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import classes from "./CampaignCard.module.css";
import {
  cardTitleExcerpt,
  nonProfitExcerpt,
} from "../../shared/Utils/CardTitleExcerpt";
import { campaignExcerpt } from "../CampaignSearch/CampaignSearch";
import { extractTextContent } from "../../shared/Utils/ExtractText";
import cardImg from "../../../assets/paw.svg";
import SecondaryButton from "../../shared/Buttons/SecondaryButton";

function CampaignCard({ campaign, handleClick, type }) {
  return (
    <div
      className={classes.campaign_search_card}
      key={campaign.id}
      onClick={() => handleClick(campaign)}
    >
      <div className="flex flex-row justify-center items-center m-auto -mb-6 ">
        <img
          src={cardImg}
          alt=""
          style={{
            width: "1.5rem",
            height: "1.5rem",
            marginRight: "0.5rem",
            marginBottom: "0.5rem",
            border: "none",
          }}
        />
        <p className="text-blue !text-[0.95rem]">
          {nonProfitExcerpt(campaign.nonprofit_name)}
        </p>
      </div>
      <div className="flex flex-row justify-center items-center m-auto -mb-2">
        <FontAwesomeIcon
          icon={faLocationDot}
          style={{
            fontSize: "1.5rem",
            marginRight: "0.5rem",
            marginBottom: "0.5rem",
            border: "none",
            color: "#919191",
          }}
        />
        <p className="text-grey !text-[0.95rem]">
          {campaign.nonprofit_address.city}, {campaign.nonprofit_address.state}
        </p>
      </div>
      {campaign.campaign_images && campaign.campaign_images[0] ? (
        <LazyLoadImage
          src={campaign.campaign_images[0].image_url}
          className={`${classes.campaign_search_card_img} ${
            campaign.campaign_images[0]?.graphic ? classes.graphic_blurred : ""
          }`}
        />
      ) : (
        ""
      )}
      <div className={classes.campaign_search_card_div}>
        <h3 className="font-bold">
          <div className={classes.campaign_title_link}>
            {type === "animal"
              ? campaign?.animal_name.length > 17
                ? cardTitleExcerpt(campaign.animal_name, 17)
                : cardTitleExcerpt(campaign.animal_name, 35)
              : campaign?.campaign_title.length > 17
              ? cardTitleExcerpt(campaign.campaign_title, 17)
              : cardTitleExcerpt(campaign.campaign_title, 35)}
          </div>
        </h3>
        <p className="flex break-words break-keep justify-center items-center break-all w-full">
          {campaignExcerpt(extractTextContent(campaign.description))}
        </p>
        <SecondaryButton className="mx-auto">Donate</SecondaryButton>
      </div>
    </div>
  );
}

export default CampaignCard;
