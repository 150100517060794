import React from "react";
import editClasses from "./EditCampaign.module.css";
import { urlifyName } from "../../shared/Utils/PageUrl";
import QRCode from "qrcode.react";
import SecondaryButton from "../../shared/Buttons/SecondaryButton";

// This is the Share component in the Edit Campaign modal

const CampaignShareBox = ({ campaignData }) => {
  const campaignUrl = campaignData
    ? campaignData.campaign_type === "animal"
      ? `sponsorapet.org/campaigns/animal/${urlifyName(
          campaignData.nonprofit_name
        )}/${urlifyName(campaignData.animal_name)}/${campaignData.id}`
      : `sponsorapet.org/campaigns/${campaignData.campaign_type}/${urlifyName(
          campaignData.nonprofit_name
        )}/${urlifyName(campaignData.campaign_title)}/${campaignData.id}`
    : "";

  const downloadQRCode = () => {
    const canvas = document.getElementById("qrcode");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "CampaignQRCode.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(campaignUrl).then(
      () => {
        alert("Campaign URL copied to clipboard!");
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  // Function to open Facebook share dialog
  const shareToFb = () => {
    const facebookUrl = `https://www.facebook.com/dialog/share?app_id=952019939706310&display=popup&href=${encodeURIComponent(
      campaignUrl
    )}&redirect_uri=https%3A%2F%2Fdevelopers.facebook.com%2Ftools%2Fexplorer`;
    window.open(facebookUrl, "_blank");
  };

  // Function to open Twitter share dialog
  const shareToX = () => {
    const text = `Check out our newest Animal Campaign at`;
    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      text
    )}&url=${encodeURIComponent(campaignUrl)}`;
    window.open(twitterUrl, "_blank");
  };
  return (
    <div
      className={`${editClasses.qrcode_box} flex flex-col justify-center items-center border border-gray-200 rounded-3xl shadow-sm shadow-gray-200`}
    >
      <h2 className="text-2xl font-normal mb-4">Share</h2>
      <QRCode
        id="qrcode"
        value={campaignUrl}
        size={195}
        level={"H"}
        style={{ margin: "0 auto" }}
      />
      <SecondaryButton
        onClick={downloadQRCode}
        className="!mt-8 !py-3 !px-5 !uppercase !w-56"
      >
        Download QR Code
      </SecondaryButton>
      <SecondaryButton
        onClick={copyToClipboard}
        className="!mt-8 !py-3 !px-5 !uppercase !w-56"
      >
        Copy Campaign Link
      </SecondaryButton>
      <SecondaryButton
        onClick={shareToX}
        className="!mt-8 !py-3 !px-5 !uppercase !w-56"
      >
        Share to Twitter
      </SecondaryButton>
      <SecondaryButton
        onClick={shareToFb}
        className="!mt-8 !py-3 !px-5 !uppercase !w-56"
      >
        Share to Facebook
      </SecondaryButton>
    </div>
  );
};

export default CampaignShareBox;
