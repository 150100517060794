const DonationItems = ({
  donationItems,
  handleDelete,
  handleDonationChange,
  donationTotal,
}) => {
  return (
    <div className="bg-gray-100 rounded-xl35 p-8 shadow-cartBoxes">
      <h1 className="font-fingerpaint text-black text-left !text-2xl !font-light mb-4">
        Donations
      </h1>

      {donationItems?.length > 0 &&
        donationItems.map((item, index) => (
          <div key={index} className="my-5">
            <div className="flex flex-row !w-full gap-4">
              {/* Image Section */}
              <div className="w-40 h-36 overflow-hidden">
                {item.image?.image_url ? (
                  <img
                    src={item.image.image_url}
                    alt={item.name}
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <div className="w-full h-full border bg-white"></div>
                )}
              </div>

              {/* Donation Info Section */}
              <div className="gap-1 flex flex-col items-start justify-start text-left">
                <h3 className="text-base font-semibold">{item.name}</h3>
                <p className="m-0 !text-sm">
                  Nonprofit Name: {item.nonprofit_name}
                </p>
                <p className="m-0 !text-sm">Donation: ${item.amount}</p>
                <p className="flex items-center">
                  <span
                    onClick={() => handleDonationChange(item, item.amount)}
                    className="w-8 m-0 !text-sm !text-blue underline underline-offset-2 cursor-pointer hover:font-bold active:font-normal"
                  >
                    Edit
                  </span>
                  <span
                    onClick={() => handleDelete("", item.donation_id)}
                    className="m-0 !text-sm underline underline-offset-2 !text-error ml-5 cursor-pointer hover:font-bold active:font-normal"
                  >
                    Remove
                  </span>
                </p>
              </div>

              {/* Price Section */}
              <div className="ml-auto text-right">
                <h3 className="text-base font-semibold">
                  ${item.amount.toFixed(2)}
                </h3>
              </div>
            </div>
          </div>
        ))}

      {/* Total Section */}
      {donationItems?.length > 0 ? (
        <div className="flex justify-end gap-8 w-full pb-8">
          <span className="text-base font-semibold">Total:</span>
          <span className="text-base font-semibold">
            ${donationTotal.toFixed(2)}
          </span>
        </div>
      ) : (
        <p className="text-center text-blue w-full font-normal font-bold text-base p-8">
          No donations have been added.
        </p>
      )}
    </div>
  );
};

export default DonationItems;
