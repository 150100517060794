import { faMoneyBill, faTv, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { getDonors } from "../../../api/GetDonors";

export default function DashboardBanner({ donations, totalCampaigns, npId }) {
  const [donors, setDonors] = useState(0);
  const allDonations = donations.reduce(
    (acc, donation) => +acc + +donation.amount,
    0
  );
  const roundedDonations = parseFloat(allDonations).toFixed(2);
  const allDonationsWithoutLeadingZeros = roundedDonations.replace(/^0+/, "");

  const getDonorList = async () => {
    try {
      const response = await getDonors(npId);
      if (response.length > 0) {
        setDonors(response.length);
      }
    } catch (error) {
      console.error("Error getting Donors: ", error);
    }
  };

  useEffect(() => {
    if (npId) {
      getDonorList();
    }
  }, [npId]);

  return (
    <div className="bg-white rounded-xl border border-1 !border-bg-gray-900 shadow-md flex flex-row justify-center items-stretch sm:items-center p-6 w-full my-4 mx-auto">
      <div className="px-8 border-r-2 flex flex-grow flex-row max-[1025px]:px-4">
        <div className="hidden sm:flex w-16 h-16 rounded-full bg-blue-100 justify-center items-center mr-2">
          <FontAwesomeIcon icon={faMoneyBill} className="text-blue text-4xl" />
        </div>
        <div className="flex flex-col text-3xl sm:text-4xl">
          <p className="text-blue font-semibold">Donations</p>
          <p className="font-bold">${allDonationsWithoutLeadingZeros}</p>
        </div>
      </div>

      <div className="px-8 border-r-2 flex flex-grow flex-row max-[1025px]:px-4">
        <div className="hidden sm:flex w-16 h-16 rounded-full bg-blue-100 justify-center items-center mr-2">
          <FontAwesomeIcon icon={faUser} className="text-blue text-4xl" />
        </div>
        <div className="flex flex-col text-3xl sm:text-4xl">
          <p className="text-blue font-semibold">Donors Added</p>
          <p className="font-bold">{donors}</p>
        </div>
      </div>

      <div className="px-8 flex flex-grow flex-row max-[1025px]:px-4">
        <div className="hidden sm:flex w-16 h-16 rounded-full bg-blue-100 justify-center items-center mr-2">
          <FontAwesomeIcon icon={faTv} className="text-blue text-4xl" />
        </div>
        <div className="flex flex-col text-3xl sm:text-4xl">
          <p className="text-blue font-semibold">Campaigns Created</p>
          <p className="font-bold">{totalCampaigns}</p>
        </div>
      </div>
    </div>
  );
}
