import { useEffect, useState } from "react";
import { fetchNPDonations } from "../../../api/Donations";
import NonprofitDonationTable from "./NonprofitDonationTable/NonprofitDonationTable";
import dashboardClasses from "../Dashboard.module.css";
import { useSelector } from "react-redux";
import Spinner from "../../shared/LoadingSpinner/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

export default function NonprofitDonationList() {
  const [isLoading, setIsLoading] = useState(false);
  const [localUserData, setLocalUserData] = useState(null);
  const [donationDetails, setDonationDetails] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const userData = useSelector((state) => state.userData.userData);

  // Retrieve user data from localStorage
  useEffect(() => {
    window.scrollTo(0, 0);
    if (Object.keys(userData).length !== 0) {
      setLocalUserData(JSON.parse(userData));
    }
  }, [userData]);

  // Retrieve NP and campaign data
  useEffect(() => {
    if (!localUserData || !localUserData.nonprofit) return;

    setIsLoading(true); // Start loading

    const fetchAndProcessDonations = async () => {
      try {
        const response = await fetchNPDonations(localUserData.nonprofit);

        // Process donations and group by campaign type first, then animal_name
        const groupedByType = response.reduce((acc, donation) => {
          // Assign undefined campaign types to "animal"
          const campaignType = donation.campaign_type || "animal"; // Default to "animal"
          const campaignTitle = donation.campaign_title || "Unknown"; // Default title if undefined

          if (!acc[campaignType]) {
            acc[campaignType] = {};
          }

          if (!acc[campaignType][campaignTitle]) {
            acc[campaignType][campaignTitle] = {
              campaign_title: campaignTitle,
              data: [],
            };
          }

          acc[campaignType][campaignTitle].data.push(donation);
          return acc;
        }, {});

        // Convert the grouped object into an array format for easier rendering
        const donationDetailsArray = Object.entries(groupedByType).map(
          ([campaignType, campaigns]) => ({
            campaign_type: campaignType,
            campaigns: Object.values(campaigns),
          })
        );

        setDonationDetails(donationDetailsArray);
      } catch (error) {
        console.error("Error fetching or processing donations:", error);
      } finally {
        setIsLoading(false); // Stop loading
      }
    };

    fetchAndProcessDonations();
  }, [localUserData]);

  const searchMatch = (s) => {
    return s.toLowerCase().includes(searchValue.toLowerCase());
  };

  if (isLoading) {
    return (
      <div className="flex flex-row ml-auto mt-[25vh] w-full h-full justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className={`mx-auto pt-0 lg:pt-12 w-full lg:w-4/6 mb-14`}>
      <div
        className={`${dashboardClasses.dashboard_block} flex flex-col gap-5 p-4 max-sm:hidden max-md:hidden min-lg:block`}
      >
        <h2 className="text-orange !text-3xl font-bold text-center">
          Campaign Donations
        </h2>
      </div>
      <div className={`flex items-center relative w-full`}>
        <FontAwesomeIcon
          icon={faMagnifyingGlass}
          size="xs"
          className="absolute left-3"
          color="#666666"
        />
        <input
          type="text"
          placeholder="Search By Campaign Name"
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          className={`p-2 pl-8 border text-black border-gray-300 rounded-lg w-full h-7 `}
        />
      </div>

      {donationDetails.length > 0 && (
        <>
          {donationDetails.map((campaignTypeGroup, index) => (
            <div key={index}>
              {/* Render Campaign Type */}
              <h1 className="text-xl font-bold text-orange mt-6 mb-4 text-left !font-roboto">
                {campaignTypeGroup.campaign_type.charAt(0).toUpperCase() +
                  campaignTypeGroup.campaign_type.slice(1)}{" "}
                Campaigns
              </h1>

              {/* Render Campaigns within Campaign Type */}
              {campaignTypeGroup.campaigns.map((campaign, campaignIndex) => {
                if (!searchMatch(campaign.campaign_title)) return null;
                if (campaign.data.length <= 0) return null;

                return (
                  <NonprofitDonationTable
                    name={campaign.campaign_title}
                    key={`${index}-${campaignIndex}`}
                    data={campaign.data}
                  />
                );
              })}
            </div>
          ))}
        </>
      )}
    </div>
  );
}
