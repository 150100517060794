import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { getPaginatedCampaigns } from "../../../api/Campaigns";
import { urlifyName } from "../../shared/Utils/PageUrl";
import CampaignCard from "../CampaignCard/CampaignCard";
import Spinner from "../../shared/LoadingSpinner/Spinner";
import { states } from "states-us";
import SearchHeader from "./SearchHeader";
import SecondaryButton from "../../shared/Buttons/SecondaryButton";

// This page is the search page to render 3 different types of campaigns
// Grabs the campaign data from API depending on the URL pathname
// Renders a different header depending on the type

const ageOptions = [
  { value: -1, label: "All Ages" },
  { value: 1, label: "Newborn" },
  { value: 2, label: "Young" },
  { value: 3, label: "Adult" },
  { value: 4, label: "Senior" },
  { value: 5, label: "Unknown" },
];

const productTypeOptions = [
  { value: "all", label: "All" },
  { value: "food", label: "Food" },
  { value: "toys", label: "Toys" },
  { value: "supplies", label: "Supplies" },
  { value: "beds", label: "Beds" },
  { value: "medical", label: "Medical Equipment" },
  { value: "other", label: "Other" },
];

const projectStatusOptions = [
  { value: "all", label: "All Statuses" },
  { value: "planning", label: "Planning" },
  { value: "construction", label: "Under Construction" },
  { value: "completed", label: "Completed" },
];

export const campaignExcerpt = (plainText) => {
  const EXCERPT_CHARS = 45;
  const EXCERPT_SENTENCES = 1;
  let sentences =
    plainText.split(".").slice(0, EXCERPT_SENTENCES).join(". ") + ".";
  return sentences.length > EXCERPT_CHARS
    ? sentences.substring(0, EXCERPT_CHARS) + "..."
    : sentences;
};

export default function CampaignSearch() {
  // States used by all campaigns
  const [campaigns, setCampaigns] = useState([]);
  const [page, setPage] = useState(1);
  const [IfDataExists, setIfDataExists] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [stateParam, setStateParam] = useState("");
  const [type, setType] = useState("");

  // Search states for different campaigns
  const [animalSearch, setAnimalSearch] = useState({
    search: "",
    species: "",
    age: -1,
    state: "",
    nonprofitId: "",
  });
  const [productSearch, setProductSearch] = useState({
    search: "",
    state: "",
    nonprofitId: "",
    productType: "all",
  });
  const [capitalSearch, setCapitalSearch] = useState({
    search: "", // includes land area, nonprofit
    state: "",
    nonprofitId: "",
    projectStatus: "all",
    completionDate: "all", // All, This week, this month, within 6 months, within a year, 1+ more
  });

  // States for the location (campaign type) and navigation (campaign feature page)
  const location = useLocation();
  const navigate = useNavigate();

  // API call to get campaigns
  // Gets called on first render, every change in search, and load more
  const getAllCampaigns = async (page, searches, type) => {
    try {
      const response = await getPaginatedCampaigns(page, searches, type);

      if (response) {
        setIsLoading(false);
      }

      if (page === 1) {
        setCampaigns(response.results);
      } else {
        setCampaigns((prevCampaigns) => [
          ...prevCampaigns,
          ...response.results,
        ]);
      }

      if (!response.next) {
        setIfDataExists(false);
      } else {
        setIfDataExists(true);
      }
    } catch (error) {
      console.error(`Could not get campaigns`, error);
    }
  };

  // Tracks changes to the URL and sets the campaign type
  // Grabs data based on the type
  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    const campaignType = pathSegments[2];
    setType(campaignType);

    // Clear campaigns and searches when switching types
    setCampaigns([]);
    setAnimalSearch({
      search: "",
      species: "",
      age: -1,
      state: "",
      nonprofitId: "",
    });
    setProductSearch({
      search: "",
      state: "",
      nonprofitId: "",
      productType: "all",
    });
    setCapitalSearch({
      search: "",
      state: "",
      nonprofitId: "",
      projectStatus: "all",
      completionDate: "all",
    });

    if (campaignType === "animal") {
      getAllCampaigns(1, animalSearch, "animal");
    } else if (campaignType === "product") {
      getAllCampaigns(1, productSearch, "product");
    } else if (campaignType === "capital") {
      getAllCampaigns(1, capitalSearch, "capital");
    }
    setPage(1);
  }, [location.pathname]);

  // useEffect for calling the API everytime a user searches something or the type changes
  useEffect(() => {
    if (type === "animal") {
      getAllCampaigns(1, animalSearch, "animal");
    } else if (type === "product") {
      getAllCampaigns(1, productSearch, "product");
    } else if (type === "capital") {
      getAllCampaigns(1, capitalSearch, "capital");
    }
    setPage(1);
  }, [animalSearch, productSearch, capitalSearch]);

  // Scrolls to the top of the page only on first render
  useEffect(() => {
    if (page === 1) {
      window.scrollTo(0, 0);
    }
  }, [page]);

  // Handles clicking on 'Search' button
  const handleSearch = (values) => {
    setPage(1);
    getAllCampaigns(1, values, type);
  };

  const optionsMaps = {
    productType: productTypeOptions,
    projectStatus: projectStatusOptions,
  };

  // Handles attached searches to the campaign state
  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    const finalValue =
      name === "age"
        ? value === -1
          ? -1
          : value
        : optionsMaps[name]
        ? optionsMaps[name].find((option) => option.value === value)?.value ||
          value
        : value;

    if (type === "animal") {
      setAnimalSearch((prevState) => ({
        ...prevState,
        [name]: finalValue,
      }));
    } else if (type === "product") {
      setProductSearch((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else if (type === "capital") {
      setCapitalSearch((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  // Redirects to the Campagin Feature page
  const handleClick = (campaign) => {
    if (type === "animal") {
      navigate(
        `/campaigns/animal/${urlifyName(campaign.nonprofit_name)}/${urlifyName(
          campaign.animal_name
        )}/${campaign.id}`
      );
    } else if (type === "product" || type === "capital") {
      navigate(
        `/campaigns/${type}/${urlifyName(campaign.nonprofit_name)}/${urlifyName(
          campaign.campaign_title
        )}/${campaign.id}`
      );
    }
  };

  // Handles showing more campaigns if available
  const handleLoadMoreButton = (e) => {
    e.preventDefault();

    if (type === "animal") {
      getAllCampaigns(page + 1, animalSearch, "animal");
    } else if (type === "product") {
      getAllCampaigns(page + 1, productSearch, "product");
    } else if (type === "capital") {
      getAllCampaigns(page + 1, capitalSearch, "capital");
    }
    setPage(page + 1);
  };

  return (
    <div>
      <SearchHeader
        handleSearchChange={handleSearchChange}
        animalSearch={animalSearch}
        ageOptions={ageOptions}
        productSearch={productSearch}
        productTypeOptions={productTypeOptions}
        capitalSearch={capitalSearch}
        projectStatusOptions={projectStatusOptions}
        type={type}
        handleSearch={handleSearch}
        stateParam={stateParam}
        states={states}
      />

      <div className="flex flex-col my-8">
        {isLoading ? (
          <div className="flex flex-row mx-auto mt-16 w-full pb-24 justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="w-full flex max-w-md:flex-col flex-wrap justify-center max-w-md:items-center gap-12 max-w-md:gap-4 mx-auto">
            {campaigns && campaigns.length > 0 ? (
              campaigns.map((campaign) => (
                <CampaignCard
                  key={campaign.id}
                  campaign={campaign}
                  handleClick={handleClick}
                  type={type}
                />
              ))
            ) : (
              <p>Sorry, no matching results!</p>
            )}
          </div>
        )}

        {!isLoading && IfDataExists && (
          <SecondaryButton
            type="button"
            onClick={(e) => handleLoadMoreButton(e)}
            className="!mx-auto mt-16"
          >
            Load More
          </SecondaryButton>
        )}
      </div>
    </div>
  );
}
