import { useEffect, useState } from "react";
import UserDonationTable from "./UserDonationTable/UserDonationTable";
import { useSelector } from "react-redux";
import Spinner from "../../shared/LoadingSpinner/Spinner";
import { fetchUserDonations } from "../../../api/Donations";

export default function UserDonationList() {
  const [isLoading, setIsLoading] = useState(true);
  const [localUserData, setLocalUserData] = useState(null);
  const [donationsDetails, setDonationsDetails] = useState([]);
  const [npDonationsDetails, setNpDonationsDetails] = useState([]);

  const userData = useSelector((state) => state.userData.userData);

  const getUserDonations = async (userId) => {
    try {
      const response = await fetchUserDonations(userId);

      if (response) {
        setDonationsDetails(response);
      }
      setIsLoading(false);
    } catch (error) {
      // Handle or throw error
      if (error.response && error.response.status === 500) {
        console.error("500 Internal Server Error");
        throw error;
      } else {
        console.error("Error getting user donations:", error.response);
        throw error;
      }
    }
  };
  // Retrieve user data from localStorage
  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      const parsedUserData = JSON.parse(userData);
      setLocalUserData(parsedUserData);
      if (parsedUserData.general_donations_details)
        setNpDonationsDetails(parsedUserData.general_donations_details);
    }
  }, [userData]);

  useEffect(() => {
    if (localUserData) {
      getUserDonations(localUserData.id);
    }
  }, [localUserData]);

  return (
    <div className={`mx-auto pt-0 lg:pt-12 w-full lg:w-4/6`}>
      {isLoading ? (
        <div className="flex flex-row ml-auto mt-[25vh] w-full h-full justify-center">
          <Spinner />
        </div>
      ) : (
        <UserDonationTable
          data={donationsDetails}
          npData={npDonationsDetails}
        />
      )}
    </div>
  );
}
