import React, { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import { urlifyName } from "../shared/Utils/PageUrl";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useNavigate } from "react-router-dom";
import { getAnnouncements } from "../../api/Announcements";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import {
  getPaginatedCampaigns,
  getAllPaginatedCampaigns,
} from "../../api/Campaigns";
import UsaMap from "./UsaMap/UsaMap";
import classes from "./Homepage.module.css";
import CampaignCard from "../Campaigns/CampaignCard/CampaignCard";
import { subscribeMarketingNewsletter } from "../../api/SubscribeNewsletter";
import { errorHandler } from "../shared/Utils/ErrorHandler";
import Spinner from "../shared/LoadingSpinner/Spinner";
import { useMediaQuery } from "@mui/material";
import Diagram from "./Diagram";
import {
  faArrowRight,
  faPiggyBank,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VerifiedIcon from "@mui/icons-material/Verified";
import PrimaryButton from "../shared/Buttons/PrimaryButton";

// Images
const desktopBackground =
  "https://sap-app-prod.s3.us-west-2.amazonaws.com/homepage_images/newsletter-bg.png";
const mobileBackground =
  "https://sap-app-prod.s3.us-west-2.amazonaws.com/homepage_images/newsletter-bg-mobile.png";
const furfeather =
  "https://sap-app-prod.s3.us-west-2.amazonaws.com/homepage_images/furfeather.png";
const logo4dogs =
  "https://sap-app-prod.s3.us-west-2.amazonaws.com/homepage_images/4dogslogo.png";
const quoteIcon =
  "https://sap-app-prod.s3.us-west-2.amazonaws.com/homepage_images/Frame+1000006781.png";

const bannerMobile =
  "https://sap-app-prod.s3.us-west-2.amazonaws.com/homepage_images/dogCatMobile.png";
const bannerCatDog =
  "https://sap-app-prod.s3.us-west-2.amazonaws.com/homepage_images/catDog.png";
const NPofTheMonth =
  "https://sap-app-prod.s3.us-west-2.amazonaws.com/homepage_images/headingDesktop.png";
const NPofTheMonthMobile =
  "https://sap-app-prod.s3.us-west-2.amazonaws.com/homepage_images/np_of_the_month_mobile.png";

// Creating logo list
function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex > 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}
const logos = require.context("../../assets/nonprofitLogos", true);
const logoList = shuffle(logos.keys().map((logo) => logos(logo)));

const testimonials = [
  {
    content:
      "A refreshing plaform that is based on integrity, putting nonprofits first.",
    img: furfeather,
    name: "Nancy Sayle",
    nonprofit: "Fur & Feather",
  },
  {
    content: "They truly are the Happy Platform. People seek them out.",
    img: logo4dogs,
    name: "Dennise Totes",
    nonprofit: "4 Dogs Farm Rescue",
  },
];

function HomePage() {
  const [announcements, setAnnouncements] = useState([]);
  const [campaignsSubset, setCampaignsSubset] = useState([]);
  const [email, setEmail] = useState("");
  const { width } = useWindowDimensions();
  const sliderRef = useRef(null);
  const [hover, setHover] = useState(-1);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [animalSearch, setAnimalSearch] = useState({
    search: "",
    species: "",
    age: -1,
    state: "",
    nonprofitId: "",
  });

  // Getting api responses
  useEffect(() => {
    const fetchAllData = () => {
      setIsLoading(true);

      getAnnouncements()
        .then((response) => {
          setAnnouncements(response.slice(0, 3));
        })
        .catch((error) => {
          console.error("Could not retrieve announcements", error);
        });

      getAllPaginatedCampaigns(1)
        .then((response) => {
          setCampaignsSubset(response.results.slice(0, 4));
        })
        .catch((error) => {
          console.error("Could not retrieve campaigns", error);
        })
        .finally(() => {
          setTimeout(() => {
            setIsLoading(false);
          }, 300);
        });
    };

    fetchAllData();
  }, []);
  // end getting api responses

  function handleGetStarted(data) {
    if (data) {
      subscribeMarketingNewsletter({ email: data, active: false })
        .then(() => {
          setEmail("");
          alert("Thank you for subscribing to our newsletter!");
        })
        .catch((error) => {
          console.error(error);
          const err = errorHandler(error);
          alert("Error while subscribing to our newsletter: " + err);
        });
    } else {
      alert("Please enter an email address.");
    }
  }

  let slideshowSettings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 10000, // 10 secs
    pauseOnHover: false,
  };

  let logoCarouselSettings = {
    dots: false,
    infinite: true,
    slidesToShow: 8,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 0,
    pauseOnHover: false,
    cssEase: "linear",
  };

  let logoCarouselSettingsMedium = {
    ...logoCarouselSettings,
    slidesToShow: 4,
  };
  let logoCarouselSettingsSmall = {
    ...logoCarouselSettings,
    slidesToShow: 3,
    speed: 2000,
  };

  const handleMouseEnter = (id) => {
    if (sliderRef.current) {
      sliderRef.current.slickPause();
    }
    setHover(id);
  };

  const handleMouseLeave = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPlay();
    }
    setHover(-1);
  };

  const handleClick = (campaign) => {
    const campaignPath =
      campaign.campaign_type === "animal"
        ? `/campaigns/animal/${urlifyName(
            campaign.nonprofit_name
          )}/${urlifyName(campaign.animal_name)}/${campaign.id}`
        : `/campaigns/${campaign.campaign_type}/${urlifyName(
            campaign.nonprofit_name
          )}/${urlifyName(campaign.campaign_title)}/${campaign.id}`;

    navigate(campaignPath);
  };

  const matches560px = useMediaQuery("(max-width: 540px)");

  if (isLoading) {
    return (
      <div className="flex flex-row ml-auto mt-[35vh] w-full h-full justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="bg-white w-full flex flex-col">
      {/* Announcements */}
      <div className={classes.slideshow_container}>
        <Slider ref={sliderRef} {...slideshowSettings}>
          <div className="bg-blue !w-full !flex justify-between md:gap-12 h-[80vh] !mt-20 relative xl:static">
            <div className="w-full xl:w-1/2 flex flex-col lg:justify-between xl:justify-start gap-2 md:gap-16 lg:gap-40 xl:gap-16 mt-12 lg:mt-36 px-4 lg:px-8 xl:pl-12 absolute xl:static z-10">
              <h1 className="text-white text-2xl/[45px] md:!text-5xl/[75px] !font-normal mb-4 md:mb-0 w-full text-left">
                Be a star and donate to an animal in need
              </h1>
              <div className="flex flex-col gap-4 w-full xl:w-3/5">
                <div className="flex justify-start gap-8">
                  <a
                    href="/campaigns/animal"
                    className="cursor-pointer bg-orange hover:bg-orange-dark1 flex justify-center items-center text-sm sm:text-base !font-semibold w-64 h-9 text-white rounded-xl shadow-button"
                  >
                    Find Animals to Help
                  </a>
                  <a
                    href="/petstar"
                    className="cursor-pointer bg-orange hover:bg-orange-dark1 flex justify-center items-center text-sm sm:text-base !font-semibold w-64 h-9 text-white rounded-xl shadow-button"
                  >
                    Join PetStar
                  </a>
                </div>
                <Link
                  to="/signup"
                  className="bg-white hover:bg-blue-dark1 flex justify-center items-center text-sm sm:text-base !font-semibold w-full h-9 text-blue hover:text-white rounded-xl shadow-button"
                >
                  Are You a Shelter/Non Profit? Join Us Here!
                  <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                </Link>
              </div>

              <div className="flex flex-col mt-12 w-full xl:w-3/5 mt-36 md:mt-0">
                <p className="font-semibold text-lg text-white text-left mb-0 md:mb-4">
                  Stay updated on the latest animal news!
                </p>
                <div className="flex justify-start items-start gap-4">
                  <input
                    type="email"
                    value={email}
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                    required
                    className="h-9 w-full"
                  />
                  <button
                    className="bg-orange hover:bg-orange-dark1 flex justify-center items-center text-sm sm:text-base !font-semibold w-64 h-9 text-white rounded-xl shadow-button"
                    onClick={() => handleGetStarted(email)}
                  >
                    Get Started
                  </button>
                </div>
              </div>
            </div>

            <div className="w-full h-auto xl:w-2/5 block xl:flex md:justify-end gap-0 md:mt-4 z-0">
              {width > 1280 ? (
                <>
                  <img src={bannerCatDog} className="w-3/5 xl:w-full" />
                </>
              ) : (
                <img
                  src={bannerMobile}
                  className="object-cover w-full h-[80vh] xl:h-auto"
                  alt="cat with dog"
                />
              )}
            </div>
          </div>

          <div className="bg-white !w-full !flex justify-center items-center md:gap-12 h-[80vh] !mt-20 relative xl:static">
            {width > 1280 ? (
              <img src={NPofTheMonth} className="" />
            ) : (
              <img src={NPofTheMonthMobile} className="w-full" />
            )}
          </div>

          {announcements.map((announcement) => (
            <div className={classes.announcement_slide} key={announcement.id}>
              <img
                className={classes.announcement_slide_content}
                src={announcement.image}
                alt=""
              />
              <div className={classes.announcement_slide_content}>
                <h1>{announcement.title}</h1>
                <h3>{announcement.sub_title}</h3>
                {announcement.button_primary_url &&
                  announcement.button_primary_text && (
                    <a
                      className={`${classes.homepage_button} border hover:bg-orange-dark1 rounded-3xl ${classes.announcement_button}`}
                      href={announcement.button_primary_url}
                    >
                      {announcement.button_primary_text}
                    </a>
                  )}
                {announcement.button_secondary_url &&
                  announcement.button_secondary_text && (
                    <a
                      className={`${classes.homepage_button} ${classes.announcement_button} border hover:bg-blue-dark1 rounded-3xl ${classes.announcement_button_alt}`}
                      href={announcement.button_secondary_url}
                    >
                      {announcement.button_secondary_text}
                    </a>
                  )}
              </div>
            </div>
          ))}
        </Slider>
      </div>

      {/* Who we are banner */}
      <div className="flex !flex-col !justify-center items-center !pb-12 !px-8 bg-white w-full h-full mt-14 mb-14">
        <h1 className="!text-2xl !font-normal py-8 shadow-cardShadow h-32 sm:h-9 w-3/5 bg-orange text-white mb-16 flex justify-center items-center">
          WHO WE ARE
        </h1>
        <div className="flex !flex-col lg:!flex-row justify-center items-center gap-14 px-8 max-w-7xl w-full">
          <div className="flex flex-col justify-center items-center">
            <p className="!text-black mb-16 ld:text-left text-center">
              Sponsor a Pet is a fundraising platform, dedicated to providing
              Animal Non-Profits a platform to share their stories and help
              raise necessary resources to save the lives of animals. We do this
              by working together with passionate local organizations to amplify
              our impact in communities as we come together as one.
            </p>
            <Link
              className="block bg-blue hover:bg-blue-dark1 hover:shadow-buttonHover active:shadow-buttonClick justify-center items-center text-base !font-semibold px-4 py-2 text-white rounded-3xl shadow-button"
              to={`/about-us`}
            >
              LEARN MORE
            </Link>
          </div>
          <div>
            <iframe
              width={matches560px ? "350" : "540"}
              height={matches560px ? "auto" : "360"}
              src="https://sap-app-staging.s3.us-west-2.amazonaws.com/internal/who_we_are_new.mp4"
              title="Who We Are"
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>

      {/* We help NP fundraise banner */}
      {width >= 768 ? (
        <section className="bg-white flex justify-center flex-col items-center mb-8">
          <h1 className="!text-2xl !font-normal py-8 shadow-cardShadow h-9 w-3/5 bg-orange text-white mb-16 flex justify-center items-center">
            We help non-profits fundraise money
          </h1>
          {/* <div className={classes.announcements_cover}>
            <CircleText
              handleMouseEnter={handleMouseEnter}
              handleMouseLeave={handleMouseLeave}
              hover={hover}
            />
            <Arrows />
            <img
              style={{ gridArea: "3 / 3 / 6 / 6" }}
              className={classes.announcements_cover_image}
              src={announcementCoverMobile}
              alt="Non-Profits Turn to Us for Critical Support and Action. We organize a campaign to collect money. We advocate the pet's rights. We find corporate partners. We send money to the non-profit. Transforming Lives: Ensuring Every Pet Receives the Care They Deserve."
            />
          </div> */}
          <Diagram />
        </section>
      ) : (
        <section className="bg-white flex justify-center flex-col items-center mb-8">
          <h1 className="!text-2xl !font-normal py-8 shadow-cardShadow !h-32 w-3/5 bg-orange text-white mb-16 flex justify-center items-center">
            We help non-profits fundraise money
          </h1>
          {/* <div className={classes.announcements_cover}>
            <CircleText
              handleMouseEnter={handleMouseEnter}
              handleMouseLeave={handleMouseLeave}
              hover={hover}
            />
            <Arrows />
            <img
              style={{ gridArea: "3 / 3 / 6 / 6" }}
              className={classes.announcements_cover_mobile_image}
              src={announcementCoverMobile}
              alt="Non-Profits Turn to Us for Critical Support and Action. We organize a campaign to collect money. We advocate the pet's rights. We find corporate partners. We send money to the non-profit. Transforming Lives: Ensuring Every Pet Receives the Care They Deserve."
            />
          </div> */}
          <Diagram />
        </section>
      )}

      {/* Campaigns */}
      {campaignsSubset.length > 0 ? (
        <div className="flex flex-col justify-center items-center py-12 px-0 !m-0 !bg-blue w-full">
          <h1 className="text-center text-white mb-12 !text-3xl">
            Champion a Cause: Featured Campaigns
          </h1>
          <div
            className={`${classes.homepage_campaigns_container} ${classes.homepage_campaigns_container_mobile} w-full justify-center items-center`}
          >
            {campaignsSubset.map((campaign) => {
              return (
                <CampaignCard
                  key={campaign.campaign_title}
                  campaign={campaign}
                  handleClick={handleClick}
                  type={campaign.type}
                />
              );
            })}
          </div>
          <Link
            to="/campaigns/animal"
            className="mt-8 block !bg-orange hover:!bg-orange-dark1 hover:shadow-buttonHover active:shadow-buttonClick justify-center items-center text-base !font-semibold px-4 py-2 text-white rounded-3xl shadow-button"
          >
            View More
          </Link>
        </div>
      ) : (
        ""
      )}

      {/* Reasons to give banner */}
      <div className="flex !flex-col !justify-center items-center !pb-12 !px-8 zinc-50 w-full h-full bg-white !mt-16">
        <h1 className="!text-2xl !font-normal py-8 shadow-cardShadow h-32 sm:h-9 w-3/5 bg-orange text-white mb-16 flex justify-center items-center">
          Reasons to give to Sponsor a Pet
        </h1>
        <div className="!text-black flex  flex-col md:flex-row justify-between items-start gap-16">
          <div
            className={`${classes.homepage_reasons_card} justify-center items-center`}
          >
            <FontAwesomeIcon
              icon={faPiggyBank}
              className="!text-stone-900 h-16"
            />
            <div className="text-left max-w-72 mt-4">
              <h3 className="text-black font-bold">Tax Deductible Donations</h3>
              <p className="!text-black">
                All Monetary Donations to our non-profit partners are tax
                deductible.
              </p>
            </div>
          </div>
          <div
            className={`${classes.homepage_reasons_card} justify-center items-center`}
          >
            <VerifiedIcon className="!text-stone-900 scale-150 h-16 sm:mt-3.5" />
            <div className="text-left max-w-72 mt-4">
              <h3 className="text-black font-bold">
                Verified 501(c)(3) Organizations
              </h3>
              <p className="!text-black">
                Our verified non-profits partners pay nothing for our services.
              </p>
            </div>
          </div>
          <div
            className={`${classes.homepage_reasons_card} justify-center items-center`}
          >
            <FontAwesomeIcon icon={faUsers} className="!text-stone-900 h-16" />
            <div className="text-left max-w-72 mt-4">
              <h3 className="text-black font-bold">Join Our Community</h3>
              <p className="!text-black">
                Working together amplifies our ability to accomplish our goals
                of providing every animal love and a home.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Testimonials banner */}
      <div className="bg-blue w-full h-fit !py-8 !mt-0 px-32 lg:px-4 text-left">
        <h1 className="flex justify-center text-white mb-8 text-center">
          Our Impact: Testimonials
        </h1>
        <div className="flex flex-col lg:flex-row justify-center items-center gap-8 lg:flex-wrap">
          {testimonials.map((review, index) => {
            return (
              <div
                key={index}
                className="flex flex-col md:flex-row gap-2 max-w-96 w-full min-w-72 h-96 sm:h-72 bg-white rounded-lg p-4"
              >
                <div className="pt-1.5 w-14 h-10">
                  <img className="w-10 h-10" src={quoteIcon} />
                </div>
                <div className="flex flex-col gap-4 pr-4">
                  <p className="text-black">{review.content}</p>
                  <img className="w-24 h-24 object-cover" src={review.img} />
                  <p className="text-blue text-xs">{review.name}</p>
                  <p className="text-grey text-xs">{review.nonprofit}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* Become a petstar banner */}
      <div className="flex flex-col justify-center items-center pb-12 px-8 zinc-50 w-full h-full mt-16">
        <h1 className="!text-2xl !font-normal py-8 shadow-cardShadow h-32 sm:h-9 w-3/5 bg-orange text-white mb-16 flex justify-center items-center">
          Become a PetStar
        </h1>
        <div className="flex md:flex-row flex-col justify-center lg:justify-around gap-4">
          <img
            className="max-w-md w-full h-auto"
            src="https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/guy-holding-dog.png"
          />
          <div className="flex flex-col justify-center md:justify-start md:mt-8 lg:w-1/2">
            <p className="flex justify-center lg:justify-start text-center lg:text-left text-black mb-14">
              PetStar is a way to help Non-profits while earning rewards!.
              Sponsor a Pet is a fundraising platform, dedicated to providing
              Animal Non-Profits a platform to share their stories and help
              raise necessary resources to save the lives of animals. We do this
              by working together with passionate local organizations to amplify
              our impact in communities as we come together as one.
            </p>
            <Link
              to="/petstar"
              className="!w-36 block !bg-orange hover:!bg-orange-dark1 hover:shadow-buttonHover active:shadow-buttonClick justify-center items-center text-base !font-semibold px-4 py-2 text-white rounded-3xl shadow-button"
            >
              LEARN MORE
            </Link>
          </div>
        </div>
      </div>

      {/* Map and partners */}
      <div className="flex !flex-col !justify-center items-center !pb-12 !px-8 bg-white w-full h-full">
        <h1 className="!text-2xl !font-normal py-8 shadow-cardShadow h-32 sm:h-9 w-3/5 bg-orange text-white mb-16 flex justify-center items-center">
          Our Partners
        </h1>
        <UsaMap />
        <div className={`${classes.hompage_section} p-0 !w-full my-8`}>
          {width >= 1200 ? (
            <Slider {...logoCarouselSettings}>
              {
                // Rendering logos
                logoList.map((logoUrl) => (
                  <div className={classes.logo_slide} key={logoUrl}>
                    <img src={logoUrl} alt="" />
                  </div>
                ))
              }
            </Slider>
          ) : width >= 768 ? (
            <Slider {...logoCarouselSettingsMedium}>
              {
                // Rendering logos
                logoList.map((logoUrl) => (
                  <div className={classes.logo_slide} key={logoUrl}>
                    <img src={logoUrl} alt="" />
                  </div>
                ))
              }
            </Slider>
          ) : width >= 0 ? (
            <Slider {...logoCarouselSettingsSmall}>
              {
                // Rendering logos
                logoList.map((logoUrl) => (
                  <div className={classes.logo_slide} key={logoUrl}>
                    <img src={logoUrl} alt="" />
                  </div>
                ))
              }
            </Slider>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default HomePage;
