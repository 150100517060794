import React, { useState } from "react";
import dashboardClasses from "../../Dashboard.module.css";
import classes from "../NonprofitDonationList.module.css";
import { Pagination, PaginationItem } from "@mui/material";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

export default function NonprofitDonationTable({ data, name }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(4);

  // Calculate the current items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  const { width } = useWindowDimensions();
  const [expandRow, setExpandRow] = useState(null);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate page numbers
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(data.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const skipPages = 2;
  const totalPages = Math.ceil(data.length / itemsPerPage);

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  const renderItem = (item) => {
    if (item.type === "previous" || item.type === "next") {
      const isPrevious = item.type === "previous";
      let page = isPrevious
        ? Math.max(1, item.page - skipPages + 1)
        : Math.min(totalPages, item.page + skipPages - 1);
      return (
        <PaginationItem
          {...item}
          page={page}
          onClick={() => handleChange(null, page)}
        />
      );
    }
    return <PaginationItem {...item} />;
  };

  const handleDropdown = (index) => {
    setExpandRow(expandRow === index ? null : index);
  };

  return (
    <div className={`${dashboardClasses.dashboard_block}`}>
      <h2 className="text-2xl text-blue font-bold mt-4 text-left mb-4">
        {name}'s Donations
      </h2>
      <table
        className={`${classes.table_styles_override} !w-full table-fixed !border-separate`}
      >
        {width > 768 ? (
          <>
            <thead>
              <tr>
                <th className="text-sm font-medium text-neutral-500">
                  First Name
                </th>
                <th className="text-sm font-medium text-neutral-500">
                  Last Name
                </th>
                <th className="text-sm font-medium text-neutral-500">Email</th>
                <th className="text-sm font-medium text-neutral-500">Amount</th>
                <th className="text-sm font-medium text-neutral-500">
                  Payment ID
                </th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((donation, index) => (
                <tr key={index} className="bg-sky-50 mb-14 break-words">
                  <td className="rounded-l-xl text-sm text-neutral-600 text-center p-2.5">
                    {donation.donor_first_name}
                  </td>
                  <td className="text-sm text-neutral-600 text-center p-2.5">
                    {donation.donor_last_name}
                  </td>
                  <td className="text-sm text-neutral-600 text-center p-2.5">
                    {donation.donor_email}
                  </td>
                  <td className="text-sm text-neutral-600 text-center p-2.5">
                    ${donation.amount}
                  </td>
                  <td className="rounded-r-xl text-sm text-neutral-600 text-center p-2.5">
                    {donation.payment_intent.id}
                  </td>
                </tr>
              ))}
              {data.length <= 0 ? (
                <tr>
                  <td colspan="5">No donations yet!</td>
                </tr>
              ) : (
                ""
              )}
            </tbody>
          </>
        ) : (
          <>
            <thead>
              <tr>
                <th className="text-sm text-left pl-4 font-medium text-black">
                  Name
                </th>
                <th className="text-sm text-left pl-4 font-medium text-black">
                  Email
                </th>
                <th className="w-[10%]"></th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map((donation, index) => (
                <tr
                  key={index}
                  className="bg-sky-50 mb-14 break-words cursor-pointer"
                  onClick={() => handleDropdown(index)}
                >
                  <td className="rounded-l-xl text-lg text-[#667085] font-normal p-2.5 text-left pl-4">
                    {donation.donor_first_name} {donation.donor_last_name}
                    {expandRow === index && (
                      <div className="flex flex-col my-4">
                        <div className="my-4">
                          <p className="!text-sm text-[#667085]"> Amount</p>
                          <p> $ {donation.amount}</p>
                        </div>
                        <div className="my-4">
                          <p className="!text-sm text-[#667085]">Payment ID</p>
                          <p>{donation.payment_intent.id}</p>
                        </div>
                      </div>
                    )}
                  </td>
                  <td className="text-lg text-[#667085] font-normal  pl-4 p-2.5 text-left align-top">
                    {donation.donor_email}
                  </td>
                  {expandRow === index ? (
                    <td className="bg-sky-50 mb-14 rounded-r-xl" align="center">
                      <FontAwesomeIcon
                        icon={faChevronUp}
                        size="lg"
                        color="#666666"
                      />
                    </td>
                  ) : (
                    <td className="bg-sky-50 mb-14 rounded-r-xl" align="center">
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        size="lg"
                        color="#666666"
                      />
                    </td>
                  )}
                </tr>
              ))}
              {data.length <= 0 ? (
                <tr>
                  <td colspan="5">No donations yet!</td>
                </tr>
              ) : (
                ""
              )}
            </tbody>
          </>
        )}
      </table>
      <nav className="overflow-x-auto mt-4 flex justify-end max-[768px]:justify-center">
        <Pagination
          sx={{
            ".MuiPaginationItem-root": {
              backgroundColor: "var(--light-grey)",
              borderRadius: "0",
            },
            ".Mui-selected": {
              backgroundColor: "var(--default-orange) !important",
              borderRadius: "0",
            },
          }}
          count={totalPages}
          page={currentPage}
          onChange={handleChange}
          className="flex list-none justify-center"
          color="primary"
          renderItem={renderItem}
        />
      </nav>
    </div>
  );
}
