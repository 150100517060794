import React, { useEffect, useState, useRef } from "react";
import { updateCampaign } from "../../../api/UpdateCampaign";
import GeneralButton from "../../shared/Buttons/GeneralButton";
import SharedTooltip from "../../shared/Tooltip/SharedTooltip";
import editClasses from "./EditCampaign.module.css";
import { errorHandler } from "../../shared/Utils/ErrorHandler";
import Editor from "../CampaignModal/Editor/Editor";
import { Grid, Card, CardMedia } from "@mui/material";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import Compressor from "compressorjs";
import { addUpdate } from "../../../api/AddUpdate";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import SecondaryButton from "../../shared/Buttons/SecondaryButton";
import CampaignShareBox from "./CampaignShareBox";
import CampaignUpdateForm from "./CampaignUpdateForm";

const ageOptions = {
  Newborn: 1,
  Young: 2,
  Adult: 3,
  Senior: 4,
  Unknown: 5,
};

const maxSize = 3 * 1024 * 1024;

const SortableItem = SortableElement(({ value, width }) => {
  return (
    <Grid item xs={width > 900 ? 4 : width > 530 ? 5 : 8} className="relative">
      <Card className="relative">
        <CardMedia
          component="img"
          height="140"
          src={value}
          alt="campaign image"
          style={{ cursor: "pointer" }}
        />
      </Card>
    </Grid>
  );
});

const SortableList = SortableContainer(({ items, width }) => {
  // items prop is campaignData.image_urls which only contains the URLs of pics
  // everytime an image is sorted or uploaded, it sorts campaignData.image_urls
  return (
    <div className="max-[900px]:ml-20">
      <Grid container spacing={2} className="overflow-auto">
        {items.map((value, index) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            value={value}
            width={width}
          />
        ))}
      </Grid>
    </div>
  );
});

export default function EditAnimalCampaignForm({
  campaignId,
  campaignInfo,
  onClose,
}) {
  const [campaignData, setCampaignData] = useState(() => {
    const imageUrls =
      campaignInfo?.campaign_images?.map((image) => image.image_url) || [];
    return {
      ...campaignInfo,
      image_urls: imageUrls,
    };
  });
  const [formEnabled, setFormEnabled] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [updateCampaignSuccess, setCampaignSuccess] = useState(false);
  const [totalImages, setTotalImages] = useState({
    images: 0,
    graphic: 0,
  });
  let imageCount = 0;
  let graphicCount = 0;
  const imageRef = useRef();
  const graphicImageRef = useRef();

  const [updateData, setUpdateData] = useState({
    update_content: "",
    campaign: campaignId,
  });
  const [image, setImage] = useState(null);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const { width } = useWindowDimensions();

  /* Handles updates */
  const handleUpdateChange = (e) => {
    const { name, value } = e.target;
    setUpdateData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  //   [
  //     0.25,
  //     0.5,
  //     0.75,
  //     1
  // ]

  const handleUpdateImageChange = (e) => {
    const file = e.target.files[0];
    if (file.size > 3 * 1024 * 1024) {
      setError("Image size should not exceed 3 MB.");
      setImage(null);
    } else {
      setImage(file);
      setError("");
    }
  };

  // Make form and API call
  const submitUpdate = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;

    setIsSubmitting(true);
    setError(""); // Clear previous errors

    try {
      let formData = new FormData();
      Object.keys(updateData).forEach((key) => {
        if (updateData[key] !== "") {
          formData.append(key, updateData[key]);
        }
      });
      if (image) {
        formData.append("image", image);
      }

      const response = await addUpdate(formData);
      setUpdateSuccess(true);
    } catch (error) {
      const errorMsg = errorHandler(error);
      console.error("Error submitting the update:", error);
      setError(errorMsg);
    } finally {
      setIsSubmitting(false);
    }
  };

  /* Handles Campaign Edit Update */
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Prevent negative donation goals
    if (name === "donation_goal" && parseFloat(value) < 0) {
      return;
    }

    setCampaignData((prevState) => ({
      ...prevState,
      [name]: name === "age" ? ageOptions[value] : value, // Convert age to integer
    }));
  };

  const enableEdits = () => {
    setFormEnabled((prev) => !prev);
  };

  const submitCampaignUpdate = async (e) => {
    e.preventDefault();
    setCampaignSuccess(false);
    setErrorMsg("");

    // Enforces at least 4 images
    if (totalImages.images < 4) {
      if (totalImages.graphic === 0) {
        setErrorMsg(
          "Please upload at least 4 normal images or 3 normal with 1 graphic image."
        );
        setCampaignData((prevState) => ({
          ...prevState,
          error:
            "Please upload at least 4 normal images or 3 normal with 1 graphic image.",
        }));
        return;
      } else if (totalImages.graphic > 0 && totalImages.images < 3) {
        setErrorMsg(
          "Please upload at least 4 normal images or 3 normal with 1 graphic image."
        );
        setCampaignData((prevState) => ({
          ...prevState,
          error:
            "Please upload at least 4 normal images or 3 normal with 1 graphic image.",
        }));
        return;
      }
    }

    try {
      let formData = new FormData();

      Object.keys(campaignData).forEach((key) => {
        if (
          campaignData[key] !== "" &&
          key !== "updates" &&
          key !== "description" &&
          key !== "campaign_images"
        ) {
          formData.append(key, campaignData[key]);
        }
        if (key === "description") {
          formData.append(key, JSON.stringify(campaignData[key])); // HTML as string to API
        }

        // Add milestones_reached to FormData as a JSON string
        if (campaignData.milestones_reached) {
          formData.append(
            "milestones_reached",
            JSON.stringify(campaignData.milestones_reached)
          );
        }
      });

      // sorts campaign_images based on image_urls since image_urls changes order when moved
      const campaignImages = [...campaignData.campaign_images];
      const imageUrls = [...campaignData.image_urls];

      // campaignImages gets sorted based on image_urls
      // this gets sent to the backend as "images"
      campaignImages.sort(function (a, b) {
        return imageUrls.indexOf(a.image_url) - imageUrls.indexOf(b.image_url);
      });

      campaignImages.forEach((image, index) => {
        if (image.file) {
          // if image hasn't been uploaded yet, it should contain a File
          if (image.graphic) {
            formData.append("graphic_images", image.file);
          } else {
            formData.append("new_images", image.file);
            formData.append("new_image_orders", index);
          }
        } else {
          // else, sending image ID and order to the backend to change it
          formData.append("existing_image_ids", image.id);
          formData.append("existing_image_orders", index);
        }
      });

      const response = await updateCampaign(campaignId, formData);
      if (response.success && response.data.animal_name) {
        setCampaignSuccess(true);
        setErrorMsg("");
        setFormEnabled(false); // Disable form fields again
      }
    } catch (error) {
      const errorMessage = errorHandler(error);
      const formattedError = errorMessage;
      setCampaignSuccess(false);
      setErrorMsg(formattedError);
    }
  };

  const handleEditorChange = (content, delta, source, editor) => {
    setCampaignData((prevState) => ({
      ...prevState,
      description: content, // Set description as HTML content
    }));
  };

  const handleImageChange = (event) => {
    let files = Array.from(event.target.files);
    const compressFiles = async (files) => {
      const compressedFiles = await Promise.all(
        files.map(async (file) => {
          return new Promise((resolve) => {
            //Compressor is async for success
            new Compressor(file, {
              quality: 0.8,
              success: (compressedResult) => {
                resolve(compressedResult);
              },
            });
          });
        })
      );

      return compressedFiles;
    };

    const handleFileCompression = async (files) => {
      try {
        const compressedFiles = await compressFiles(files);
      } catch (error) {
        console.error("Error during file compression:", error);
      }
    };
    handleFileCompression(files);
    let error = "";
    let isTooLarge = false;

    const validFiles = files.filter((file) => {
      if (file.size > maxSize) {
        error = "File size is too large (Must be under 3 MB).";
        isTooLarge = true;
        return false;
      }
      return true;
    });

    if (isTooLarge) {
      setCampaignData((prevState) => ({ ...prevState, error: error }));
    } else {
      setErrorMsg("");

      const newImages = validFiles.map((file) => ({
        file,
        url: URL.createObjectURL(file),
      }));

      const count = totalImages["images"] + imageCount;
      setTotalImages((prevState) => ({
        ...prevState,
        images: count + files.length,
      }));

      // Saves campaign_images with order field
      setCampaignData((prevState) => {
        const startingIndex = prevState.campaign_images.length; // gets total image length
        const newCampaignImages = newImages.map((img, index) => ({
          file: img.file,
          order: startingIndex + index,
        }));
        return {
          ...prevState,
          campaign_images: [...prevState.campaign_images, ...newCampaignImages],
          image_urls: [
            ...prevState.image_urls,
            ...newImages.map((img) => img.url),
          ],
          error: "",
        };
      });
    }
    event.target.value = "";
  };

  const handleGraphicImageChange = (event) => {
    let files = Array.from(event.target.files);
    const compressFiles = async (files) => {
      const compressedFiles = await Promise.all(
        files.map(async (file) => {
          return new Promise((resolve) => {
            //Compressor is async for success
            new Compressor(file, {
              quality: 0.8,
              success: (compressedResult) => {
                resolve(compressedResult);
              },
            });
          });
        })
      );

      return compressedFiles;
    };

    const handleFileCompression = async (files) => {
      try {
        const compressedFiles = await compressFiles(files);
      } catch (error) {
        console.error("Error during file compression:", error);
      }
    };
    handleFileCompression(files);
    let error = "";
    let isTooLarge = false;

    const count = totalImages["graphic"] + graphicCount;
    setTotalImages((prevState) => ({
      ...prevState,
      graphic: count + files.length,
    }));

    const validFiles = files.filter((file) => {
      if (file.size > maxSize) {
        error = "File size is too large (Must be under 3 MB).";
        isTooLarge = true;
        return false;
      }
      return true;
    });

    if (isTooLarge) {
      setCampaignData((prevState) => ({ ...prevState, error: error }));
    } else {
      const newGraphicImages = validFiles.map((file) => ({
        file,
        graphic: true,
        url: URL.createObjectURL(file),
      }));

      // Saves campaign_images with order field
      setCampaignData((prevState) => {
        const startingIndex = prevState.campaign_images.length; // gets total image length
        const newCampaignGraphicImages = newGraphicImages.map((img, index) => ({
          file: img.file,
          graphic: true,
          order: startingIndex + index,
        }));

        return {
          ...prevState,
          campaign_images: [
            ...prevState.campaign_images,
            ...newCampaignGraphicImages,
          ],
          image_urls: [
            ...prevState.image_urls,
            ...newGraphicImages.map((img) => img.url),
          ],
          error: "",
        };
      });
    }
    event.target.value = "";
  };

  useEffect(() => {
    let imageCount = 0;
    let graphicCount = 0;

    for (const img in campaignData.campaign_images) {
      if (campaignData.campaign_images[img]["graphic"] === false) {
        imageCount += 1;
      } else if (campaignData.campaign_images[img]["graphic"] === true) {
        graphicCount += 1;
      }
    }

    setTotalImages((prevState) => ({
      ...prevState,
      images: imageCount,
    }));
    setTotalImages((prevState) => ({
      ...prevState,
      graphic: graphicCount,
    }));
  }, []);

  useEffect(() => {
    // sort image_urls based on campaign_images on first render
    // changes everytime campaign_images change
    const campaignImages = [...campaignData.campaign_images];
    const imageUrls = [...campaignData.image_urls];

    // imageUrls gets sorted by campaignImages "order" key
    const mappingCampaignImages = {};
    campaignImages.forEach((img) => {
      mappingCampaignImages[img["image_url"]] = img["order"];
    });

    imageUrls.sort((a, b) => {
      return mappingCampaignImages[a] - mappingCampaignImages[b];
    });

    // this changes the order of campaign_images when rendering in the sortable list
    setCampaignData((prevState) => ({
      ...prevState,
      image_urls: imageUrls,
    }));
  }, [campaignData.campaign_images]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setCampaignData((prevState) => ({
      ...prevState,
      image_urls: arrayMove(prevState.image_urls, oldIndex, newIndex),
    }));
  };

  return (
    <>
      {/* update form  */}
      <CampaignUpdateForm campaignId={campaignId} campaignData={campaignData} />

      {/* Edit Campaign here */}
      <div className={`flex flex-row justify-between ${editClasses.container}`}>
        <form
          onSubmit={submitCampaignUpdate}
          className={`${editClasses.form_column} flex flex-col gap-6 max-[900px]:w-full`}
        >
          {/* Image Upload/Rearrange */}
          <div className="flex flex-col justify-center items-center gap-5 mb-4">
            <p className="font-normal text-lg italic">
              Click and drag to reorder
            </p>
            <div className="flex flex-row justify-between w-full max-[900px]:flex-col max-[900px]:justify-center max-[900px]:items-center">
              <div className="flex flex-col justify-center items-center w-64 ">
                <p className="text-xl text-left font-normal">
                  Upload New Image
                </p>
                <input
                  type="file"
                  name="images"
                  multiple
                  onChange={handleImageChange}
                  disabled={!formEnabled}
                  className="!p-2.5 text-black"
                />
              </div>
              <div className="flex flex-col justify-center items-center w-64 ">
                <p className="text-xl text-left font-normal">
                  Upload Graphic Images
                </p>
                <input
                  type="file"
                  name="graphic_images"
                  multiple
                  onChange={handleGraphicImageChange}
                  disabled={!formEnabled}
                  className="!p-2.5 text-black"
                />
              </div>
            </div>
            <SortableList
              width={width}
              items={campaignData.image_urls}
              onSortEnd={onSortEnd}
              axis="xy"
              helperClass={editClasses.sortable_helper}
            />
          </div>

          {/* Campaign Details */}
          <div className="flex flex-col">
            <div className="flex flex-col gap-4 py-4">
              <h2 className="text-2xl text-left max-[530px]:text-center">
                Campaign Name
              </h2>
              <input
                name="campaign_title"
                value={campaignData.campaign_title}
                onChange={handleChange}
                placeholder="Campaign Title"
                disabled={!formEnabled}
                className="disabled:bg-neutral-50 rounded-lg p-2 border border-neutral-400 mb-2"
              />
            </div>

            <div className="flex flex-col gap-4 py-4">
              <h2 className="text-2xl text-left max-[530px]:text-center">
                Description
              </h2>
              <Editor
                defaultValue={campaignData.description}
                onTextChange={handleEditorChange}
                readOnly={!formEnabled}
              />
            </div>

            <div className="flex flex-row gap-6 py-4 max-[530px]:flex-col">
              <div className="flex flex-col items-start gap-4 max-[530px]:items-center ">
                <h2 className="text-2xl text-left max-[530px]:text-center">
                  Donation Goal
                </h2>
                <input
                  type="number"
                  name="donation_goal"
                  value={campaignData.donation_goal}
                  onChange={handleChange}
                  placeholder="Donation Goal Amount (Number)"
                  disabled={!formEnabled}
                  className="w-52 !h-14 disabled:bg-neutral-50"
                />
              </div>

              <div className="flex flex-col items-start gap-4 bg-green max-[530px]:items-center">
                <div className="flex flex-row gap-2">
                  <h2 className="text-2xl text-left max-[530px]:text-center">
                    Donation Box Title
                  </h2>
                  <SharedTooltip
                    id="my-tooltip"
                    content="The heading right above the donation box"
                  />
                </div>
                <div
                  className={`${editClasses.form_column} max-[530px]:w-full`}
                >
                  <div
                    className={`flex items-center justify-between gap-4 mb-4`}
                  >
                    <input
                      name="donation_box_title"
                      value={campaignData.donation_box_title}
                      onChange={handleChange}
                      placeholder="Donation Box Title (Max. 30 Chars)"
                      maxLength="30"
                      className="w-96 h-14 disabled:bg-neutral-50"
                      disabled={!formEnabled}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-4 py-4">
              <h2 className="text-2xl text-left max-[530px]:text-center">
                Video URL
              </h2>
              <input
                name="video_urls"
                value={campaignData.video_urls}
                onChange={handleChange}
                placeholder="Video URLs (Separate with comma)"
                disabled={!formEnabled}
                className="disabled:bg-neutral-50 rounded-lg p-2 border border-neutral-400 mb-2"
              />
            </div>
          </div>

          {/* Animal Details */}
          <div className="flex flex-col gap-y-4">
            <h2 className="text-2xl text-left max-[530px]:text-center">
              Animal details
            </h2>

            <div className="flex flex-row justify-start items-start gap-5 max-[530px]:flex-col">
              <div className="flex flex-col max-[530px]:w-full">
                <label className="text-xl text-left font-normal max-[530px]:text-center">
                  Name
                </label>
                <input
                  name="animal_name"
                  value={campaignData.animal_name}
                  onChange={handleChange}
                  placeholder="Animal Name"
                  disabled={!formEnabled}
                  className="disabled:bg-neutral-50 rounded-lg p-2 border border-neutral-400 mb-2"
                />
              </div>

              <div className="flex flex-col max-[530px]:w-full">
                <label className="text-xl text-left font-normal max-[530px]:text-center">
                  Age
                </label>
                <select
                  name="age"
                  value={Object.keys(ageOptions).find(
                    (key) => ageOptions[key] === campaignData.age
                  )}
                  onChange={handleChange}
                  disabled={!formEnabled}
                  className="disabled:bg-neutral-50 rounded-lg p-2 border border-neutral-400 mb-2"
                >
                  <option value="" disabled>
                    Select Age
                  </option>
                  {Object.keys(ageOptions).map((age) => (
                    <option key={age} value={age}>
                      {age}
                    </option>
                  ))}
                </select>
              </div>

              <div className="flex flex-col w-full">
                <label className="text-xl text-left font-normal max-[530px]:text-center">
                  Color
                </label>
                <input
                  name="color"
                  value={campaignData.color}
                  onChange={handleChange}
                  placeholder="Color"
                  disabled={!formEnabled}
                  className="disabled:bg-neutral-50 rounded-lg p-2 border border-neutral-400 mb-2"
                />
              </div>
            </div>

            <div className="flex flex-row justify-start items-start gap-5 max-[530px]:flex-col">
              <div className="flex flex-col w-full">
                <label className="text-xl text-left font-normal max-[530px]:text-center">
                  Breed
                </label>
                <input
                  name="breed"
                  value={campaignData.breed}
                  onChange={handleChange}
                  placeholder="Breed"
                  disabled={!formEnabled}
                  className="disabled:bg-neutral-50 rounded-lg p-2 border border-neutral-400 mb-2"
                />
              </div>

              <div className="flex flex-col max-[530px]:w-full">
                <label className="text-xl text-left font-normal max-[530px]:text-center">
                  Gender
                </label>
                <select
                  name="gender"
                  value={campaignData.gender}
                  onChange={handleChange}
                  disabled={!formEnabled}
                  className="disabled:bg-neutral-50 rounded-lg p-2 border border-neutral-400 mb-2"
                >
                  <option value="" selected disabled={true}>
                    Gender
                  </option>
                  <option value="female">Female</option>
                  <option value="male">Male</option>
                </select>
              </div>

              <div className="flex flex-col max-[530px]:w-full">
                <label className="text-xl text-left font-normal max-[530px]:text-center">
                  Species
                </label>
                <select
                  name="species"
                  value={campaignData.species}
                  onChange={handleChange}
                  disabled={!formEnabled}
                  className="disabled:bg-neutral-50 rounded-lg p-2 border border-neutral-400 mb-2"
                >
                  <option value="" selected disabled={true}>
                    Species
                  </option>
                  <option value="dog">Dog</option>
                  <option value="cat">Cat</option>
                  <option value="farm">Farm</option>
                  <option value="exotic">Exotic</option>
                  <option value="other">Other</option>
                </select>
              </div>
            </div>
          </div>

          {/* Success/Error messages and buttons */}
          {updateCampaignSuccess && (
            <p className="text-green-500">
              Campaign has been edited successfully!
            </p>
          )}
          {!updateCampaignSuccess && errorMsg.length > 0 && (
            <p className="text-[var(--default-red)]">{errorMsg}</p>
          )}
          <div
            className={`flex flex-row justify-end items-center gap-6 max-[530px]:flex-col`}
          >
            <SecondaryButton
              onClick={enableEdits}
              type="button"
              className="!w-48 !h-9"
            >
              Edit
            </SecondaryButton>
            <GeneralButton
              colour="orange"
              type="submit"
              className="disabled:bg-[#dddddd] bg-orange hover:bg-orange-dark1 hover:shadow-buttonHover active:shadow-buttonClick w-48 h-9 text-xl !rounded-3xl text-center text-white font-medium !p-0"
              disabled={!formEnabled}
            >
              Save
            </GeneralButton>
          </div>
        </form>

        <div className={`flex flex-col gap-6 ${editClasses.goal_container}`}>
          {/* Goal Details */}
          <div
            className={`${editClasses.goal_box} p-4 flex flex-col gap-4 border border-gray-200 rounded-3xl shadow-sm shadow-gray-200`}
          >
            <h2
              className={`${editClasses.goal_box_title} font-semibold text-4xl text-left`}
            >
              ${campaignInfo.donation_goal} Goal
            </h2>
            <progress
              className={`${editClasses.progress_bar} w-full h-2 my-2`}
              value={campaignInfo.donation_amount}
              max={campaignInfo.donation_goal}
            />
            <div className="flex flex-row items-stretch justify-between">
              <div className="flex flex-col">
                <p className="text-gray-500 text-xs font-medium text-left">
                  Goal
                </p>
                <p className="gray-700 font-semibold text-lg">
                  ${campaignInfo.donation_goal}
                </p>
              </div>
              <div>
                <p className="text-gray-500 text-xs font-medium text-left">
                  Remaining
                </p>
                <p className="gray-700 font-semibold text-lg">
                  $
                  {campaignInfo.donation_goal - campaignInfo.donation_amount < 0
                    ? 0
                    : campaignInfo.donation_goal - campaignInfo.donation_amount}
                </p>
              </div>
            </div>
          </div>

          {/* QR Code */}
          <CampaignShareBox campaignData={campaignInfo} />
        </div>
      </div>
    </>
  );
}
