import { useEffect, useState } from "react";
import {
  faMagnifyingGlass,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import classes from "./DashboardContent.module.css";
import dashboardClasses from "../Dashboard.module.css";
import {
  useMediaQuery,
  Pagination,
  PaginationItem,
  MenuItem,
} from "@mui/material";
import Select from "@mui/material/Select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SecondaryButton from "../../shared/Buttons/SecondaryButton";
import { formatDate } from "../../shared/Utils/DateFilters";
import DropDown from "../../shared/Inputs/Dropdown";

// Arrow for drop down buttons
const CustomIcon = (props) => (
  <FontAwesomeIcon
    icon={faChevronDown}
    {...props}
    style={{ color: "#AAAAAA" }}
  />
);

const itemsPerPage = 4;

const DonationsComponent = ({
  donations,
  setTab,
  weeklyDonations,
  monthlyDonations,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("Filter by");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentDonations, setCurrentDonations] = useState(
    donations.slice(0, itemsPerPage)
  );
  const [expandedDonationIndex, setExpandedDonationIndex] = useState(null);

  // Functions to handle mobile screens
  const matchesMax430px = useMediaQuery("(max-width: 430px)");

  const getFilteredDonations = () => {
    let filtered = donations;
    if (filter === "This Week") filtered = weeklyDonations;
    if (filter === "This Month") filtered = monthlyDonations;

    if (searchTerm) {
      filtered = filtered.filter((donation) =>
        donation.donor_email.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    return filtered;
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
    setCurrentPage(1);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    const filteredDonations = getFilteredDonations();
    setCurrentDonations(filteredDonations.slice(0, itemsPerPage));
  }, [filter, searchTerm, donations]);

  const skipPages = 2;
  const maxVisiblePages = 6;

  // Calculate page numbers and changing pages for donations
  const calculateTotalPages = () => {
    const filteredDonations = getFilteredDonations();
    return Math.ceil(filteredDonations.length / itemsPerPage);
  };

  // Function to render pagination items
  const renderItem = (item) => {
    const totalPages = calculateTotalPages();
    const halfVisible = Math.floor(maxVisiblePages / 2);

    if (item.type === "previous" || item.type === "next") {
      const isPrevious = item.type === "previous";
      const page = isPrevious
        ? Math.max(1, item.page - skipPages + 1)
        : Math.min(totalPages, currentPage + skipPages);
      return (
        <PaginationItem
          {...item}
          page={page}
          onClick={() => handleChangePage(page)}
        />
      );
    }

    const isVisible = matchesMax430px
      ? item.page >= currentPage - halfVisible &&
        item.page <= currentPage + halfVisible
      : true;

    return isVisible ? <PaginationItem {...item} /> : null;
  };

  // Function to handle page change
  const handleChangePage = (value) => {
    setCurrentPage(value);
    const filteredDonations = getFilteredDonations();

    const startIndex = (value - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    setCurrentDonations(filteredDonations.slice(startIndex, endIndex));
  };

  const expandDonationInfo = (index) => {
    setExpandedDonationIndex(expandedDonationIndex === index ? null : index);
  };

  return (
    <div className="sm:mb-2 flex flex-col px-1 py-4 sm:py-1 gap-1">
      {/* Header */}
      <div className="flex flex-row justify-between w-full">
        <h2 className="!text-blue !text-2xl font-bold text-left mb-6">
          Donations
        </h2>

        <Select
          className="!w-32 !h-8 !text-black !font-normal !rounded-lg !text-left"
          value={filter}
          IconComponent={CustomIcon}
          MenuProps={{
            PaperProps: {
              sx: {
                backgroundColor: "#f3f3f3", // Dropdown background grey
              },
            },
          }}
          onChange={handleFilterChange}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#d1d5db", // Default border color
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "black", // Focused border color
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#9ca3af", // Hover border color
            },
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                borderColor: "#d1d5db", // Ensure consistency
              },
            },
          }}
        >
          <MenuItem value="Filter by">Filter by</MenuItem>
          <MenuItem value="This Week">This Week</MenuItem>
          <MenuItem value="This Month">This Month</MenuItem>
        </Select>
      </div>

      <div className="flex">
        <div className="flex-1">
          <div className="flex text-center flex-wrap">
            {/* Donations search bar */}
            <div className={`flex items-center relative w-full`}>
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                size="xs"
                className="absolute left-3"
                color="#666666"
              />
              <input
                type="text"
                placeholder="Search Donation by Donor Email"
                value={searchTerm}
                onChange={handleSearchChange}
                className={`p-2 pl-8 border text-black border-gray-300 rounded-lg w-full h-7 `}
              />
            </div>

            {/* Donations table */}
            <div className={dashboardClasses.dashboard_block}>
              <table
                className={`${classes.table_styles} !w-full table-fixed !border-separate`}
              >
                <thead>
                  <tr>
                    <th className="text-sm font-medium text-neutral-500">
                      Donor Name
                    </th>
                    <th className="text-sm font-medium text-neutral-500">
                      Campaign
                    </th>
                    <th className="text-sm font-medium text-neutral-500">
                      Amount
                    </th>
                    {!matchesMax430px && (
                      <>
                        <th className="text-sm font-medium text-neutral-500">
                          Date
                        </th>
                        <th className="text-sm font-medium text-neutral-500">
                          Email
                        </th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {currentDonations && currentDonations.length !== 0 ? (
                    currentDonations.map((donation, index) => (
                      <tr
                        key={donation.id || `${donation.donor_email}-${index}`}
                        onClick={
                          matchesMax430px
                            ? () => expandDonationInfo(index)
                            : undefined
                        }
                      >
                        <td className="bg-sky-50 mb-14 rounded-l-xl">
                          <input
                            className="text-sm font-normal text-neutral-600 disabled:bg-sky-50 disabled:border-none text-center"
                            type="text"
                            name="first_name"
                            value={donation.donor_first_name}
                            disabled
                            required
                          />

                          {matchesMax430px &&
                            expandedDonationIndex === index && (
                              <div
                                className="flex justify-center"
                                onClick={(e) => e.stopPropagation()}
                              >
                                <p className="text-gray-500 !text-xs font-normal flex flex-col m-2 text-left">
                                  Email:{" "}
                                  <span className="text-neutral-600 !text-xs font-bold break-all">
                                    {donation.donor_email}
                                  </span>
                                </p>
                              </div>
                            )}
                        </td>
                        <td className="bg-sky-50 mb-14">
                          <input
                            className="text-sm font-normal text-neutral-600 disabled:bg-sky-50 disabled:border-none text-center"
                            type="text"
                            name="campaign_name"
                            value={donation.campaign_title}
                            disabled
                            required
                          />

                          {matchesMax430px &&
                            expandedDonationIndex === index && (
                              <div
                                className="flex justify-center"
                                onClick={(e) => e.stopPropagation()}
                              >
                                <p className="text-gray-500 !text-xs font-normal flex flex-col m-2 text-left">
                                  Date:{" "}
                                  <span className="text-neutral-600 !text-xs font-bold">
                                    {formatDate(donation.created_at)}
                                  </span>
                                </p>
                              </div>
                            )}
                        </td>
                        <td className="bg-sky-50 mb-14">
                          <p className="!text-sm text-neutral-600 text-center">
                            ${donation.amount}
                          </p>
                        </td>
                        {!matchesMax430px && (
                          <>
                            <td className="bg-sky-50 mb-14">
                              <input
                                className="text-sm text-neutral-600 disabled:bg-sky-50 disabled:border-none text-center"
                                type="text"
                                name="date"
                                value={formatDate(donation.created_at)}
                                disabled
                                required
                              />
                            </td>
                            <td className="bg-sky-50 mb-14 rounded-r-xl">
                              <input
                                className="text-sm text-neutral-800 disabled:bg-sky-50 disabled:border-none text-center"
                                type="email"
                                name="email"
                                value={donation.donor_email}
                                disabled
                                required
                              />
                            </td>
                          </>
                        )}

                        {matchesMax430px &&
                          (expandedDonationIndex === index ? (
                            <td
                              className="bg-sky-50 mb-14 rounded-r-xl"
                              align="center"
                            >
                              <FontAwesomeIcon
                                icon={faChevronUp}
                                size="lg"
                                color="#666666"
                              />
                            </td>
                          ) : (
                            <td
                              className="bg-sky-50 mb-14 rounded-r-xl"
                              align="center"
                            >
                              <FontAwesomeIcon
                                icon={faChevronDown}
                                size="lg"
                                color="#666666"
                              />
                            </td>
                          ))}
                      </tr>
                    ))
                  ) : searchTerm !== "" || filter !== "Filter by" ? (
                    <tr>
                      <td
                        colSpan="5"
                        className="bg-sky-50 mb-14 rounded-xl text-sm font-semibold text-neutral-600 text-center"
                      >
                        Sorry, no matching results!
                      </td>
                    </tr>
                  ) : (
                    <tr>
                      <td
                        colSpan="5"
                        className="bg-sky-50 mb-14 rounded-xl text-sm font-semibold text-neutral-600 text-center"
                      >
                        Sorry, no donations!
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            {/* Bottom part of the component */}
            <div
              className={`${classes.donation_page_button} flex flex-row justify-between w-full p-5`}
            >
              <SecondaryButton
                className="!text-sm"
                onClick={() => setTab("Donations")}
              >
                See all donations
              </SecondaryButton>
              <nav className="overflow-x-auto max-w-full">
                <Pagination
                  sx={{
                    ".MuiPaginationItem-root": {
                      backgroundColor: "var(--light-grey)",
                      borderRadius: "0",
                    },
                    ".Mui-selected": {
                      backgroundColor: "var(--default-orange) !important",
                      borderRadius: "0",
                    },
                  }}
                  count={calculateTotalPages()}
                  page={currentPage}
                  className="flex list-none justify-center"
                  color="primary"
                  renderItem={renderItem}
                  onChange={(event, value) => handleChangePage(value)}
                />
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonationsComponent;
