import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { deleteMember } from "../../../../api/DeleteMember";
import dashboardClasses from "../../Dashboard.module.css";
import classes from "./MemberTable.module.css";
import newClasses from "../MemberList.module.css";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { Pagination, PaginationItem } from "@mui/material";
import { DASHBOARD_MODALS } from "../../Dashboard";
import SecondaryButton from "../../../shared/Buttons/SecondaryButton";

export const MemberTable = ({ data, setModal }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(30);
  const [memberList, setMemberList] = useState(data);
  const [localUserData, setLocalUserData] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(-1);
  const userData = useSelector((state) => state.userData.userData);
  const [searchParam, setSearchParam] = useState("");

  // Retrieve user data from localStorage
  const navigate = useNavigate();
  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      setLocalUserData(JSON.parse(userData));
    }
  }, [userData]);

  // Calculate the current items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = memberList.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Calculate page numbers
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(memberList.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const skipPages = 2;

  const totalPages = Math.ceil(memberList.length / itemsPerPage);

  const memberDeleteButtonCallback = (memberId) => {
    try {
      deleteMember(memberId);
      const tempMemberList = memberList.filter((item) => item.id !== memberId);
      setMemberList(tempMemberList);
      setConfirmDelete(-1);
    } catch (error) {
      console.error("Error deleting in donor table:", error);
    }
  };

  const handleSearch = (e) => {
    setSearchParam(e.target.value);
  };

  const renderItem = (item) => {
    if (item.type === "previous" || item.type === "next") {
      const isPrevious = item.type === "previous";
      let page = isPrevious
        ? Math.max(1, item.page - skipPages + 1)
        : Math.min(totalPages, item.page + skipPages - 1);
      return (
        <PaginationItem
          {...item}
          page={page}
          onClick={() => handleChange(null, page)}
        />
      );
    }
    return <PaginationItem {...item} />;
  };

  const handleChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <div className={dashboardClasses.dashboard_block}>
      <div
        className={`${classes.filter_panel} flex items-center mb-5 my-0 mx-auto gap-16`}
      >
        <div className={`flex items-center relative w-full`}>
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            size="xs"
            className="absolute left-3"
            color="#666666"
          />
          <input
            type="text"
            placeholder="Type to search by name, city, or state"
            value={searchParam}
            onInput={handleSearch}
            className={`p-2 pl-8 border text-black border-gray-300 rounded-lg w-full h-7 `}
          />
        </div>
      </div>
      <table className={`${classes.table} w-full`}>
        <thead>
          <tr>
            <th className="text-center">First Name</th>
            <th>Last Name</th>
            <th>Access Level</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentItems
            .filter((member, index) => {
              return (
                member.first_name
                  .toLowerCase()
                  .includes(searchParam.toLowerCase()) ||
                member.last_name
                  .toLowerCase()
                  .includes(searchParam.toLowerCase())
              );
            })
            .map((member, index) => (
              <tr key={index}>
                <td>{member.first_name}</td>
                <td>{member.last_name}</td>
                <td>
                  {member.np_access_level.charAt(0).toUpperCase() +
                    member.np_access_level.slice(1)}
                </td>
                {localUserData &&
                localUserData.user_type === "nonprofit_admin" &&
                localUserData.id !== member.id ? (
                  <td className="group !bg-red-400 !hover:bg-red-700">
                    {confirmDelete === member.id ? (
                      <button
                        onClick={() => {
                          memberDeleteButtonCallback(member.id);
                        }}
                        className="w-full h-full group-hover:text-white"
                      >
                        Confirm?
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          setConfirmDelete(member.id);
                        }}
                        className="w-full h-full group-hover:text-white "
                      >
                        Delete
                      </button>
                    )}
                  </td>
                ) : (
                  <td></td>
                )}
              </tr>
            ))}
        </tbody>
      </table>
      {localUserData && localUserData.np_access_level === "admin" && (
        <div
          className={`${newClasses.button_container} justify-around items-center block md:hidden`}
        >
          <SecondaryButton
            colour="blue"
            onClick={() => {
              setModal(DASHBOARD_MODALS.addMember);
            }}
          >
            ADD NEW TEAM MEMBER
          </SecondaryButton>
        </div>
      )}
      <nav className="overflow-x-auto max-w-full flex justify-center md:justify-end">
        <Pagination
          sx={{
            ".MuiPaginationItem-root": {
              backgroundColor: "var(--light-grey)",
              borderRadius: "0",
            },
            ".Mui-selected": {
              backgroundColor: "var(--default-orange) !important",
              borderRadius: "0",
            },
          }}
          count={totalPages}
          page={currentPage}
          onChange={handleChange}
          className="flex p-5 pr-0 list-none justify-center"
          color="primary"
          renderItem={renderItem}
        />
      </nav>
    </div>
  );
};
