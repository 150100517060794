export const extractTextContent = (txt) => {
  const parser = new DOMParser();
  const parsedHtml = parser.parseFromString(txt, 'text/html');
  let text = '';

  if (parsedHtml.body.textContent) {
    text = parsedHtml.body.textContent.trim();
  }

  // Regular expression to remove leading and trailing quotes
  const sanitizedText = text.replace(/^"+|"+$/g, '');
  
  return sanitizedText;
}

