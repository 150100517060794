import React from "react";
import EditAnimalCampaignForm from "./EditAnimalCampaignForm";
import EditProductCampaignForm from "./EditProductCampaignForm";
import EditCapitalCampaignForm from "./EditCapitalCampaignForm";
import classes from "./EditCampaign.module.css";
import { Grid, Card, CardMedia } from "@mui/material";
import { SortableContainer, SortableElement } from "react-sortable-hoc";

// This is the modal to open up the 3 different edit campaign forms

// TODO:
// Refactor the code to send common props
// Change the Edit Animal campaign page (refactor)

const maxSize = 10 * 1024 * 1024;
const SortableItem = SortableElement(({ value, width }) => {
  return (
    <Grid item xs={width > 900 ? 4 : width > 530 ? 5 : 8} className="relative">
      <Card className="relative">
        <CardMedia
          component="img"
          sx={{ height: 110, objectFit: "cover" }}
          src={value}
          alt="campaign image"
          style={{ cursor: "pointer" }}
        />
      </Card>
    </Grid>
  );
});

const SortableList = SortableContainer(({ items, width }) => {
  // items prop is campaignData.image_urls which only contains the URLs of pics
  // everytime an image is sorted or uploaded, it sorts campaignData.image_urls
  return (
    <div className="max-[900px]:ml-20">
      <Grid container spacing={2} className="overflow-auto">
        {items.map((value, index) => (
          <SortableItem
            key={`item-${index}`}
            index={index}
            value={value}
            width={width}
          />
        ))}
      </Grid>
    </div>
  );
});

// Modal component
const EditCampaignModal = ({ campaignId, campaignData, isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div
      className={`fixed inset-0 bg-black bg-opacity-70 w-full h-full flex items-baseline justify-center overflow-auto p-4 sm:p-12 z-50`}
    >
      <div
        className={`${classes.campaign_modal} relative bg-white rounded w-full sm:w-4/5 flex flex-col items-center border-box h-auto p-5`}
      >
        <button
          onClick={onClose}
          className={`absolute mr-4 max-w-4 top-2 right-2 p-2 border-none text-2xl cursor-pointer zinc-800 bg-transparent`}
        >
          x
        </button>
        <h2 className={`text-3xl font-bold text-orange text-center mb-4 `}>
          Campaign Details
        </h2>
        {campaignData.campaign_type === "animal" ? (
          <EditAnimalCampaignForm
            campaignId={campaignId}
            campaignInfo={campaignData}
            maxSize={maxSize}
            SortableItem={SortableItem}
            SortableList={SortableList}
          />
        ) : campaignData.campaign_type === "product" ? (
          <EditProductCampaignForm
            campaignId={campaignId}
            campaignData={campaignData}
            maxSize={maxSize}
            SortableItem={SortableItem}
            SortableList={SortableList}
          />
        ) : campaignData.campaign_type === "capital" ? (
          <EditCapitalCampaignForm
            campaignId={campaignId}
            campaignData={campaignData}
            maxSize={maxSize}
            SortableItem={SortableItem}
            SortableList={SortableList}
          />
        ) : null}
      </div>
    </div>
  );
};

export default EditCampaignModal;
