import React from "react";

const TextInput = ({
  label,
  subLabel,
  labelClassName,
  sublabelClassName,
  type = "text",
  name,
  placeholder,
  value,
  onChange,
  errorMessage,
  className,
  maxLength,
  rows,
  disabled = false,
}) => {
  // Example usage
  //   <TextInput
  //     label="Full name"
  //     subLabel="(Required)"
  //     labelClassName="!text-white"
  //     type="text"
  //     name="full_name"
  //     placeholder="Full Name"
  //     value={formValues.full_name}
  //     onChange={handleInputChange}
  //     errorMessage={formErrors.full_name}
  //   />;

  //   const handleInputChange = (e) => {
  //     const { name, value } = e.target;

  //     setFormValues((prev) => ({
  //       ...prev,
  //       [name]: value,
  //     }));

  //     // Restarts formErrors when input changes
  //     setFormErrors((prev) => ({
  //       ...prev,
  //       [name]: "",
  //     }));
  //   };
  return (
    <div className={`flex flex-col gap-1 my-2 w-full ${className}`}>
      <label
        id={name}
        className={`text-black font-roboto font-semibold text-lg text-left !w-full block ${labelClassName}`}
      >
        {label}{" "}
        {subLabel && (
          <span
            className={`text-grey-dark1 !text-sm font-roboto font-semibold ${sublabelClassName}`}
          >
            {subLabel}
          </span>
        )}
      </label>

      {rows ? (
        // Render textarea if rows prop is provided
        <textarea
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={(e) =>
            onChange({
              target: {
                name: e.target.name,
                value: e.target.value,
              },
            })
          }
          disabled={disabled}
          rows={rows}
          className={`border ${
            errorMessage ? "border-error" : "border-grey-inputs"
          } 
            shadow-inputs rounded-3xl pl-4 pt-4 w-full text-sm font-roboto
            focus:border-blue active:border-blue focus:outline-none focus:ring-0 placeholder:grey-superLight
            ${value && "text-black"} ${
            !errorMessage && value && "border-success"
          } ${disabled && "bg-neutral-50 cursor-not-allowed"}`}
        />
      ) : (
        // Render input by default
        <input
          type={type}
          name={name}
          placeholder={placeholder}
          value={value}
          maxLength={maxLength}
          onChange={(e) =>
            onChange({
              target: {
                name: e.target.name,
                value: e.target.value,
              },
            })
          }
          disabled={disabled}
          className={`border ${
            errorMessage ? "border-error" : "border-grey-inputs"
          } 
            shadow-inputs rounded-3xl h-12 pl-4 py-2 w-full text-sm font-roboto shadow-inputs
            focus:border-blue active:border-blue focus:outline-none focus:ring-0 placeholder:grey-inputs
            ${value ? "text-black" : "text-grey-inputs"} ${
            !errorMessage && value && "border-success"
          } ${disabled && "bg-gray-200 !cursor-not-allowed"}`}
        />
      )}
      <p className="text-error font-medium !text-sm ml-4 min-h-[20px] font-roboto text-left">
        {errorMessage || "\u00A0"}
      </p>
    </div>
  );
};

export default TextInput;
