import { useState } from "react";
import { sendEmail } from "../../../api/SendEmail";
import { errorHandler } from "../../shared/Utils/ErrorHandler";
import classes from "./ChooseDonorListModal.module.css";
import formClasses from "../DashboardForm.module.css";
import SecondaryButton from "../../shared/Buttons/SecondaryButton";

export default function ChooseDonorListModal({
  isOpen,
  onClose,
  onSelect,
  createEmailJson,
  campaignId,
  nonprofitId,
  showSuccessMessage,
  setShowSuccessMessage,
  setSendEmailError,
  sendEmailError,
  ifCustomEmail,
  customSubject = "",
  emailBody = "",
}) {
  const [selectedOption, setSelectedOption] = useState("");
  const [showConfirmationPrompt, setShowConfirmationPrompt] = useState(false);
  const [successMessage, setSuccessMessage] = useState(showSuccessMessage);

  const handleSelectionChange = (e) => {
    setSelectedOption(e.target.value);
    if (showConfirmationPrompt) setShowConfirmationPrompt(false);
  };

  const handleSendEmail = async (numericSelection) => {
    let data = ifCustomEmail
      ? {
          subject: customSubject || "No Subject",
          body: emailBody.email_body,
          name: "Custom Email",
          custom_email: ifCustomEmail,
        }
      : createEmailJson();

    if (!data) return;

    try {
      const response = await sendEmail(
        data,
        numericSelection,
        campaignId,
        nonprofitId
      );
      setSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
        // Now close the modal and call onSelect
        onClose();
        onSelect(numericSelection);
      }, 3000);
    } catch (error) {
      console.error("Error sending email: ", error);
      const errMsg = errorHandler(error);
      setSendEmailError(errMsg);
    }
  };

  const handleConfirmSelection = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    const optionValues = { full: 0, donated: 1, notDonated: 2 };
    const numericSelection = optionValues[selectedOption];
    await handleSendEmail(numericSelection); // Make the API call
  };

  // if (!isOpen) return null;

  return (
    <div className={classes.choose_overlay} onClick={onClose}>
      <div
        className={classes.modal_choose_donor}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={classes.choose_cancel_div}>
          <button className={classes.choose_cancel_button} onClick={onClose}>
            X
          </button>
        </div>
        <h2 className="text-2xl font-semibold mb-8">Choose Donor List</h2>
        <div className="flex justify-center cursor-pointer">
          <select
            required
            value={selectedOption}
            onChange={handleSelectionChange}
          >
            <option value="">Select an option</option>
            <option value="full">Full Donor List</option>
            <option value="donated">People who have donated</option>
            <option value="notDonated">People who have not donated</option>
          </select>
        </div>
        <div className="mx-auto">
          {!showConfirmationPrompt ? (
            <SecondaryButton
              type="button"
              onClick={() => {
                setShowConfirmationPrompt(true);
              }}
              className="mt-4"
            >
              Send
            </SecondaryButton>
          ) : (
            <>
              <h3>Are you sure?</h3>
              <div className={`${classes.confirmation_prompt_buttons} mt-4`}>
                <SecondaryButton
                  onClick={() => {
                    setShowConfirmationPrompt(false);
                  }}
                  className="mr-4"
                >
                  No
                </SecondaryButton>
                <SecondaryButton onClick={(e) => handleConfirmSelection(e)}>
                  Yes
                </SecondaryButton>
              </div>
            </>
          )}
          {successMessage && (
            <p className="text-success">Success! Emails have been sent</p>
          )}
          {sendEmailError && <p className="text-error">{sendEmailError}</p>}
        </div>
      </div>
    </div>
  );
}
