import React, { useEffect, useState, useRef } from "react";
import { errorHandler } from "../../shared/Utils/ErrorHandler";
import PrimaryButton from "../../shared/Buttons/PrimaryButton";
import { addUpdate } from "../../../api/AddUpdate";

const CampaignUpdateForm = ({ campaignId, campaignData }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [updateData, setUpdateData] = useState({
    update_content: "",
    campaign: campaignId,
    campaign_type: campaignData.campaign_type,
  });
  const [image, setImage] = useState(null);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  /* Handles input change */
  const handleUpdateChange = (e) => {
    const { name, value } = e.target;
    setUpdateData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleUpdateImageChange = (e) => {
    const file = e.target.files[0];
    setSuccessMsg("");
    if (file.size > 3 * 1024 * 1024) {
      setImage(null);
      setErrorMsg("Image size is too big.");
    } else {
      setImage(file);
    }
  };

  // Make form and API call
  const submitUpdate = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;

    setIsSubmitting(true);
    setErrorMsg("");
    setSuccessMsg("");
    try {
      let formData = new FormData();
      Object.keys(updateData).forEach((key) => {
        if (updateData[key] !== "") {
          formData.append(key, updateData[key]);
        }
      });
      if (image) {
        formData.append("image", image);
      }

      const response = await addUpdate(formData);
      setSuccessMsg("Successfully posted an update!");

      // Reset form
      setUpdateData({
        update_content: "",
        campaign: campaignId,
        campaign_type: campaignData.campaign_type,
      });
      setImage(null);
    } catch (error) {
      const errorMsg = errorHandler(error);
      console.error("Error submitting the update:", error);
      setErrorMsg(errorMsg);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      {/* update form here */}
      <form
        onSubmit={submitUpdate}
        className={`w-full mb-6 p-6 border border-gray-200 rounded-3xl shadow-sm shadow-gray-200 max-[1000px]:flex max-[1000px]:flex-col`}
      >
        <h2 className="text-2xl text-left mb-2">Post an Update</h2>
        <textarea
          name="update_content"
          value={updateData.update_content}
          onChange={handleUpdateChange}
          placeholder="Add your text here..."
          className="p-4 border text-[var(--dark-grey)] !min-h-28 rounded-lg w-full"
        />

        <div className="flex flex-row justify-between items-center w-full max-[1000px]:flex-col max-[1000px]:justify-start max-[1000px]:items-start">
          <div className="flex flex-col">
            <h2 className="text-xl font-normal mb-2">
              Image Upload (.jpg,.jpeg,.png under 3 MB)
            </h2>
            <input
              type="file"
              onChange={handleUpdateImageChange}
              accept="image/jpeg, image/png, image/jpg"
              className="!p-2.5 text-black"
            />
          </div>

          <div className="flex flex-row justify-center align-center">
            <PrimaryButton
              disabled={isSubmitting}
              className="!text-xl !w-80 !h-16 max-[1000px]:!w-60 max-[1000px]:!h-12 max-[1000px]:!mt-4 max-[530px]:!w-40 max-[530px]:!h-8"
            >
              {isSubmitting ? "Posting..." : "Post Update"}
            </PrimaryButton>
          </div>
        </div>
        {errorMsg && (
          <p className="text-[var(--default-red)] mt-2">{errorMsg}</p>
        )}
        {successMsg && <p className="text-green-500 mt-2">{successMsg}</p>}
      </form>
    </>
  );
};

export default CampaignUpdateForm;
