import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faStar,
  faCat,
  faDog,
  faHorse,
  faOtter,
} from "@fortawesome/free-solid-svg-icons";
import PrimaryButton from "../../shared/Buttons/PrimaryButton";
import DropDown from "../../shared/Inputs/Dropdown";

// This is the header component for Campaign Search page
// Renders different searches and dropdowns depending on the campaign type

const StateSearch = ({ values, handleSearchChange, states }) => {
  return (
    <DropDown
      name="state"
      value={values.state}
      onChange={handleSearchChange}
      options={states}
      optionKey="abbreviation" // Key for option values
      optionLabel="name" // Key for option labels
      placeholder="Select a State"
      className="!w-60"
    />
  );
};

// Component for search input and submission
const InputSearch = ({ values, handleChange, handleSearch, placeHolder }) => {
  return (
    <div className="flex flex-col lg:flex-row justify-between items-center my-0 gap-4 md:w-[60vw]">
      <div className="flex flex-1 items-center max-w-full w-full lg:w-auto h-9 border border-grey rounded-full overflow-hidden bg-white">
        <FontAwesomeIcon
          className="cursor-default text-grey ml-3 mr-2 text-sm"
          icon={faMagnifyingGlass}
        />
        <input
          type="text"
          placeholder={placeHolder}
          name="search"
          value={values.search}
          onChange={handleChange}
          className="flex-1 border-none outline-none text-xs px-2"
        />
      </div>
      <PrimaryButton onClick={() => handleSearch(values)}>Search</PrimaryButton>
    </div>
  );
};

const SearchHeader = ({
  handleSearchChange,
  animalSearch,
  ageOptions,
  productSearch,
  productTypeOptions,
  capitalSearch,
  projectStatusOptions,
  type,
  handleSearch,
  stateParam,
  states,
}) => {
  return (
    <div className="sm:grow flex flex-col justify-center items-center w-full !my-0 pt-40 bg-blue">
      {type === "animal" ? (
        <>
          <h1 className="text-white">Animal Campaigns</h1>
          <div className="flex gap-6 lg:gap-12 my-8">
            {["", "dog", "cat", "farm", "exotic"].map((species) => (
              <label
                key={species}
                className={`flex flex-col w-12 cursor-pointer pb-1 border-b-[1px] ${
                  animalSearch.species === species
                    ? "text-yellow border-yellow"
                    : "text-white border-blue"
                }`}
              >
                <input
                  className="opacity-0 w-0 h-0"
                  type="radio"
                  name="species" // Set a fixed name for consistent state updates
                  id={`species-${species || "all"}`}
                  value={species} // Use `species` directly for the value
                  defaultChecked={species === ""}
                  onChange={handleSearchChange}
                />
                <FontAwesomeIcon
                  className="text-3xl"
                  icon={
                    species === "dog"
                      ? faDog
                      : species === "cat"
                      ? faCat
                      : species === "farm"
                      ? faHorse
                      : species === "exotic"
                      ? faOtter
                      : faStar
                  }
                />
                <p className="m-0 mt-3.5 capitalize">{species || "All"}</p>
              </label>
            ))}
          </div>

          <InputSearch
            values={animalSearch}
            handleChange={handleSearchChange}
            handleSearch={handleSearch}
            placeHolder="Type to search by animal name, location, nonprofit name, or animal breed."
          />

          <div className="flex flex-col md:flex-row py-5 gap-3 md:gap-4 mb-4">
            <StateSearch
              values={animalSearch}
              stateParam={stateParam}
              handleSearchChange={handleSearchChange}
              states={states}
            />

            <DropDown
              name="age"
              value={animalSearch.age}
              onChange={handleSearchChange}
              options={ageOptions}
              placeholder="Select an Age"
            />
          </div>
        </>
      ) : type === "product" ? (
        <>
          <h1 className="text-white mb-4">Product Campaigns</h1>
          <InputSearch
            values={productSearch}
            handleChange={handleSearchChange}
            handleSearch={handleSearch}
            placeHolder="Type to search by product name, location, or nonprofit name"
          />
          <div className="flex flex-col md:flex-row justify-center items-center py-5 gap-3 md:gap-4 mb-4">
            <StateSearch
              values={productSearch}
              stateParam={stateParam}
              handleSearchChange={handleSearchChange}
              states={states}
            />
            <DropDown
              name="productType"
              value={productSearch.productType}
              onChange={handleSearchChange}
              options={productTypeOptions}
              placeholder="Select a product"
              className="!w-48"
            />
          </div>
        </>
      ) : type === "capital" ? (
        <div>
          <h1 className="text-white mb-4">Capital Campaigns</h1>
          <InputSearch
            values={capitalSearch}
            handleChange={handleSearchChange}
            handleSearch={handleSearch}
            placeHolder="Type to search by location, nonprofit name, or "
          />
          <div className="flex flex-col md:flex-row justify-center items-center py-5 gap-3 md:gap-4 mb-4">
            <StateSearch
              values={capitalSearch}
              stateParam={stateParam}
              handleSearchChange={handleSearchChange}
              states={states}
              className="!w-60"
            />
            <DropDown
              name="projectStatus"
              value={capitalSearch.projectStatus}
              onChange={handleSearchChange}
              options={projectStatusOptions}
              placeholder="Select a status"
              className="!w-48"
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SearchHeader;
