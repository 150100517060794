import React, { useState, useEffect, useRef } from "react";
import { submitCampaign } from "../../../api/SubmitCampaign";
import QRCodeModal from "../QRCodeModal/QRCodeModal";
import { urlifyName } from "../../shared/Utils/PageUrl";
import classes from "./CampaignModal.module.css";
import { errorHandler } from "../../shared/Utils/ErrorHandler";
import { lineBreaks } from "../../shared/Utils/LineBreaks";
import SharedTooltip from "../../shared/Tooltip/SharedTooltip";
import Quill from "quill";
import Editor from "./Editor/Editor";
import { Grid, Card, CardMedia } from "@mui/material";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import Compressor from "compressorjs";
import CampaignFeature from "../../Campaigns/CampaignFeature/CampaignFeature";
import PrimaryButton from "../../shared/Buttons/PrimaryButton";
import SecondaryButton from "../../shared/Buttons/SecondaryButton";
import TextInput from "../../shared/Inputs/TextInput";
import DropDown from "../../shared/Inputs/Dropdown";

const ageOptions = [
  { value: 1, label: "Newborn" },
  { value: 2, label: "Young" },
  { value: 3, label: "Adult" },
  { value: 4, label: "Senior" },
  { value: 5, label: "Unknown" },
];
const genderOptions = ["Female", "Male"];
const speciesOptions = ["Dog", "Cat", "Farm", "Exotic", "Other"];

const AnimalCampaignForm = ({
  nonprofitId,
  onSuccess,
  nonprofitName,
  qrModalOpen,
  campaignUrl,
  setQrModalOpen,
  animalFormValues,
  setAnimalFormValues,
  animalFormErrors,
  setAnimalFormErrors,
  SortableList,
  handleCampaignSubmit,
  handleImageChange,
  handleGraphicImageChange,
  imagePreviews,
  graphicImagePreviews,
  onSortEnd,
  campaignType,
  isLoading,
}) => {
  const [tooltipKey, setTooltipKey] = useState(0);
  const [createError, setCreateError] = useState("");
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  // const [imagePreviews, setImagePreviews] = useState([]);
  // const [graphicImagePreviews, setGraphicImagePreviews] = useState([]);
  const [compressedFile, setCompressedFile] = useState(null);
  const fileInputRef = useRef();
  const graphicFileInputRef = useRef();
  const [selectedFileCount, setSelectedFileCount] = useState(0);
  const [selectedGraphicFileCount, setSelectedGraphicFileCount] = useState(0);

  useEffect(() => {
    setTooltipKey((prevKey) => prevKey + 1);
  }, [animalFormValues.donation_box_title]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    const finalValue =
      name === "age"
        ? ageOptions.find((option) => option.value.toString() === value)
            ?.value || value
        : value;

    // Prevent negative donation goals
    if (name === "donation_goal" && parseFloat(value) < 0) {
      return;
    }

    // Update form values
    setAnimalFormValues((prevState) => ({
      ...prevState,
      [name]: finalValue,
    }));

    // Reset error for the changed field
    setAnimalFormErrors((prevState) => ({
      ...prevState,
      [name]: "",
    }));
  };

  const handleEditorChange = (content) => {
    setAnimalFormValues((prevState) => ({
      ...prevState,
      description: content,
    }));
  };

  const handleOpenPreview = () => {
    setPreviewModalOpen(true);

    const createCampaignContent = document.querySelector(
      `.${classes.campaign_modal_overlay}`
    );
    if (createCampaignContent) {
      createCampaignContent.style.overflow = "hidden";
    }
  };

  const handleClosePreview = () => {
    setPreviewModalOpen(false);

    const createCampaignContent = document.querySelector(
      `.${classes.campaign_modal_overlay}`
    );
    if (createCampaignContent) {
      createCampaignContent.style.overflow = "auto";
    }
  };

  return (
    <div>
      <h2 className="font-bold text-orange text-2xl text-center">
        Create New Animal Campaign
      </h2>
      <form onSubmit={handleCampaignSubmit}>
        <TextInput
          label="Animal Name"
          subLabel="(Required)"
          type="text"
          name="animal_name"
          placeholder="Animal Name"
          value={animalFormValues.animal_name}
          onChange={handleInputChange}
          errorMessage={animalFormErrors.animal_name}
        />

        <TextInput
          label="Campaign Title"
          subLabel="(Required)"
          type="text"
          name="campaign_title"
          placeholder="Campaign Title"
          value={animalFormValues.campaign_title}
          onChange={handleInputChange}
          errorMessage={animalFormErrors.campaign_title}
        />

        <div>
          <label className="flex flex-row justify-start items-center gap-1.5">
            <h2 className="text-black font-roboto font-semibold text-lg block">
              Description
            </h2>
            <span className="text-grey-dark1 !text-sm font-roboto font-semibold">
              (Required)
            </span>
          </label>
          <Editor
            defaultValue={animalFormValues.description}
            onTextChange={handleEditorChange}
          />
          <div className="mt-2 text-left w-full ">
            {animalFormErrors.description !== "" && (
              <p className="text-error !text-sm">
                {animalFormErrors.description}
              </p>
            )}
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-[auto,1fr] gap-2.5 items-end">
          <TextInput
            label="Donation Goal"
            subLabel="(Required)"
            sublabelClassName="!text-xs"
            type="number"
            name="donation_goal"
            placeholder="0"
            value={animalFormValues.donation_goal}
            onChange={handleInputChange}
            errorMessage={animalFormErrors.donation_goal}
            className="!w-11/12"
          />
          <TextInput
            label="Donation Box Title "
            maxLength="30"
            subLabel={
              <>
                {" "}
                (Max. 30 Chars){"  "}
                <SharedTooltip
                  id="my-tooltip"
                  toolClass="flex justify-center items-center"
                  content="The heading right above the donation box"
                />
              </>
            }
            sublabelClassName="inline-flex items-center !text-xs"
            type="text"
            name="donation_box_title"
            placeholder="Donate"
            value={animalFormValues.donation_box_title}
            onChange={handleInputChange}
            errorMessage={animalFormErrors.donation_box_title}
          />
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-2.5 items-center">
          <DropDown
            label="Age"
            subLabel="(Required)"
            placeholder="Select age"
            options={ageOptions}
            name="age"
            value={animalFormValues.age}
            onChange={handleInputChange}
            errorMessage={animalFormErrors.age}
          />
          <TextInput
            label="Color"
            subLabel="(Required)"
            type="text"
            name="color"
            placeholder="Color"
            value={animalFormValues.color}
            onChange={handleInputChange}
            errorMessage={animalFormErrors.color}
          />
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-3 gap-2.5 items-center">
          <TextInput
            label="Breed"
            subLabel="(Required)"
            type="text"
            name="breed"
            placeholder="Breed"
            value={animalFormValues.breed}
            onChange={handleInputChange}
            errorMessage={animalFormErrors.breed}
          />

          <DropDown
            label="Gender"
            subLabel="(Required)"
            placeholder="Select gender"
            options={genderOptions}
            name="gender"
            value={animalFormValues.gender}
            onChange={handleInputChange}
            errorMessage={animalFormErrors.gender}
          />
          <DropDown
            label="Species"
            subLabel="(Required)"
            placeholder="Select species"
            options={speciesOptions}
            name="species"
            value={animalFormValues.species}
            onChange={handleInputChange}
            errorMessage={animalFormErrors.species}
          />
        </div>

        <TextInput
          label="Video URLs"
          subLabel="(Separated by commas)"
          type="text"
          name="video_urls"
          placeholder="URL, URL"
          value={animalFormValues.video_urls}
          onChange={handleInputChange}
          errorMessage={animalFormErrors.video_urls}
        />

        <div>
          <h2 className="text-lg mb-4 !text-black font-roboto font-semibold">
            Upload Images
          </h2>
          <SortableList
            items={imagePreviews}
            onSortEnd={onSortEnd}
            axis="xy"
            helperClass={classes.sortable_helper}
          />

          <PrimaryButton
            className="!w-1/2 !justify-center !items-center !flex !m-auto !mt-4"
            onClick={(e) => {
              e.preventDefault();
              fileInputRef.current.click();
            }}
          >
            Add Image
          </PrimaryButton>
          <input
            onChange={(e) =>
              handleImageChange(e, setAnimalFormErrors, setAnimalFormValues)
            }
            multiple
            ref={fileInputRef}
            name="images"
            type="file"
            hidden
          />
        </div>

        <div className="mt-2">
          <h2 className="text-lg mb-4 !text-black flex gap-2 justify-center items-center font-roboto">
            Upload Graphic Images ({animalFormValues.graphic_images.length})
            <SharedTooltip
              id="graphic-images-tooltip"
              toolClass="flex justify-center items-center font-normal"
              content="These will initially be blurred out until a user clicks 'Show'"
            />
          </h2>

          <PrimaryButton
            type="button"
            className="!w-1/2 !justify-center !items-center !flex !m-auto !my-2"
            onClick={(e) => {
              e.preventDefault();
              graphicFileInputRef.current.click();
            }}
          >
            Add Image
          </PrimaryButton>
          <input
            onChange={(e) =>
              handleGraphicImageChange(
                e,
                setAnimalFormErrors,
                setAnimalFormValues
              )
            }
            multiple
            ref={graphicFileInputRef}
            name="graphic_images"
            type="file"
            hidden
          />
          <div className="mt-2 text-center w-full ">
            {animalFormErrors.images !== "" && (
              <p className="text-error !text-sm">{animalFormErrors.images}</p>
            )}
          </div>
        </div>

        <div className="my-4 flex gap-2 justify-center items-center">
          <SecondaryButton type="submit" disabled={isLoading}>
            {isLoading ? "Saving..." : "Save Campaign"}
          </SecondaryButton>
          <PrimaryButton
            type="preview"
            onClick={(e) => {
              e.preventDefault();
              handleOpenPreview();
            }}
            className="!my-2"
          >
            Preview
          </PrimaryButton>
        </div>
      </form>

      {!createError && (
        <p style={{ color: "green" }}>{animalFormValues.success}</p>
      )}
      {createError ? <p style={{ color: "red" }}>{createError}</p> : ""}

      {previewModalOpen && (
        <CampaignFeature
          isOpen={previewModalOpen}
          onClose={() => handleClosePreview()}
          campaignData={animalFormValues}
          imagePreviews={imagePreviews}
          graphicImagePreviews={graphicImagePreviews}
          campaignType={campaignType}
        />
      )}

      <QRCodeModal
        isOpen={qrModalOpen}
        onClose={() => setQrModalOpen(false)}
        campaignUrl={campaignUrl}
        key={campaignUrl}
      />
    </div>
  );
};

export default AnimalCampaignForm;
