import React, { useEffect, useState, useRef } from "react";
import { updateCampaign } from "../../../api/UpdateCampaign";
import SharedTooltip from "../../shared/Tooltip/SharedTooltip";
import editClasses from "./EditCampaign.module.css";
import { errorHandler } from "../../shared/Utils/ErrorHandler";
import Editor from "../CampaignModal/Editor/Editor";
import { arrayMove } from "react-sortable-hoc";
import Compressor from "compressorjs";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import SecondaryButton from "../../shared/Buttons/SecondaryButton";
import PrimaryButton from "../../shared/Buttons/PrimaryButton";
import CampaignUpdateForm from "./CampaignUpdateForm";
import CampaignShareBox from "./CampaignShareBox";
import TextInput from "../../shared/Inputs/TextInput";
import DropDown from "../../shared/Inputs/Dropdown";
import Spinner from "../../shared/LoadingSpinner/Spinner";

// This component renders updating the product campaign details

const productOptions = [
  { value: "food", label: "Food" },
  { value: "toys", label: "Toys" },
  { value: "supplies", label: "Supplies" },
  { value: "beds", label: "Beds" },
  { value: "medical", label: "Medical Equipment" },
  { value: "other", label: "Other" },
];

const EditProductCampaignForm = ({
  campaignId,
  campaignData,
  maxSize,
  SortableList,
}) => {
  const [productFormValues, setProductFormValues] = useState(() => {
    const imageUrls =
      campaignData?.campaign_images?.map((image) => image.image_url) || [];
    return {
      nonprofit: campaignData?.nonprofit || "",
      campaign_title: campaignData?.campaign_title || "",
      description: campaignData?.description || "",
      donation_goal: campaignData?.donation_goal || "",
      donation_box_title: campaignData?.donation_box_title || "",
      video_urls: campaignData?.video_urls || "",
      images: campaignData?.campaign_images || [],
      image_urls: imageUrls,
      campaign_type: "product",
      product_type: campaignData?.product_type || "",
      product_quantity_needed: campaignData?.product_quantity_needed || "",
    };
  });

  const [productFormErrors, setProductFormErrors] = useState({
    nonprofit: "",
    campaign_title: "",
    description: "",
    donation_goal: "",
    donation_box_title: "",
    video_urls: "",
    images: "",
    product_type: "",
    product_quantity_needed: "",
  });
  const { width } = useWindowDimensions();
  const [totalImages, setTotalImages] = useState({
    images: 0,
  });
  let imageCount = 0;
  const [formEnabled, setFormEnabled] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleImageChange = (event) => {
    let files = Array.from(event.target.files);

    const compressFiles = async (files) => {
      const compressedFiles = await Promise.all(
        files.map(async (file) => {
          return new Promise((resolve) => {
            //Compressor is async for success
            new Compressor(file, {
              quality: 0.8,
              success: (compressedResult) => {
                resolve(compressedResult);
              },
            });
          });
        })
      );

      return compressedFiles;
    };

    const handleFileCompression = async (files) => {
      try {
        const compressedFiles = await compressFiles(files);
      } catch (error) {
        console.error("Error during file compression:", error);
      }
    };

    handleFileCompression(files);
    let error = "";
    let isTooLarge = false;

    const validFiles = files.filter((file) => {
      if (file.size > maxSize) {
        error = "File size is too large (Must be under 3 MB).";
        isTooLarge = true;
        return false;
      }
      return true;
    });

    if (isTooLarge) {
      setProductFormErrors((prevState) => ({
        ...prevState,
        images: error,
      }));
    } else {
      setProductFormErrors((prevState) => ({
        ...prevState,
        images: "",
      }));

      const newImages = validFiles.map((file) => ({
        file,
        url: URL.createObjectURL(file),
      }));

      setProductFormValues((prevState) => {
        // Generate order for new images
        const startingIndex = prevState.images.filter(
          (img) => !img.file
        ).length; // Only count uploaded images
        const newCampaignImages = newImages.map((img, index) => ({
          file: img.file,
          image_url: img.url, // Include the URL in new images
          order: startingIndex + index,
        }));

        return {
          ...prevState,
          images: [...prevState.images, ...newCampaignImages],
          image_urls: [
            ...prevState.image_urls,
            ...newImages.map((img) => img.url),
          ],
        };
      });
    }
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setProductFormValues((prevState) => ({
      ...prevState,
      image_urls: arrayMove(prevState.image_urls, oldIndex, newIndex),
    }));
  };

  // Testing for required inputs
  const validateForm = (formValues, setFormErrors) => {
    const errors = {};

    // For all forms
    if (!formValues.campaign_title.trim()) {
      errors.campaign_title = "Campaign title is required";
    }
    if (!formValues.donation_goal) {
      errors.donation_goal = "Donation goal is required";
    }
    if (!formValues.description) {
      errors.description = "Description is required";
    }
    if (!formValues.product_type) {
      errors.product_type = "Product type is required";
    }
    if (!formValues.product_quantity_needed) {
      errors.product_quantity_needed = "Quantity is required";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0; // Returns true if no errors
  };

  const appendFormValues = (formValues) => {
    const formData = new FormData();

    // Append non-image fields
    Object.keys(formValues).forEach((key) => {
      if (key === "description") {
        // Convert HTML content to string before appending
        formData.append(key, JSON.stringify(formValues[key]));
      } else if (key !== "images") {
        // Append other non-image fields
        formData.append(key, formValues[key]);
      }

      // Add milestones_reached to FormData as a JSON string
      if (campaignData.milestones_reached) {
        formData.append(
          "milestones_reached",
          JSON.stringify(campaignData.milestones_reached)
        );
      }
    });

    // Append images and their orders if they exist
    if (formValues.images && Array.isArray(formValues.images)) {
      formValues.images.forEach((image, index) => {
        formData.append("images", image.file);
        formData.append("orders", index); // Append order explicitly
      });
    }

    return formData;
  };

  const handleProductSubmit = () => {
    if (validateForm(productFormValues, setProductFormErrors)) {
      const formData = appendFormValues(productFormValues);

      return formData;
    }

    setIsLoading(false); // Stop loading on validation failure
    return null; // Explicitly return null if validation fails
  };

  const submitCampaignUpdate = async (e) => {
    e.preventDefault();
    setSuccessMsg("");
    setErrorMsg("");
    setIsLoading(true);

    let formData;
    formData = handleProductSubmit();
    if (formData) {
      try {
        // sorts campaign_images based on image_urls since image_urls changes order when moved
        const campaignImages = [...productFormValues.images];
        const imageUrls = [...productFormValues.image_urls];

        // campaignImages gets sorted based on image_urls
        // this gets sent to the backend as "images"
        campaignImages.sort(function (a, b) {
          return (
            imageUrls.indexOf(a.image_url) - imageUrls.indexOf(b.image_url)
          );
        });

        campaignImages.forEach((image, index) => {
          if (image.file) {
            // if image hasn't been uploaded yet, it should contain a File
            formData.append("new_images", image.file);
            formData.append("new_image_orders", index);
          } else {
            // else, sending image ID and order to the backend to change it
            formData.append("existing_image_ids", image.id);
            formData.append("existing_image_orders", index);
          }
        });

        const response = await updateCampaign(campaignData.id, formData);

        if (response.success && response.data) {
          setSuccessMsg("Your campaign has been successfully updated!");
          setErrorMsg("");
          setIsLoading(false);
          setFormEnabled(false); // Disable form fields again
        }
      } catch (error) {
        const errorMessage = errorHandler(error);
        const formattedError = errorMessage;
        setSuccessMsg("");
        setErrorMsg(formattedError);
      }
    }
  };

  /* Handles Campaign Edit Update */
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Prevent negative donation & quantity goals
    if (
      (name === "product_quantity_needed" || name === "donation_goal") &&
      parseFloat(value) < 0
    ) {
      return;
    }

    const finalValue =
      name === "product_type"
        ? productOptions.find((option) => option.value.toString() === value)
            ?.value || value
        : value;
    setProductFormValues((prevState) => ({
      ...prevState,
      [name]: finalValue,
    }));

    // Clear states on input
    setSuccessMsg("");
    setErrorMsg("");
    setProductFormErrors({
      nonprofit: "",
      campaign_title: "",
      description: "",
      donation_goal: "",
      donation_box_title: "",
      video_urls: "",
      images: "",
      product_type: "",
      product_quantity_needed: "",
    });
  };

  const enableEdits = () => {
    setFormEnabled((prev) => !prev);
  };

  const handleEditorChange = (content, delta, source, editor) => {
    setProductFormValues((prevState) => ({
      ...prevState,
      description: content, // Set description as HTML content
    }));
  };

  return (
    <>
      {/* update form  */}
      <CampaignUpdateForm campaignId={campaignId} campaignData={campaignData} />

      {/* Edit Campaign  */}
      <div className={`flex flex-row justify-between gap-8`}>
        <form
          onSubmit={submitCampaignUpdate}
          className={`flex flex-col w-full`}
        >
          {/* Image Upload/Rearrange */}
          <div className="flex flex-col justify-center items-center gap-5 mb-4">
            <p className="font-normal text-lg italic">
              Click and drag to reorder
            </p>
            <div className="flex flex-row justify-center w-full items-center">
              <div className="flex flex-col justify-center items-center w-64 ">
                <p className="text-xl text-left font-normal">
                  Upload New Image
                </p>
                <input
                  type="file"
                  name="images"
                  multiple
                  onChange={handleImageChange}
                  className="!p-2.5 text-black"
                  disabled={!formEnabled}
                />
              </div>
            </div>
            <SortableList
              width={width}
              items={productFormValues.image_urls}
              onSortEnd={onSortEnd}
              axis="xy"
              helperClass={editClasses.sortable_helper}
            />
          </div>

          {/* Campaign Details */}
          <div className="flex flex-col">
            <TextInput
              label="Campaign Title"
              subLabel="(Required)"
              type="text"
              name="campaign_title"
              placeholder="Campaign Title"
              value={productFormValues.campaign_title}
              onChange={handleInputChange}
              errorMessage={productFormErrors.campaign_title}
              disabled={!formEnabled}
            />

            <div className="flex flex-col gap-4">
              <h2 className="text-black font-roboto font-normal text-lg text-left !w-full block">
                Description
              </h2>
              <Editor
                defaultValue={productFormValues.description}
                onTextChange={handleEditorChange}
                readOnly={!formEnabled}
              />
              <p className="text-error font-medium !text-sm ml-4 min-h-[20px] font-roboto text-left">
                {productFormErrors.description || "\u00A0"}
              </p>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <TextInput
                label="Donation Goal"
                subLabel="(Required)"
                type="number"
                name="donation_goal"
                placeholder="$"
                value={productFormValues.donation_goal}
                onChange={handleInputChange}
                errorMessage={productFormErrors.donation_goal}
                disabled={!formEnabled}
              />

              <TextInput
                label="Donation Box Title"
                subLabel={
                  <>
                    {" "}
                    (Max. 30 Chars){"  "}
                    <SharedTooltip
                      id="my-tooltip"
                      toolClass="flex justify-center items-center"
                      content="The heading right above the donation box"
                    />
                  </>
                }
                sublabelClassName="inline-flex items-center !text-xs"
                type="text"
                name="donation_box_title"
                maxLength="30"
                placeholder="Donation Box Title (Max. 30 Chars)"
                value={productFormValues.donation_box_title}
                onChange={handleInputChange}
                errorMessage={productFormErrors.donation_box_title}
                disabled={!formEnabled}
              />
            </div>

            <TextInput
              label="Video URL"
              type="text"
              name="video_urls"
              placeholder="Video URLs (Separate with comma)"
              value={productFormValues.video_urls}
              onChange={handleInputChange}
              errorMessage={productFormErrors.video_urls}
              disabled={!formEnabled}
            />
          </div>

          {/* Campaign Details */}
          <div className="flex flex-col gap-y-4">
            <h2 className="text-2xl text-left max-[530px]:text-center">
              Product Details
            </h2>
            <div className="grid grid-cols-2 gap-4">
              <DropDown
                label="Product type"
                subLabel="(Required)"
                placeholder="Select an option"
                options={productOptions}
                name="product_type"
                value={productFormValues.product_type}
                onChange={handleInputChange}
                errorMessage={productFormErrors.product_type}
                disabled={!formEnabled}
              />
              <TextInput
                label="Quantity Needed"
                subLabel="(Required)"
                type="number"
                name="product_quantity_needed"
                placeholder="0"
                value={productFormValues.product_quantity_needed}
                onChange={handleInputChange}
                errorMessage={productFormErrors.product_quantity_needed}
                disabled={!formEnabled}
              />
            </div>
          </div>

          {/* Success/Error messages and buttons */}

          <div
            className={`flex flex-row justify-end items-center gap-6 max-[530px]:flex-col`}
          >
            <SecondaryButton
              onClick={enableEdits}
              type="button"
              className="!w-48 !h-9"
            >
              Edit
            </SecondaryButton>
            <PrimaryButton
              type="submit"
              disabled={!formEnabled}
              className="!w-48 !h-9 disabled:!bg-neutral-300"
            >
              {isLoading ? <Spinner size={20} /> : "Save"}
            </PrimaryButton>
          </div>
          <div className="my-4">
            {successMsg && (
              <p className="text-success font-medium !text-sm min-h-[20px] font-roboto">
                {successMsg}
              </p>
            )}
            {errorMsg && (
              <p className="text-error font-medium !text-sm min-h-[20px] font-roboto">
                {errorMsg}
              </p>
            )}
          </div>
        </form>

        {/* Right side */}
        <div className={`flex flex-col gap-6 ${editClasses.goal_container}`}>
          {/* Goal Details */}
          <div
            className={`${editClasses.goal_box} p-4 flex flex-col gap-4 border border-gray-200 rounded-3xl shadow-sm shadow-gray-200`}
          >
            <h2
              className={`${editClasses.goal_box_title} font-semibold text-4xl text-left`}
            >
              ${campaignData.donation_goal} Goal
            </h2>
            <progress
              className={`${editClasses.progress_bar} w-full h-2 my-2`}
              value={campaignData.donation_amount}
              max={campaignData.donation_goal}
            />
            <div className="flex flex-row items-stretch justify-between">
              <div className="flex flex-col">
                <p className="text-gray-500 text-xs font-medium text-left">
                  Goal
                </p>
                <p className="gray-700 font-semibold text-lg">
                  ${campaignData.donation_goal}
                </p>
              </div>
              <div>
                <p className="text-gray-500 text-xs font-medium text-left">
                  Remaining
                </p>
                <p className="gray-700 font-semibold text-lg">
                  $
                  {campaignData.donation_goal - campaignData.donation_amount < 0
                    ? 0
                    : campaignData.donation_goal - campaignData.donation_amount}
                </p>
              </div>
            </div>
          </div>

          {/* QR Code */}
          <CampaignShareBox campaignData={campaignData} />
        </div>
      </div>
    </>
  );
};

export default EditProductCampaignForm;
