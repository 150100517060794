import React, { useState } from "react";
import formClasses from "../../DashboardForm.module.css";
import dashboardClasses from "../../Dashboard.module.css";
import { Pagination, PaginationItem, useMediaQuery } from "@mui/material";
import classes from "./UserDonationTable.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faChevronUp,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { MenuItem } from "@mui/material";
import Select from "@mui/material/Select";
import {
  formatDate,
  isWithinLastWeek,
  IsWithinLastMonth,
} from "../../../shared/Utils/DateFilters";

// TODO:
// Need to update this page for the new donations
// Fix the dropdown filter by

// Arrow for drop down buttons
const CustomIcon = (props) => (
  <FontAwesomeIcon
    icon={faChevronDown}
    {...props}
    style={{ color: "#AAAAAA" }}
  />
);

export default function UserDonationTable({ data, npData }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState("");
  const [timeRange, setTimeRange] = useState("Filter by");
  const [expandRow, setExpandRow] = useState(null);

  const matchesMax430px = useMediaQuery("(max-width: 430px)");

  // Filter donations by time range
  const isInTimeRange = (donationDate) => {
    const date = new Date(donationDate);

    if (timeRange === "month") {
      return IsWithinLastMonth(date);
    } else if (timeRange === "week") {
      return isWithinLastWeek(date);
    }
    return true; // For "all time" or unfiltered case
  };

  // Filter donations based on search query and time range
  const filteredDonations = data.filter((donation) => {
    const matchesSearch =
      donation.nonprofit_name
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      donation.campaign_title.toLowerCase().includes(searchQuery.toLowerCase());
    const withinTimeRange = isInTimeRange(donation.created_at);
    return matchesSearch && withinTimeRange;
  });

  // Paginate donations
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredDonations.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Change page
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Handle search input change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to the first page on search
  };

  // Handle time range change
  const handleTimeRangeChange = (event) => {
    setTimeRange(event.target.value);
    setCurrentPage(1); // Reset to the first page on filter change
  };

  // Toggle row expansion
  const handleExpandRow = (index) => {
    setExpandRow(expandRow === index ? null : index);
  };

  return (
    <div className={`${dashboardClasses.dashboard_block} mb-8`}>
      <h2 className="text-orange !text-3xl font-bold text-center mb-2">
        Your Donations
      </h2>
      <div className="flex flex-col md:flex-row mb-4 gap-2">
        <div className={`flex items-center relative w-full`}>
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            size="xs"
            className="absolute left-3"
            color="#666666"
          />
          <input
            type="text"
            placeholder="Search by Nonprofit Name or Campaign Title"
            value={searchQuery}
            onChange={handleSearchChange}
            className={`p-2 pl-8 border text-black border-gray-300 rounded-lg w-full h-8 `}
          />
        </div>
        <Select
          className="!w-36 !h-8 !text-black !font-normal !rounded-lg"
          value={timeRange}
          IconComponent={CustomIcon}
          MenuProps={{
            PaperProps: {
              sx: {
                backgroundColor: "#f3f3f3", // Dropdown background grey
              },
            },
          }}
          onChange={handleTimeRangeChange}
          name="weekday"
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#d1d5db", // Default border color
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "black", // Focused border color
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#9ca3af", // Hover border color
            },
            "& .MuiOutlinedInput-root": {
              "&.Mui-focused fieldset": {
                borderColor: "#d1d5db", // Ensure consistency
              },
            },
          }}
        >
          <MenuItem value="Filter by">Filter by</MenuItem>
          <MenuItem value="month">This Month</MenuItem>
          <MenuItem value="week">This Week</MenuItem>
        </Select>
      </div>

      {matchesMax430px ? (
        <table
          className={`${formClasses.table_styles_override} !w-full table-fixed !border-separate ${classes.table}`}
        >
          <thead>
            <tr>
              <th className="text-sm font-medium text-neutral-500 align-top">
                Nonprofit Name
              </th>
              <th className="text-sm font-medium text-neutral-500 align-top">
                Campaign Title
              </th>
              <th className="text-sm font-medium text-neutral-500 align-top">
                Amount
              </th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((donation, index) => (
              <tr
                key={index}
                className="bg-sky-50"
                onClick={() => handleExpandRow(index)}
              >
                <td className="rounded-l-xl text-neutral-600 bg-sky-50 break-words">
                  {donation.nonprofit_name}

                  {expandRow === index && (
                    <div className="flex justify-center mt-3">
                      <p className="text-gray-500 !text-xs font-normal flex flex-col m-2 text-left">
                        Date
                        <span className="text-neutral-600 !text-xs font-bold">
                          {formatDate(donation.created_at)}
                        </span>
                      </p>
                    </div>
                  )}
                </td>
                <td className="text-neutral-600 text-center">
                  {donation.campaign_title}

                  {expandRow === index && (
                    <div className="flex justify-center mt-3">
                      <p className="text-gray-500 !text-xs font-normal flex flex-col m-2 text-left">
                        Payment ID
                        <span className="text-neutral-600 !text-xs font-bold break-all">
                          {donation.payment_intent.id}
                        </span>
                      </p>
                    </div>
                  )}
                </td>
                <td className="text-neutral-600 text-center">
                  ${donation.amount}
                </td>

                {expandRow === index ? (
                  <td className="bg-sky-50 mb-14 rounded-r-xl" align="center">
                    <FontAwesomeIcon
                      icon={faChevronUp}
                      size="lg"
                      color="#666666"
                    />
                  </td>
                ) : (
                  <td className="bg-sky-50 mb-14 rounded-r-xl" align="center">
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      size="lg"
                      color="#666666"
                    />
                  </td>
                )}
              </tr>
            ))}
            {currentItems.length === 0 && (
              <tr>
                <td
                  colSpan="4"
                  className="bg-sky-50 mb-14 rounded-r-xl"
                  align="center"
                >
                  No donations found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      ) : (
        <table className={classes.table}>
          <thead>
            <tr>
              <th className="text-neutral-500">Nonprofit Name</th>
              <th className="text-neutral-500">Campaign Title</th>
              <th className="text-neutral-500">Amount</th>
              <th className="text-neutral-500">Date</th>
              <th className="text-neutral-500">Payment ID</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((donation, index) => (
              <tr key={index} className="bg-sky-50">
                <td className="rounded-l-xl text-neutral-600">
                  {donation.nonprofit_name}
                </td>
                <td className="text-neutral-600">{donation.campaign_title}</td>
                <td className="text-neutral-600">${donation.amount}</td>
                <td className="text-neutral-600">
                  {formatDate(donation.created_at)}
                </td>
                <td className="rounded-r-xl text-neutral-600 break-all">
                  {donation.payment_intent.id}
                </td>
              </tr>
            ))}
            {currentItems.length === 0 && (
              <tr className="bg-sky-50">
                <td colSpan="5">Sorry, no matching results!</td>
              </tr>
            )}
          </tbody>
        </table>
      )}

      <nav className="overflow-x-auto w-full mt-4 flex justify-end">
        <div className="flex justify-center mt-4">
          <Pagination
            count={Math.ceil(filteredDonations.length / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
          />
        </div>
      </nav>
    </div>
  );
}
