export const cardTitleExcerpt = (text, wordLength) => {
  // Split the text into words
  const words = text.split(" ");

  // Check if the text has only one word and it's longer than 15 characters
  if (words.length === 1 && words[0].length > wordLength - 20) {
    // Return the first 14 characters of the word followed by '..'
    return words[0].slice(0, wordLength - 20 - 1) + "..";
  }

  // Check if the text is longer than 35 characters
  if (text.length > wordLength) {
    return text.slice(0, wordLength - 3) + "...";
  } else {
    return text;
  }
};

//Blog Cards
export const blogCardTitleExcerpt = (text) => {
  // Split the text into words
  const words = text.split(" ");

  // Check if the text has only one word and it's longer than 15 characters
  if (words.length === 1 && words[0].length > 30) {
    // Return the first 14 characters of the word followed by '..'
    return words[0].slice(0, 30) + "..";
  }

  // Check if the text is longer than 93 characters
  if (text.length > 93) {
    return text.slice(0, 93) + "...";
  } else {
    return text;
  }
};

export const nonProfitExcerpt = (text) => {
  // If the text is longer than 15 characters
  if (text.length > 19) {
    // Return the first 12 characters followed by "..."
    return text.slice(0, 19) + "...";
  } else {
    // If the text is 15 characters or less, return it as is
    return text;
  }
};
