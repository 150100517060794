import axios from "axios";
import { urlifyName } from "../components/shared/Utils/PageUrl";

const API_URL = process.env.REACT_APP_API_URL;

export const getCampaigns = async () => {
  try {
    const response = await axios.get(`${API_URL}/nonprofits/campaigns/all`);
    return response.data;
  } catch (error) {
    // Handle or throw error
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("get campaign error:", error.response);
      throw error;
    }
  }
};

export const getAllPaginatedCampaigns = async (
  page,
  search_param,
  nonprofitId,
  filter_param
) => {
  const params = {
    page,
    search_param,
    nonprofitId,
    filter_param,
  };

  try {
    const response = await axios.get(
      `${API_URL}/nonprofits/campaigns/all/paginate`,
      {
        params,
      }
    );

    if (!response) return null;

    return response.data;
  } catch (error) {
    // Handle or throw error
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("get campaign error:", error.response);
      throw error;
    }
  }
};

export const getPaginatedCampaigns = async (page, searches, type) => {
  const params = {
    ...searches,
    page,
    type,
  };

  try {
    const response = await axios.get(
      `${API_URL}/nonprofits/campaigns/paginate/`,
      { params }
    );
    if (!response) return null;

    return response.data;
  } catch (error) {
    // Handle or throw error
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("get campaign error:", error.response);
      throw error;
    }
  }
};

export const getCampaign = async (nonprofit_name, animal_name, campaign_id) => {
  const response = await getCampaigns();
  if (!response) return null;
  for (let i in response) {
    if (
      response[i].id === parseInt(campaign_id) &&
      urlifyName(response[i].animal_name) === animal_name &&
      urlifyName(response[i].nonprofit_name) === nonprofit_name
    )
      return response[i];
  }
  return null;
};

export const getCampaignbyId = async (campaign_id, type) => {
  try {
    const params = { type: type };
    const id = parseInt(campaign_id);
    const response = await axios.get(`${API_URL}/nonprofits/campaigns/${id}`, {
      params,
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("get campaign error:", error.response);
      throw error;
    }
  }
};

export const deleteCampaign = async (campaign_id, campaign_type) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
      params: {
        campaign_type,
      },
    };
    const response = await axios.delete(
      `${API_URL}/nonprofits/campaigns/${campaign_id}/delete/`,
      config
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("delete campaign error:", error.response);
      throw error;
    }
  }
};

export const getCampaignByNonProfit = async (nonprofit_id) => {
  try {
    const response = await getCampaigns();
    if (!response) return null;

    const arr = response.filter((campaign) => {
      return campaign.nonprofit === nonprofit_id;
    });

    return arr;
  } catch (error) {
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("get campaign error:", error.response);
      throw error;
    }
  }
};

export const getTotalCampaigns = async (nonprofitId) => {
  try {
    const params = { nonprofitId: nonprofitId };
    const response = await axios.get(`${API_URL}/nonprofits/campaigns-total/`, {
      params,
    });
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("get campaign error:", error.response);
      throw error;
    }
  }
};
